.p-datatable-responsive {
    width: 100%;
    height: 100%;
}

.p-datatable {
    font-family: inherit;
    font-size: rem(12px);

    .btn.btn-icon {
        min-width: 24px;
        min-height: 24px;
    }

    .row-obsolete {
        td {
            opacity: 0.5;
        }
    }
}

.p-datatable th {
    white-space: nowrap;
}

.p-datatable .p-datatable-table {
    border-spacing: 0px;
}

.p-datatable .p-datatable-header {
    background: var(--neutral200);
    color: inherit;
    border-color: var(--neutral300);
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding: 4px 8px;
    font-weight: 500;
}

.p-datatable .p-datatable-footer {
    background: transparent;
    color: inherit;
    border-color: var(--neutral300);
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    padding: 4px 8px;
    font-weight: 400;
}

.p-datatable-thead {
    // display: block;

    tr {
        // display: flex;
        // flex-wrap: nowrap;
        // width: 100%;
    }
}

.p-datatable .p-datatable-thead>tr>th {
    padding: 4px 8px;
    min-height: 40px;
    border-color: var(--neutral300);
    font-weight: 500;
    vertical-align: middle;
    color: $colorBlack;
    background: var(--ramuddenYellow);
    border-top: 0px;
    border-bottom: 0px;
    border-left: 1px solid var(--neutral300);
    border-right: 0 none;

    // display: flex;
    // flex: 1 1 0;
    // align-items: center;

    &:first-child {
        border-left: 0;
    }
}

.p-datatable .p-datatable-tfoot>tr>td {
    padding: 4px 8px;
    border-color: var(--neutral300);
    font-weight: 500;
    color: inherit;
    background: var(--neutral200);
    border-left: 1px solid var(--neutral300);
    border-right: 0 none;

    &:first-child {
        border-left: 0;
    }
}

.p-datatable .p-sortable-column p-sorticon {
    display: flex;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: inherit;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
    color: inherit;
    background: var(--ramuddenYellow);
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: var(--ramuddenYellow);
    color: inherit;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: inherit;
}

.p-datatable .p-sortable-column.p-highlight {
    background: darken($colorBrand, 12.5%);
    color: inherit;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: inherit;
}

.p-datatable .p-sortable-column.p-highlight:hover {
    background: darken($colorBrand, 12.5%);
    color: inherit;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: inherit;
}

.p-datatable .p-sortable-column:focus {
    box-shadow: none;
    outline: 0 none;
}

.p-datatable-tbody {
    // display: block;
}

.p-datatable .p-datatable-tbody>tr {
    background: transparent;
    color: inherit;
    outline: 0;
    // display: flex;
    // flex-wrap: nowrap;
    // width: 100%;

    &.is-greyed-out {
        color: $colorTextLight;
    }
}

.p-datatable .p-datatable-tbody>tr:focus {
    outline: 0;
    outline-offset: 0;
}

.p-datatable .p-datatable-tbody>tr>td {
    border-color: var(--neutral300);
    min-height: 28px;
    padding: 2px 8px;
    border-top: 0 none;
    border-bottom: 0 none;
    border-left: 1px solid var(--neutral300);
    border-right: 0 none;

    // display: flex;
    // flex: 1 1 0;
    // align-items: center;

    &.m-table-empty-message {
        padding: 8px;
    }

    &:first-child {
        border-left: 0;
    }
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel {
    color: inherit;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:enabled:hover {
    color: inherit;
}

.p-datatable .p-datatable-tbody>tr>td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody>tr>td .p-row-editor-cancel:focus {
    box-shadow: none;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
    background: rgba(black, .066);
    color: inherit;

    >td:first-child {
        position: relative;

        &:before {
            content: "";
            display: block;
            width: 5px;
            height: 100%;
            background-color: var(--ramuddenYellow);
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-top>td {
    box-shadow: none;
}

.p-datatable .p-datatable-tbody>tr.p-datatable-dragpoint-bottom>td {
    box-shadow: none;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
    background: rgba(black, .066);
    color: inherit;
}

.p-datatable .p-column-resizer-helper {
    background: var(--neutral300);
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
    background: var(--neutral300);
}

.p-datatable .p-datatable-tbody>tr:nth-child(even) {
    background: rgba(black, .033);
}

.p-datatable .p-datatable-tbody>tr:nth-child(even).p-highlight {
    background: rgba(black, .066);
    color: inherit;
}

.p-datatable .p-datatable-tbody>tr:nth-child(even).p-highlight .p-row-toggler {
    color: inherit;
}

.p-datatable .p-datatable-tbody>tr:nth-child(even).p-highlight .p-row-toggler:hover {
    color: inherit;
}

.p-datatable.p-datatable-sm .p-datatable-header {
    padding: 4px 8px;
}

.p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
    padding: 4px 8px;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    padding: 1px 8px;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot>tr>td {
    padding: 4px 8px;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
    padding: 4px 8px;
}

.p-datatable.p-datatable-lg .p-datatable-header {
    padding: 8px;
}

.p-datatable.p-datatable-lg .p-datatable-thead>tr>th {
    padding: 8px;
}

.p-datatable.p-datatable-lg .p-datatable-tbody>tr>td {
    padding: 8px;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot>tr>td {
    padding: 8px;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 8px;
}

.p-datatable .p-column-resizer {
    right: -0.25rem;
}

.p-datatable .p-datatable-loading-icon {
    color: var(--neutral500);
    width: 2rem;
    height: 2rem;
}

.p-datatable .p-datatable-loading-overlay {
    background-color: rgba(white, 0.5);
}

.m-table-card {
    .p-datatable .p-datatable-tbody>tr>td {
        padding: 8px;
        border: 0;
    }

    .p-datatable .p-datatable-tbody>tr:nth-child(even) {
        background: transparent;
    }

    .p-datatable .p-datatable-tbody>tr:nth-child(even).p-highlight {
        background: $colorUltraLightGrey;
    }

    .p-datatable .p-datatable-tbody>tr>td>.m-card {
        background-color: $colorUltraLightGrey;

        &:hover {
            background-color: $colorLightGrey;
        }
    }

    .p-datatable .p-datatable-tbody>tr>td>.m-card p+p {
        line-height: 1.5;
        margin-top: 8px;
    }

    .p-datatable .p-datatable-tbody>tr.p-highlight {
        background: transparent;
    }

    .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody>tr:not(.p-highlight):hover {
        background: transparent;
    }
}

.p-datatable {
    .p-inputtext {
        height: auto;
        min-height: 24px;
    }

    .m-column-header {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
    }

    .m-column-header-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 24px;
    }

    .m-column-title {
        font-size: rem(13px);
        font-weight: 500;
        color: $colorText;
        margin-right: auto;
    }

    .m-column-sort {
        margin-left: 8px;
    }

    .m-column-filter {
        margin-left: 8px;
    }

    .m-column-filter-clear {
        display: flex;
        align-items: center;
        background-color: rgba($colorBlack, .1);
        border-radius: 0px;
        padding: 4px 8px;
        margin-top: 4px;

        .icon {
            width: 12px;
            height: 12px;
            margin-left: 4px;
            flex: 0 0 auto;
        }
    }

    .m-column-filter-clear-label {
        width: calc(100% - 16px);
        font-size: rem(10px);
        font-weight: 400;
        white-space: nowrap;
        color: $colorText;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .m-column-search,
    .m-column-slider,
    .m-column-dropdown,
    .m-column-multiselect {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;
        overflow: hidden;
        opacity: 0;
    }

    .m-column-filter:focus-within {

        .m-column-search,
        .m-column-slider {
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            transform: none;
            width: auto;
            height: auto;
            overflow: visible;
            opacity: 1;
        }
    }

    .m-column-calendar {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        overflow: hidden;
        opacity: 0;
    }
}

#measuring_point_validation_table .p-datatable-thead {
    // display: table;
    // border-collapse: collapse;
    // width: 100%;
    // table-layout: fixed;
}

.m-pulse-warning {
    background-color: $colorOker;
}

.m-pulse-error {
    background-color: red;
    color: $colorWhite;
}

.m-table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        padding: 4px 8px;
    }

    >thead {
        >tr {
            background-color: var(--ramuddenYellow);
        }
    }

    >tbody {
        >tr:nth-child(2n) {
            background-color: var(--neutral200);
        }
    }
}