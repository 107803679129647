.p-dialog {
    width: 100%;
    max-width: 960px;
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    border-color: $colorBorder;
}

.p-dialog .p-dialog-header {
    border-bottom: 0;
    background: $colorUltraLightGrey;
    color: $colorTextDark;
    padding: 16px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-dialog .p-dialog-header .p-dialog-title {
    width: 100%;
    font-weight: 500;
    font-size: rem(16px);
    color: $colorHeadings;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 20px;
    height: 20px;
    color: $colorText;
    margin-right: 0;
    background: transparent;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: darken($colorText, 10%);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none;
}

.p-dialog .p-dialog-content {
    background: $colorWhite;
    color: $colorText;
    padding: 0;
}

.p-dialog .p-dialog-footer {
    border-top: 0;
    background: $colorWhite;
    color: $colorText;
    padding: 8px 16px;
    text-align: right;
    border-top: 1px solid $colorBorder;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.p-dialog .p-dialog-footer button {
    margin: 0 0 0 8px;
    width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: inherit;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 16px;
}

.p-dialog-mask.p-component-overlay {
    background-color: rgba(0, 0, 0, 0.4);
}
