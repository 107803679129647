.m-snackbar {
    @extend .m-card;
    position: fixed;
    z-index: 89;
    bottom: 20px;
    right: 20px;
    width: 480px;
    max-width: calc(100% - 40px);
}

.m-snackbar-header {
    position: relative;
    border-radius: 0px;
    background-color: var(--neutral300);

    .is-expanded & {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.m-snackbar-expand {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.p-progressbar {
    border: 0 none;
    height: 16px;
    background: var(--neutral500);
    border-radius: 0px;
}

.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: var(--ramuddenYellow);
}

.p-progressbar .p-progressbar-label {
    font-size: rem(12px);
    color: var(--neutral900);
    line-height: 16px;
}

.m-progressbar-item {
    min-height: 36px;
}

.m-anchor {
    a {
        text-decoration: underline;
        color: $colorButtonTertiary;
    }

    font-style: italic;
    font-size: smaller;
    margin-top: 2px;
}