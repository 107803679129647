.m-mode-grid-header {
    background-color: $colorUltraLightGrey;
    border-radius: 0 0 0 0;

    .p-inputtext {
        background-color: white;
    }
}

.m-mode-grid-row {
    border-top: 2px dashed $colorUltraLightGrey;
}

.m-mode-grid-frames {
    border-radius: 0 0 0 4px;
}
