.container {
    width: var(--width, 1280px);
    height: var(--height, auto);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.container--fluid {
    width: 100%;
    height: var(--height, auto);
    margin-left: auto;
    margin-right: auto;
}