.m-input-round-slider {
    position: relative;

    .p-inputtext {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        appearance: textfield;
        min-width: 60px;
        width: 60px;
        text-align: center;
    }
}

.m-input-round-slider-label {
    display: block;
    text-align: center;
}

.m-input-round-slider-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .circumference {
        fill: $colorLightGrey;
    }

    .dot circle:hover {
        cursor: pointer;
    }

    .dot circle {
        fill: $colorBrand;
    }

    .dot circle.dragging {
        fill: darken($colorBrand, 4);
    }
}