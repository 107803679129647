.p {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            padding-top: map-get($sizes, $size);
            padding-bottom: map-get($sizes, $size);
            padding-left: map-get($sizes, $size);
            padding-right: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        padding-top: map-get($sizes, $size);
                        padding-bottom: map-get($sizes, $size);
                        padding-left: map-get($sizes, $size);
                        padding-right: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}

.py {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            padding-top: map-get($sizes, $size);
            padding-bottom: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        padding-top: map-get($sizes, $size);
                        padding-bottom: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}

.px {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            padding-left: map-get($sizes, $size);
            padding-right: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        padding-left: map-get($sizes, $size);
                        padding-right: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}

.pt {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            padding-top: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        padding-top: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}

.pb {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            padding-bottom: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        padding-bottom: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}

.pl {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            padding-left: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        padding-left: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}

.pr {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            padding-right: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        padding-right: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}