.grid-container {
    display:grid;
    gap:20px;
    width: 100%;
    padding: 20px;
}

.grid-cell {
    width:100%;
}

.grid-cell-no-wrap {
    flex-wrap: nowrap;
}

@mixin interpolationColumns($columnNumber) {    
    .grid-container.grid-columns-#{$columnNumber} {
        grid-template-columns: repeat($columnNumber, auto);
    }
    
    .grid-column-#{$columnNumber} {
        grid-column: $columnNumber;
    }
}

@mixin interpolationRows($rowNumber) {        
    .grid-row-#{$rowNumber} {
        grid-row: $rowNumber;
    }    
}

$maxNumberOfColumns: 6;
// 6 columns max 
@for $i from 0 to $maxNumberOfColumns {
    @include interpolationColumns($i);
    @include interpolationRows($i);
}
