.m-galleria {
    z-index: 100000;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "actions"
        "body"
        "navigator";
}

.m-galleria-actions {
    display: flex;
    justify-content: flex-end;
    grid-area: actions;
}

.m-galleria-body-wrapper {
    grid-area: body;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.m-galleria-body {
    width: 100%;
    height: 100%;
    display: grid;
    overflow: hidden;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "title"
        "content";
}

.m-galleria-title {
    text-align: center;
    color: $colorWhite;
    grid-area: title;
}

.m-galleria-content {
    width: 100%;
    height: 100%;
    grid-area: content;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    pdf-viewer {
        display: block;
        width: 100%;
        height: 100%;
    }

    img,
    video {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
}

.m-galleria-navigator {
    display: flex;
    justify-content: center;
    gap: 16px;
    grid-area: navigator;
    overflow: auto;

    img {
        max-height: 80px;
        max-width: 100%;
        object-fit: contain;
        cursor: pointer;
    }
}

.m-galleria-navigator-item {
    width: 160px;
}

.m-galleria-navigator-title {
    width: 100%;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    color: $colorWhite;
    overflow: hidden;
}