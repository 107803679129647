.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    fill: $colorTextDark;

    svg {
        display: block;
        width: 100%;
        height: 100%;
    }

    &-brand {
        fill: $colorBrand;
    }

    &-text-light {
        fill: $colorTextLight;
    }

    &-text-dark {
        fill: $colorTextDark;
    }

    &-white {
        fill: $colorWhite;
    }

    &.icon-12 {
        width: 12px;
        height: 12px;
    }

    &.icon-16 {
        width: 16px;
        height: 16px;
    }

    &.icon-20 {
        width: 20px;
        height: 20px;
    }

    &.icon-50 {
        width: 50px;
        height: 50px;
    }

    &.icon-gm {
        width: 20px;
        height: 20px;
        background-image: url("../../../assets/img/map-type-gm.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &.icon-gm-satellite {
        width: 20px;
        height: 20px;
        background-image: url("../../../assets/img/map-type-gm-satellite.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &.icon-osm {
        width: 20px;
        height: 20px;
        background-image: url("../../../assets/img/map-type-osm.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &.icon-osm-bike {
        width: 20px;
        height: 20px;
        background-image: url("../../../assets/img/map-type-osm-bike.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &.icon-osm-transport {
        width: 20px;
        height: 20px;
        background-image: url("../../../assets/img/map-type-osm-transport.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.svg-preview svg {
    width: 30px;
    height: 30px;
}
