.m-fixed-height {
    height: 100%;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                height: 100%;
            }
        }
    }
}

.m-half-height {
    height: 50%;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                height: 50%;
            }
        }
    }
}

.m-third-height {
    height: 33.33%;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                height: 33.33%;
            }
        }
    }
}

.m-two-third-height {
    height: 66.66%;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                height: 33.33%;
            }
        }
    }
}

.m-auto-height {
    height: auto;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                height: auto;
            }
        }
    }
}

.m-border-top {
    border-top: 1px solid $colorBorder;

    &-dashed {
        border-top: 1px dashed $colorBorder;
    }
}

.m-border-bottom {
    border-bottom: 1px solid $colorBorder;

    &-dashed {
        border-bottom: 1px dashed $colorBorder;
    }
}

.m-border-left {
    border-left: 1px solid $colorBorder;

    &-dashed {
        border-left: 1px dashed $colorBorder;
    }
}

.m-border-right {
    border-right: 1px solid $colorBorder;

    &-dashed {
        border-right: 1px dashed $colorBorder;
    }
}

.m-overflow-auto {
    overflow: auto;
}

.m-btn-baseline {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -4px;
}

.m-in-progress {
    position: fixed;
    left: 20px;
    bottom: 20px;
    max-width: calc(100% - 40px);
    color: $colorOrange;
    pointer-events: none;
}

.m-no-wrap {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.m-shadow {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.m-arrow-right {
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px;
    width: 24px;
    height: 16px;
    background-image: url("./../../../assets/img/arrow-right.png");
    background-size: 24px 16px;
}

.m-arrow-left {
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px;
    width: 24px;
    height: 16px;
    background-image: url("./../../../assets/img/arrow-left.png");
    background-size: 24px 16px;
}

.m-text-center {
    text-align: center;
}

.m-visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
}

.m-list-inside {
    list-style-position: inside;
}

.m-text-dark {
    color: $colorTextDark;
}

.m-text {
    color: $colorText;
}

.m-text-light {
    color: $colorTextLight;
}
