.m-tabs-picker {
    >ul {
        display: flex;
        list-style: none;
        overflow: auto;

        >li {
            >a {
                display: inline-block;
                padding: 12px;
                border-top: 2px solid transparent;
                border-bottom: 2px solid transparent;
                white-space: nowrap;

                &:hover,
                &.is-active {
                    background-color: rgba($colorBlack, .05);
                    color: $colorTextDark;
                    border-bottom-color: $colorBlack;
                }
            }
        }
    }
}