.p-inputtext {
    align-items: center;
    width: 100%;
    height: 32px;
    min-width: 85px;
    padding: 1px 0px 1px 12px;
    background-color: $colorUltraLightGrey;
    border: 1px solid $colorInputBorder;
    border-radius: 0px;
    font-family: "Fira Sans", sans-serif;
    font-size: rem(13px);
    font-weight: 400;
    color: $colorText;

    &::placeholder {
        color: $colorTextLight;
    }

    &:enabled:hover {
        border-color: $colorInputBorder;
    }

    &:enabled:focus {
        outline: none;
        box-shadow: none;
        border-color: darken($colorInputBorder, 10%);
    }

    &[readonly],
    &[disabled] {
        opacity: 0.5;
        cursor: default !important;
        user-select: none;
        pointer-events: none;

        &:enabled:focus {
            border-color: $colorInputBorder;
        }

        .p-disabled & {
            opacity: 1;
        }

        .p-colorpicker & {
            opacity: 1;
            user-select: auto;
            pointer-events: all;
        }
    }

    .p-dropdown > & {
        background-color: transparent;
        border: 0px;
    }
}

.p-inputtext.ng-invalid {
    border-color: #e24c4c;
}

.p-inputtextarea {
    @extend .p-inputtext;
    min-height: 72px;
    padding: 12px;
}

.p-inputtextarea.ng-invalid {
    border-color: #e24c4c;
}

.m-input-radio-block,
.m-input-checkbox-block {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: rem(13px);
    line-height: 1.33;

    &:hover {
        cursor: pointer;
    }

    input {
        margin: 1px 6px 1px 0;
        width: 14px;
        height: 14px;
        accent-color: var(--ramuddenYellow);
    }
}

.m-input-radio,
.m-input-checkbox {
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: rem(13px);
    line-height: 1.33;

    &:hover {
        cursor: pointer;
    }

    input {
        margin: 1px 6px 1px 0;
        width: 14px;
        height: 14px;
        accent-color: var(--ramuddenYellow);
    }
}

input.m-input-checkbox {
    margin: 1px 6px 1px 0;
    width: 14px;
    height: 14px;
    accent-color: var(--ramuddenYellow);
}

.m-input-slider-value {
    display: grid;
    grid-template-columns: 1fr 80px;
    align-items: center;
    gap: 16px;

    > * {
        min-width: 0;
        min-height: 0;
    }
}

.m-input-file {
    display: grid;
    grid-template-columns: 1fr min-content;

    &:hover {
        cursor: pointer;
    }

    input {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}

.m-input-group {
    display: grid;
    grid-template-columns: 1fr min-content;

    .p-inputtext {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0 none;

        &:focus + .m-input-group-addon {
            border-color: darken($colorInputBorder, 10%);
        }
    }
}

.m-input-group-addon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 36px;
    font-size: rem(12px);
    background-color: $colorLightGrey;
    border: 1px solid $colorInputBorder;
    border-left: 0;
}

.hour-addon {
    width: 70px;
    // font-size: 0.7rem;
}

.m-input-group-w-icon {
    position: relative;
}

.m-input-group-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .icon {
        fill: $colorTextLight;
        width: 20px;
        height: 20px;
        pointer-events: none;
    }
}

.m-input-group-icon-right {
    right: 12px;
    left: 290px;
}

.m-form-group {
    & + & {
        margin-top: 8px;
    }

    &.has-arrow {
        position: relative;

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: calc(33.33% - 8px);
            top: 0;
            width: 8px;
            height: 100%;
            background-image: url("./../../../assets/img/arrow-vertical.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}
