.p-multiselect {
    position: relative;
    width: 100%;
    min-height: 32px;
    background-color: $colorUltraLightGrey;
    border-color: $colorInputBorder;
    border-radius: 0px;

    &:not(.p-disabled):hover {
        border-color: $colorInputBorder;
    }

    &:not(.p-disabled).p-focus {
        box-shadow: none;
        border-color: darken($colorInputBorder, 10%);
    }

    .p-multiselect-label-container {
        display: flex;
    }

    .p-multiselect-label {
        display: flex;
        align-items: center;
        font-size: rem(13px);
        padding: 1px 12px;

        &.p-placeholder {
            color: $colorTextLight;
        }
    }

    &.p-multiselect-chip .p-multiselect-token {
        background: $colorBrand;
        color: $colorBlack;
        border-radius: 0px;
    }

    .p-multiselect-trigger {
        width: 30px;
        height: 30px;
        color: $colorText;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

p-multiselect.b-ultra-light-grey .p-multiselect {
    background-color: $colorUltraLightGrey;
    height: 32px;
}

p-multiselect.b-white .p-multiselect {
    background-color: $colorWhite;

    .p-multiselect-label {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: rem(13px);

        &.p-placeholder {
            color: $colorTextLight;
        }
    }
}

p-multiselect.b-white .p-multiselect .p-overlay-content {
    width: auto;
}

.p-multiselect-panel {
    color: $colorText;
    border-color: $colorInputBorder;
    border-radius: 0px;

    .p-multiselect-header {
        padding: 4px 12px;
        border-bottom-color: $colorInputBorder;
        color: $colorText;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }

    .p-multiselect-items {
        .p-multiselect-item {
            font-size: rem(13px);

            &.p-highlight {
                background: $colorLightGrey;
                font-weight: 500;
                color: $colorBrand;
            }
        }
    }
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    color: $colorText;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    color: $colorText;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: $colorBrand;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    box-shadow: none;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    color: $colorText;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: $colorText;
    background: $colorLightGrey;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    color: $colorText;
    background: $colorLightGrey;
    font-weight: 500;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    color: $colorText;
}

.p-input-filled .p-multiselect {
    background: $colorLightGrey;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: $colorLightGrey;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: $colorLightGrey;
}

p-multiselect.panel-third-screen {
    .p-multiselect-items-wrapper {
        max-height: 35vh !important;
    }
}

.measuring-point-projects {
    .p-multiselect-header {
        display: grid;
        grid-template-columns: 1fr 32px;
        grid-template-rows: 1fr;
    }
}