.m-circle {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    background-color: $colorWhite;
    border-radius: 50%;

    &-xs {
        width: 12px;
        height: 12px;
    }

    &-md {
        width: 22px;
        height: 22px;
    }

    .icon {
        width: 18px;
        height: 18px;
    }

    &-primary {
        background-color: $colorBrand;

        .icon {
            fill: $colorBlack;
        }
    }

    &-grey {
        background-color: $colorCircleGrey;

        .icon {
            fill: $colorBlack;
        }
    }
}