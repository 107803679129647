.p-checkbox .p-checkbox-box {
    border-color: $colorInputBorder;
    background: $colorWhite;
    color: $colorText;
    border-radius: 0;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: $colorBrand;
    background: $colorBrand;
}

.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon {
    color: $colorText;
}

.p-checkbox .p-checkbox-box.p-highlight checkicon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-checkbox .p-checkbox-box.p-highlight timesicon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: darken($colorInputBorder, 10%);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: none;
    border-color: $colorBrand;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: $colorBrand;
    background: $colorBrand;
    color: $colorWhite;
}

// .p-input-filled .p-checkbox .p-checkbox-box {
//     background-color: $colorWhite;
// }

// .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
//     background: $colorBrand;
// }

// .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
//     background-color: $colorWhite;
// }

// .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
//     background: $colorBrand;
// }

.p-highlight .p-checkbox .p-checkbox-box {
    border-color: $colorInputBorder;
}
