.m-livetile {
    display: grid;
    position: fixed;
    width: auto;
    max-width: 90vw;
    background: $colorWhite;
    border-radius: 0px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    z-index: 10;

    &-sm {
        width: 352px;
    }

    .nav.nav-tabs {
        padding: 0px !important;
        width: 100%;
        position: fixed;
        left: 0px;
    }

    .ng-resizable {
        position: static;
    }

    .ng-resizable-diagonal {
        right: 1px;
        bottom: -30px; // This is a hack to position the handle in the bottom bar. It would be better to just put the icon in the bottom bar
    }
}

.m-livetile-header {
    display: flex;
    align-items: center;
    padding: 2px 0px 2px 8px;
    background: $colorBrand;
    background-color: transparent;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    h4 {
        margin-right: 4px;
    }

    .m-btn-square {
        min-width: 16px;
        min-height: 16px;
        opacity: 0.66;

        svg {
            fill: $colorText;
        }

        &:hover {
            opacity: 1;
        }
    }
}

.m-livetile-body {
    display: grid;
    height: auto;
    max-height: 480px;
    overflow: auto;

    &-resizable {
        position: relative;
        overflow: visible;
        max-height: none;
    }

    .p-datatable {
        border-collapse: collapse;
    }

    .p-datatable .p-datatable-thead>tr>td,
    .p-datatable .p-datatable-tbody>tr>td,
    .p-datatable .p-datatable-tfoot>tr>td {
        border-left-color: transparent;
        border-right-color: transparent;
    }

    .icon {
        width: 16px;
        height: 16px;
    }
}

.m-livetile-footer {
    background-color: $colorLightGrey;

    .icon {
        width: 16px;
        height: 16px;
    }

    .m-btn-square {
        min-width: 24px;
        min-height: 24px;
        opacity: 0.66;

        &:hover {
            opacity: 1;
        }
    }
}

.m-livetile-chart {
    display: block;
    position: relative;
    min-width: 260px;
    min-height: 120px;
    width: 100%;
    height: 100%;

    >app-chart {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        >* {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.m-livetile-chart-props {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.m-livetile-key-value {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 16px;
    margin-top: 6px;

    >* {
        min-width: 0;
        min-height: 0;
    }
}