// .m-map-search-results-container {
//     display: flex;
//     flex-direction: column;
//     background-color: white;
//     border: white;
//     max-height: 100%;
//     overflow-y: auto;
// }

.m-map-search-result-selected-item {
    background-color: $colorGrey;
}

// .m-map-collapsed {
//     background: $colorUltraLightGrey;
//     border-radius: 0px;
// }

// .m-map-expanded {
//     background: $colorUltraLightGrey;
//     border-radius: 0px;
// }