@import 'settings/grid';
@import 'settings/breakpoints';
@import 'settings/sizes';
@import 'settings/colors';

@if(variable-exists(devMode) and $devMode=="true") {
    .m-org__product:after {
        content: 'Test';
        display: flex;
        align-items: center;
        height: 20px;
        background-color: $colorBlue;
        padding: 0px 8px;
        border-radius: 10px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1;
        color: $colorWhite;
    }
}
