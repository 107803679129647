:root {
  --ramuddenYellow: #ffe137;
  --ramuddenBeige: #ffe6aa;
  --ramuddenSand: #fffaeb;
  --ramuddenGreen: 23, 50, 50;
  --ramuddenRed: #3c1e28;
  --ramuddenOrange: #f06233;
  --ramuddenBlue: #003c8c;
  --neutral100: #ffffff;
  --neutral200: #f0f0f0;
  --neutral300: #d0d0d0;
  --neutral400: #a0a0a0;
  --neutral500: #909090;
  --neutral600: #707070;
  --neutral700: #505050;
  --neutral800: #303030;
  --neutral900: #101010;
  --success: #2ba712;
  --info: #2e5ded;
  --warning: #ffc83e;
  --error: #ed2a2a;
  --boxShadow: 0 0 5px rgba(23, 50, 50, 0.2);
}

.m-org__product:after {
  content: "Test";
  display: flex;
  align-items: center;
  height: 20px;
  background-color: #003c8c;
  padding: 0px 8px;
  border-radius: 10px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  color: #ffffff;
}

.flex {
  display: flex;
}
@media only screen and (min-width: 480px) {
  .flex-xs {
    display: flex;
  }
}
@media only screen and (min-width: 640px) {
  .flex-sm {
    display: flex;
  }
}
@media only screen and (min-width: 800px) {
  .flex-md {
    display: flex;
  }
}
@media only screen and (min-width: 960px) {
  .flex-lg {
    display: flex;
  }
}
@media only screen and (min-width: 1120px) {
  .flex-xl {
    display: flex;
  }
}
@media only screen and (min-width: 1280px) {
  .flex-xxl {
    display: flex;
  }
}

.flex-column {
  flex-direction: column;
}
@media only screen and (min-width: 480px) {
  .flex-column-xs {
    flex-direction: column;
  }
}
@media only screen and (min-width: 640px) {
  .flex-column-sm {
    flex-direction: column;
  }
}
@media only screen and (min-width: 800px) {
  .flex-column-md {
    flex-direction: column;
  }
}
@media only screen and (min-width: 960px) {
  .flex-column-lg {
    flex-direction: column;
  }
}
@media only screen and (min-width: 1120px) {
  .flex-column-xl {
    flex-direction: column;
  }
}
@media only screen and (min-width: 1280px) {
  .flex-column-xxl {
    flex-direction: column;
  }
}

.flex-row {
  flex-direction: row;
}
@media only screen and (min-width: 480px) {
  .flex-row-xs {
    flex-direction: row;
  }
}
@media only screen and (min-width: 640px) {
  .flex-row-sm {
    flex-direction: row;
  }
}
@media only screen and (min-width: 800px) {
  .flex-row-md {
    flex-direction: row;
  }
}
@media only screen and (min-width: 960px) {
  .flex-row-lg {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1120px) {
  .flex-row-xl {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1280px) {
  .flex-row-xxl {
    flex-direction: row;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}
@media only screen and (min-width: 480px) {
  .flex-wrap-xs {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 640px) {
  .flex-wrap-sm {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 800px) {
  .flex-wrap-md {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 960px) {
  .flex-wrap-lg {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 1120px) {
  .flex-wrap-xl {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 1280px) {
  .flex-wrap-xxl {
    flex-wrap: wrap;
  }
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
@media only screen and (min-width: 480px) {
  .flex-wrap-reverse-xs {
    flex-wrap: wrap-reverse;
  }
}
@media only screen and (min-width: 640px) {
  .flex-wrap-reverse-sm {
    flex-wrap: wrap-reverse;
  }
}
@media only screen and (min-width: 800px) {
  .flex-wrap-reverse-md {
    flex-wrap: wrap-reverse;
  }
}
@media only screen and (min-width: 960px) {
  .flex-wrap-reverse-lg {
    flex-wrap: wrap-reverse;
  }
}
@media only screen and (min-width: 1120px) {
  .flex-wrap-reverse-xl {
    flex-wrap: wrap-reverse;
  }
}
@media only screen and (min-width: 1280px) {
  .flex-wrap-reverse-xxl {
    flex-wrap: wrap-reverse;
  }
}

.flex-nowrap {
  flex-wrap: nowrap;
}
@media only screen and (min-width: 480px) {
  .flex-nowrap-xs {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 640px) {
  .flex-nowrap-sm {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 800px) {
  .flex-nowrap-md {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 960px) {
  .flex-nowrap-lg {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 1120px) {
  .flex-nowrap-xl {
    flex-wrap: nowrap;
  }
}
@media only screen and (min-width: 1280px) {
  .flex-nowrap-xxl {
    flex-wrap: nowrap;
  }
}

.align-stretch {
  align-items: stretch;
}
@media only screen and (min-width: 480px) {
  .align-stretch-xs {
    align-items: stretch;
  }
}
@media only screen and (min-width: 640px) {
  .align-stretch-sm {
    align-items: stretch;
  }
}
@media only screen and (min-width: 800px) {
  .align-stretch-md {
    align-items: stretch;
  }
}
@media only screen and (min-width: 960px) {
  .align-stretch-lg {
    align-items: stretch;
  }
}
@media only screen and (min-width: 1120px) {
  .align-stretch-xl {
    align-items: stretch;
  }
}
@media only screen and (min-width: 1280px) {
  .align-stretch-xxl {
    align-items: stretch;
  }
}

.align-start {
  align-items: flex-start;
}
@media only screen and (min-width: 480px) {
  .align-start-xs {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 640px) {
  .align-start-sm {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 800px) {
  .align-start-md {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 960px) {
  .align-start-lg {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 1120px) {
  .align-start-xl {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 1280px) {
  .align-start-xxl {
    align-items: flex-start;
  }
}

.align-end {
  align-items: flex-end;
}
@media only screen and (min-width: 480px) {
  .align-end-xs {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 640px) {
  .align-end-sm {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 800px) {
  .align-end-md {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 960px) {
  .align-end-lg {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 1120px) {
  .align-end-xl {
    align-items: flex-end;
  }
}
@media only screen and (min-width: 1280px) {
  .align-end-xxl {
    align-items: flex-end;
  }
}

.align-items-center,
.align-center {
  align-items: center;
}
@media only screen and (min-width: 480px) {
  .align-items-center-xs,
  .align-center-xs {
    align-items: center;
  }
}
@media only screen and (min-width: 640px) {
  .align-items-center-sm,
  .align-center-sm {
    align-items: center;
  }
}
@media only screen and (min-width: 800px) {
  .align-items-center-md,
  .align-center-md {
    align-items: center;
  }
}
@media only screen and (min-width: 960px) {
  .align-items-center-lg,
  .align-center-lg {
    align-items: center;
  }
}
@media only screen and (min-width: 1120px) {
  .align-items-center-xl,
  .align-center-xl {
    align-items: center;
  }
}
@media only screen and (min-width: 1280px) {
  .align-items-center-xxl,
  .align-center-xxl {
    align-items: center;
  }
}

.justify-stretch {
  justify-content: stretch;
}
@media only screen and (min-width: 480px) {
  .justify-stretch-xs {
    justify-content: stretch;
  }
}
@media only screen and (min-width: 640px) {
  .justify-stretch-sm {
    justify-content: stretch;
  }
}
@media only screen and (min-width: 800px) {
  .justify-stretch-md {
    justify-content: stretch;
  }
}
@media only screen and (min-width: 960px) {
  .justify-stretch-lg {
    justify-content: stretch;
  }
}
@media only screen and (min-width: 1120px) {
  .justify-stretch-xl {
    justify-content: stretch;
  }
}
@media only screen and (min-width: 1280px) {
  .justify-stretch-xxl {
    justify-content: stretch;
  }
}

.justify-start {
  justify-content: flex-start;
}
@media only screen and (min-width: 480px) {
  .justify-start-xs {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 640px) {
  .justify-start-sm {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 800px) {
  .justify-start-md {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 960px) {
  .justify-start-lg {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 1120px) {
  .justify-start-xl {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 1280px) {
  .justify-start-xxl {
    justify-content: flex-start;
  }
}

.justify-content-end,
.justify-end {
  justify-content: flex-end;
}
@media only screen and (min-width: 480px) {
  .justify-content-end-xs,
  .justify-end-xs {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 640px) {
  .justify-content-end-sm,
  .justify-end-sm {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 800px) {
  .justify-content-end-md,
  .justify-end-md {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 960px) {
  .justify-content-end-lg,
  .justify-end-lg {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 1120px) {
  .justify-content-end-xl,
  .justify-end-xl {
    justify-content: flex-end;
  }
}
@media only screen and (min-width: 1280px) {
  .justify-content-end-xxl,
  .justify-end-xxl {
    justify-content: flex-end;
  }
}

.justify-center {
  justify-content: center;
}
@media only screen and (min-width: 480px) {
  .justify-center-xs {
    justify-content: center;
  }
}
@media only screen and (min-width: 640px) {
  .justify-center-sm {
    justify-content: center;
  }
}
@media only screen and (min-width: 800px) {
  .justify-center-md {
    justify-content: center;
  }
}
@media only screen and (min-width: 960px) {
  .justify-center-lg {
    justify-content: center;
  }
}
@media only screen and (min-width: 1120px) {
  .justify-center-xl {
    justify-content: center;
  }
}
@media only screen and (min-width: 1280px) {
  .justify-center-xxl {
    justify-content: center;
  }
}

.justify-between {
  justify-content: space-between;
}
@media only screen and (min-width: 480px) {
  .justify-between-xs {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 640px) {
  .justify-between-sm {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 800px) {
  .justify-between-md {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 960px) {
  .justify-between-lg {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1120px) {
  .justify-between-xl {
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1280px) {
  .justify-between-xxl {
    justify-content: space-between;
  }
}

.gap-auto {
  gap: auto;
}
.gap-0 {
  gap: 0px;
}
.gap-2 {
  gap: 2px;
}
.gap-4 {
  gap: 4px;
}
.gap-8 {
  gap: 8px;
}
.gap-10 {
  gap: 10px;
}
.gap-16 {
  gap: 16px;
}
.gap-24 {
  gap: 24px;
}
.gap-32 {
  gap: 32px;
}
.gap-48 {
  gap: 48px;
}
.gap-64 {
  gap: 64px;
}
.gap-72 {
  gap: 72px;
}
.gap-96 {
  gap: 96px;
}
.gap-128 {
  gap: 128px;
}
@media only screen and (min-width: 480px) {
  .gap-xs-auto {
    gap: auto;
  }
  .gap-xs-0 {
    gap: 0px;
  }
  .gap-xs-2 {
    gap: 2px;
  }
  .gap-xs-4 {
    gap: 4px;
  }
  .gap-xs-8 {
    gap: 8px;
  }
  .gap-xs-10 {
    gap: 10px;
  }
  .gap-xs-16 {
    gap: 16px;
  }
  .gap-xs-24 {
    gap: 24px;
  }
  .gap-xs-32 {
    gap: 32px;
  }
  .gap-xs-48 {
    gap: 48px;
  }
  .gap-xs-64 {
    gap: 64px;
  }
  .gap-xs-72 {
    gap: 72px;
  }
  .gap-xs-96 {
    gap: 96px;
  }
  .gap-xs-128 {
    gap: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .gap-sm-auto {
    gap: auto;
  }
  .gap-sm-0 {
    gap: 0px;
  }
  .gap-sm-2 {
    gap: 2px;
  }
  .gap-sm-4 {
    gap: 4px;
  }
  .gap-sm-8 {
    gap: 8px;
  }
  .gap-sm-10 {
    gap: 10px;
  }
  .gap-sm-16 {
    gap: 16px;
  }
  .gap-sm-24 {
    gap: 24px;
  }
  .gap-sm-32 {
    gap: 32px;
  }
  .gap-sm-48 {
    gap: 48px;
  }
  .gap-sm-64 {
    gap: 64px;
  }
  .gap-sm-72 {
    gap: 72px;
  }
  .gap-sm-96 {
    gap: 96px;
  }
  .gap-sm-128 {
    gap: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .gap-md-auto {
    gap: auto;
  }
  .gap-md-0 {
    gap: 0px;
  }
  .gap-md-2 {
    gap: 2px;
  }
  .gap-md-4 {
    gap: 4px;
  }
  .gap-md-8 {
    gap: 8px;
  }
  .gap-md-10 {
    gap: 10px;
  }
  .gap-md-16 {
    gap: 16px;
  }
  .gap-md-24 {
    gap: 24px;
  }
  .gap-md-32 {
    gap: 32px;
  }
  .gap-md-48 {
    gap: 48px;
  }
  .gap-md-64 {
    gap: 64px;
  }
  .gap-md-72 {
    gap: 72px;
  }
  .gap-md-96 {
    gap: 96px;
  }
  .gap-md-128 {
    gap: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .gap-lg-auto {
    gap: auto;
  }
  .gap-lg-0 {
    gap: 0px;
  }
  .gap-lg-2 {
    gap: 2px;
  }
  .gap-lg-4 {
    gap: 4px;
  }
  .gap-lg-8 {
    gap: 8px;
  }
  .gap-lg-10 {
    gap: 10px;
  }
  .gap-lg-16 {
    gap: 16px;
  }
  .gap-lg-24 {
    gap: 24px;
  }
  .gap-lg-32 {
    gap: 32px;
  }
  .gap-lg-48 {
    gap: 48px;
  }
  .gap-lg-64 {
    gap: 64px;
  }
  .gap-lg-72 {
    gap: 72px;
  }
  .gap-lg-96 {
    gap: 96px;
  }
  .gap-lg-128 {
    gap: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .gap-xl-auto {
    gap: auto;
  }
  .gap-xl-0 {
    gap: 0px;
  }
  .gap-xl-2 {
    gap: 2px;
  }
  .gap-xl-4 {
    gap: 4px;
  }
  .gap-xl-8 {
    gap: 8px;
  }
  .gap-xl-10 {
    gap: 10px;
  }
  .gap-xl-16 {
    gap: 16px;
  }
  .gap-xl-24 {
    gap: 24px;
  }
  .gap-xl-32 {
    gap: 32px;
  }
  .gap-xl-48 {
    gap: 48px;
  }
  .gap-xl-64 {
    gap: 64px;
  }
  .gap-xl-72 {
    gap: 72px;
  }
  .gap-xl-96 {
    gap: 96px;
  }
  .gap-xl-128 {
    gap: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .gap-xxl-auto {
    gap: auto;
  }
  .gap-xxl-0 {
    gap: 0px;
  }
  .gap-xxl-2 {
    gap: 2px;
  }
  .gap-xxl-4 {
    gap: 4px;
  }
  .gap-xxl-8 {
    gap: 8px;
  }
  .gap-xxl-10 {
    gap: 10px;
  }
  .gap-xxl-16 {
    gap: 16px;
  }
  .gap-xxl-24 {
    gap: 24px;
  }
  .gap-xxl-32 {
    gap: 32px;
  }
  .gap-xxl-48 {
    gap: 48px;
  }
  .gap-xxl-64 {
    gap: 64px;
  }
  .gap-xxl-72 {
    gap: 72px;
  }
  .gap-xxl-96 {
    gap: 96px;
  }
  .gap-xxl-128 {
    gap: 128px;
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(12, minmax(0px, 1fr));
  grid-auto-rows: auto;
  gap: 24px;
}

.col-1 {
  grid-column: auto/span 1;
}
.col-2 {
  grid-column: auto/span 2;
}
.col-3 {
  grid-column: auto/span 3;
}
.col-4 {
  grid-column: auto/span 4;
}
.col-5 {
  grid-column: auto/span 5;
}
.col-6 {
  grid-column: auto/span 6;
}
.col-7 {
  grid-column: auto/span 7;
}
.col-8 {
  grid-column: auto/span 8;
}
.col-9 {
  grid-column: auto/span 9;
}
.col-10 {
  grid-column: auto/span 10;
}
.col-11 {
  grid-column: auto/span 11;
}
.col-12 {
  grid-column: auto/span 12;
}
@media only screen and (min-width: 480px) {
  .col-1--xs {
    grid-column: auto/span 1;
  }
  .col-2--xs {
    grid-column: auto/span 2;
  }
  .col-3--xs {
    grid-column: auto/span 3;
  }
  .col-4--xs {
    grid-column: auto/span 4;
  }
  .col-5--xs {
    grid-column: auto/span 5;
  }
  .col-6--xs {
    grid-column: auto/span 6;
  }
  .col-7--xs {
    grid-column: auto/span 7;
  }
  .col-8--xs {
    grid-column: auto/span 8;
  }
  .col-9--xs {
    grid-column: auto/span 9;
  }
  .col-10--xs {
    grid-column: auto/span 10;
  }
  .col-11--xs {
    grid-column: auto/span 11;
  }
  .col-12--xs {
    grid-column: auto/span 12;
  }
}
@media only screen and (min-width: 640px) {
  .col-1--sm {
    grid-column: auto/span 1;
  }
  .col-2--sm {
    grid-column: auto/span 2;
  }
  .col-3--sm {
    grid-column: auto/span 3;
  }
  .col-4--sm {
    grid-column: auto/span 4;
  }
  .col-5--sm {
    grid-column: auto/span 5;
  }
  .col-6--sm {
    grid-column: auto/span 6;
  }
  .col-7--sm {
    grid-column: auto/span 7;
  }
  .col-8--sm {
    grid-column: auto/span 8;
  }
  .col-9--sm {
    grid-column: auto/span 9;
  }
  .col-10--sm {
    grid-column: auto/span 10;
  }
  .col-11--sm {
    grid-column: auto/span 11;
  }
  .col-12--sm {
    grid-column: auto/span 12;
  }
}
@media only screen and (min-width: 800px) {
  .col-1--md {
    grid-column: auto/span 1;
  }
  .col-2--md {
    grid-column: auto/span 2;
  }
  .col-3--md {
    grid-column: auto/span 3;
  }
  .col-4--md {
    grid-column: auto/span 4;
  }
  .col-5--md {
    grid-column: auto/span 5;
  }
  .col-6--md {
    grid-column: auto/span 6;
  }
  .col-7--md {
    grid-column: auto/span 7;
  }
  .col-8--md {
    grid-column: auto/span 8;
  }
  .col-9--md {
    grid-column: auto/span 9;
  }
  .col-10--md {
    grid-column: auto/span 10;
  }
  .col-11--md {
    grid-column: auto/span 11;
  }
  .col-12--md {
    grid-column: auto/span 12;
  }
}
@media only screen and (min-width: 960px) {
  .col-1--lg {
    grid-column: auto/span 1;
  }
  .col-2--lg {
    grid-column: auto/span 2;
  }
  .col-3--lg {
    grid-column: auto/span 3;
  }
  .col-4--lg {
    grid-column: auto/span 4;
  }
  .col-5--lg {
    grid-column: auto/span 5;
  }
  .col-6--lg {
    grid-column: auto/span 6;
  }
  .col-7--lg {
    grid-column: auto/span 7;
  }
  .col-8--lg {
    grid-column: auto/span 8;
  }
  .col-9--lg {
    grid-column: auto/span 9;
  }
  .col-10--lg {
    grid-column: auto/span 10;
  }
  .col-11--lg {
    grid-column: auto/span 11;
  }
  .col-12--lg {
    grid-column: auto/span 12;
  }
}
@media only screen and (min-width: 1120px) {
  .col-1--xl {
    grid-column: auto/span 1;
  }
  .col-2--xl {
    grid-column: auto/span 2;
  }
  .col-3--xl {
    grid-column: auto/span 3;
  }
  .col-4--xl {
    grid-column: auto/span 4;
  }
  .col-5--xl {
    grid-column: auto/span 5;
  }
  .col-6--xl {
    grid-column: auto/span 6;
  }
  .col-7--xl {
    grid-column: auto/span 7;
  }
  .col-8--xl {
    grid-column: auto/span 8;
  }
  .col-9--xl {
    grid-column: auto/span 9;
  }
  .col-10--xl {
    grid-column: auto/span 10;
  }
  .col-11--xl {
    grid-column: auto/span 11;
  }
  .col-12--xl {
    grid-column: auto/span 12;
  }
}
@media only screen and (min-width: 1280px) {
  .col-1--xxl {
    grid-column: auto/span 1;
  }
  .col-2--xxl {
    grid-column: auto/span 2;
  }
  .col-3--xxl {
    grid-column: auto/span 3;
  }
  .col-4--xxl {
    grid-column: auto/span 4;
  }
  .col-5--xxl {
    grid-column: auto/span 5;
  }
  .col-6--xxl {
    grid-column: auto/span 6;
  }
  .col-7--xxl {
    grid-column: auto/span 7;
  }
  .col-8--xxl {
    grid-column: auto/span 8;
  }
  .col-9--xxl {
    grid-column: auto/span 9;
  }
  .col-10--xxl {
    grid-column: auto/span 10;
  }
  .col-11--xxl {
    grid-column: auto/span 11;
  }
  .col-12--xxl {
    grid-column: auto/span 12;
  }
}

.m-auto {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.m-0 {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.m-2 {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 2px;
  margin-right: 2px;
}
.m-4 {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 4px;
  margin-right: 4px;
}
.m-8 {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
}
.m-10 {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.m-16 {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
}
.m-24 {
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
}
.m-32 {
  margin-top: 32px;
  margin-bottom: 32px;
  margin-left: 32px;
  margin-right: 32px;
}
.m-48 {
  margin-top: 48px;
  margin-bottom: 48px;
  margin-left: 48px;
  margin-right: 48px;
}
.m-64 {
  margin-top: 64px;
  margin-bottom: 64px;
  margin-left: 64px;
  margin-right: 64px;
}
.m-72 {
  margin-top: 72px;
  margin-bottom: 72px;
  margin-left: 72px;
  margin-right: 72px;
}
.m-96 {
  margin-top: 96px;
  margin-bottom: 96px;
  margin-left: 96px;
  margin-right: 96px;
}
.m-128 {
  margin-top: 128px;
  margin-bottom: 128px;
  margin-left: 128px;
  margin-right: 128px;
}
@media only screen and (min-width: 480px) {
  .m-xs-auto {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .m-xs-0 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .m-xs-2 {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .m-xs-4 {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .m-xs-8 {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .m-xs-10 {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .m-xs-16 {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .m-xs-24 {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .m-xs-32 {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: 32px;
    margin-right: 32px;
  }
  .m-xs-48 {
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: 48px;
    margin-right: 48px;
  }
  .m-xs-64 {
    margin-top: 64px;
    margin-bottom: 64px;
    margin-left: 64px;
    margin-right: 64px;
  }
  .m-xs-72 {
    margin-top: 72px;
    margin-bottom: 72px;
    margin-left: 72px;
    margin-right: 72px;
  }
  .m-xs-96 {
    margin-top: 96px;
    margin-bottom: 96px;
    margin-left: 96px;
    margin-right: 96px;
  }
  .m-xs-128 {
    margin-top: 128px;
    margin-bottom: 128px;
    margin-left: 128px;
    margin-right: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .m-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .m-sm-0 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .m-sm-2 {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .m-sm-4 {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .m-sm-8 {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .m-sm-10 {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .m-sm-16 {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .m-sm-24 {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .m-sm-32 {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: 32px;
    margin-right: 32px;
  }
  .m-sm-48 {
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: 48px;
    margin-right: 48px;
  }
  .m-sm-64 {
    margin-top: 64px;
    margin-bottom: 64px;
    margin-left: 64px;
    margin-right: 64px;
  }
  .m-sm-72 {
    margin-top: 72px;
    margin-bottom: 72px;
    margin-left: 72px;
    margin-right: 72px;
  }
  .m-sm-96 {
    margin-top: 96px;
    margin-bottom: 96px;
    margin-left: 96px;
    margin-right: 96px;
  }
  .m-sm-128 {
    margin-top: 128px;
    margin-bottom: 128px;
    margin-left: 128px;
    margin-right: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .m-md-auto {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .m-md-0 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .m-md-2 {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .m-md-4 {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .m-md-8 {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .m-md-10 {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .m-md-16 {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .m-md-24 {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .m-md-32 {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: 32px;
    margin-right: 32px;
  }
  .m-md-48 {
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: 48px;
    margin-right: 48px;
  }
  .m-md-64 {
    margin-top: 64px;
    margin-bottom: 64px;
    margin-left: 64px;
    margin-right: 64px;
  }
  .m-md-72 {
    margin-top: 72px;
    margin-bottom: 72px;
    margin-left: 72px;
    margin-right: 72px;
  }
  .m-md-96 {
    margin-top: 96px;
    margin-bottom: 96px;
    margin-left: 96px;
    margin-right: 96px;
  }
  .m-md-128 {
    margin-top: 128px;
    margin-bottom: 128px;
    margin-left: 128px;
    margin-right: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .m-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .m-lg-0 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .m-lg-2 {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .m-lg-4 {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .m-lg-8 {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .m-lg-10 {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .m-lg-16 {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .m-lg-24 {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .m-lg-32 {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: 32px;
    margin-right: 32px;
  }
  .m-lg-48 {
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: 48px;
    margin-right: 48px;
  }
  .m-lg-64 {
    margin-top: 64px;
    margin-bottom: 64px;
    margin-left: 64px;
    margin-right: 64px;
  }
  .m-lg-72 {
    margin-top: 72px;
    margin-bottom: 72px;
    margin-left: 72px;
    margin-right: 72px;
  }
  .m-lg-96 {
    margin-top: 96px;
    margin-bottom: 96px;
    margin-left: 96px;
    margin-right: 96px;
  }
  .m-lg-128 {
    margin-top: 128px;
    margin-bottom: 128px;
    margin-left: 128px;
    margin-right: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .m-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .m-xl-0 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .m-xl-2 {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .m-xl-4 {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .m-xl-8 {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .m-xl-10 {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .m-xl-16 {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .m-xl-24 {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .m-xl-32 {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: 32px;
    margin-right: 32px;
  }
  .m-xl-48 {
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: 48px;
    margin-right: 48px;
  }
  .m-xl-64 {
    margin-top: 64px;
    margin-bottom: 64px;
    margin-left: 64px;
    margin-right: 64px;
  }
  .m-xl-72 {
    margin-top: 72px;
    margin-bottom: 72px;
    margin-left: 72px;
    margin-right: 72px;
  }
  .m-xl-96 {
    margin-top: 96px;
    margin-bottom: 96px;
    margin-left: 96px;
    margin-right: 96px;
  }
  .m-xl-128 {
    margin-top: 128px;
    margin-bottom: 128px;
    margin-left: 128px;
    margin-right: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .m-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .m-xxl-0 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .m-xxl-2 {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .m-xxl-4 {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
  }
  .m-xxl-8 {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .m-xxl-10 {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .m-xxl-16 {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .m-xxl-24 {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .m-xxl-32 {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: 32px;
    margin-right: 32px;
  }
  .m-xxl-48 {
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: 48px;
    margin-right: 48px;
  }
  .m-xxl-64 {
    margin-top: 64px;
    margin-bottom: 64px;
    margin-left: 64px;
    margin-right: 64px;
  }
  .m-xxl-72 {
    margin-top: 72px;
    margin-bottom: 72px;
    margin-left: 72px;
    margin-right: 72px;
  }
  .m-xxl-96 {
    margin-top: 96px;
    margin-bottom: 96px;
    margin-left: 96px;
    margin-right: 96px;
  }
  .m-xxl-128 {
    margin-top: 128px;
    margin-bottom: 128px;
    margin-left: 128px;
    margin-right: 128px;
  }
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.my-2 {
  margin-top: 2px;
  margin-bottom: 2px;
}
.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.my-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.my-48 {
  margin-top: 48px;
  margin-bottom: 48px;
}
.my-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}
.my-72 {
  margin-top: 72px;
  margin-bottom: 72px;
}
.my-96 {
  margin-top: 96px;
  margin-bottom: 96px;
}
.my-128 {
  margin-top: 128px;
  margin-bottom: 128px;
}
@media only screen and (min-width: 480px) {
  .my-xs-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .my-xs-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .my-xs-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .my-xs-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .my-xs-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-xs-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .my-xs-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-xs-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-xs-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-xs-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-xs-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-xs-72 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-xs-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .my-xs-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .my-sm-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .my-sm-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .my-sm-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .my-sm-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-sm-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .my-sm-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-sm-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-sm-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-sm-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-sm-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-sm-72 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-sm-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .my-sm-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .my-md-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .my-md-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .my-md-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .my-md-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-md-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .my-md-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-md-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-md-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-md-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-md-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-md-72 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-md-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .my-md-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .my-lg-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .my-lg-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .my-lg-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .my-lg-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-lg-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .my-lg-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-lg-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-lg-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-lg-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-lg-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-lg-72 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-lg-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .my-lg-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .my-xl-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .my-xl-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .my-xl-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .my-xl-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-xl-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .my-xl-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-xl-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-xl-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-xl-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-xl-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-xl-72 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-xl-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .my-xl-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .my-xxl-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .my-xxl-2 {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .my-xxl-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .my-xxl-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-xxl-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .my-xxl-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-xxl-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-xxl-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-xxl-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-xxl-64 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-xxl-72 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-xxl-96 {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  .my-xxl-128 {
    margin-top: 128px;
    margin-bottom: 128px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.mx-2 {
  margin-left: 2px;
  margin-right: 2px;
}
.mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}
.mx-8 {
  margin-left: 8px;
  margin-right: 8px;
}
.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.mx-16 {
  margin-left: 16px;
  margin-right: 16px;
}
.mx-24 {
  margin-left: 24px;
  margin-right: 24px;
}
.mx-32 {
  margin-left: 32px;
  margin-right: 32px;
}
.mx-48 {
  margin-left: 48px;
  margin-right: 48px;
}
.mx-64 {
  margin-left: 64px;
  margin-right: 64px;
}
.mx-72 {
  margin-left: 72px;
  margin-right: 72px;
}
.mx-96 {
  margin-left: 96px;
  margin-right: 96px;
}
.mx-128 {
  margin-left: 128px;
  margin-right: 128px;
}
@media only screen and (min-width: 480px) {
  .mx-xs-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-xs-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mx-xs-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mx-xs-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mx-xs-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mx-xs-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mx-xs-16 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .mx-xs-24 {
    margin-left: 24px;
    margin-right: 24px;
  }
  .mx-xs-32 {
    margin-left: 32px;
    margin-right: 32px;
  }
  .mx-xs-48 {
    margin-left: 48px;
    margin-right: 48px;
  }
  .mx-xs-64 {
    margin-left: 64px;
    margin-right: 64px;
  }
  .mx-xs-72 {
    margin-left: 72px;
    margin-right: 72px;
  }
  .mx-xs-96 {
    margin-left: 96px;
    margin-right: 96px;
  }
  .mx-xs-128 {
    margin-left: 128px;
    margin-right: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .mx-sm-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-sm-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mx-sm-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mx-sm-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mx-sm-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mx-sm-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mx-sm-16 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .mx-sm-24 {
    margin-left: 24px;
    margin-right: 24px;
  }
  .mx-sm-32 {
    margin-left: 32px;
    margin-right: 32px;
  }
  .mx-sm-48 {
    margin-left: 48px;
    margin-right: 48px;
  }
  .mx-sm-64 {
    margin-left: 64px;
    margin-right: 64px;
  }
  .mx-sm-72 {
    margin-left: 72px;
    margin-right: 72px;
  }
  .mx-sm-96 {
    margin-left: 96px;
    margin-right: 96px;
  }
  .mx-sm-128 {
    margin-left: 128px;
    margin-right: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .mx-md-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-md-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mx-md-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mx-md-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mx-md-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mx-md-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mx-md-16 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .mx-md-24 {
    margin-left: 24px;
    margin-right: 24px;
  }
  .mx-md-32 {
    margin-left: 32px;
    margin-right: 32px;
  }
  .mx-md-48 {
    margin-left: 48px;
    margin-right: 48px;
  }
  .mx-md-64 {
    margin-left: 64px;
    margin-right: 64px;
  }
  .mx-md-72 {
    margin-left: 72px;
    margin-right: 72px;
  }
  .mx-md-96 {
    margin-left: 96px;
    margin-right: 96px;
  }
  .mx-md-128 {
    margin-left: 128px;
    margin-right: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .mx-lg-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-lg-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mx-lg-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mx-lg-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mx-lg-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mx-lg-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mx-lg-16 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .mx-lg-24 {
    margin-left: 24px;
    margin-right: 24px;
  }
  .mx-lg-32 {
    margin-left: 32px;
    margin-right: 32px;
  }
  .mx-lg-48 {
    margin-left: 48px;
    margin-right: 48px;
  }
  .mx-lg-64 {
    margin-left: 64px;
    margin-right: 64px;
  }
  .mx-lg-72 {
    margin-left: 72px;
    margin-right: 72px;
  }
  .mx-lg-96 {
    margin-left: 96px;
    margin-right: 96px;
  }
  .mx-lg-128 {
    margin-left: 128px;
    margin-right: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .mx-xl-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-xl-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mx-xl-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mx-xl-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mx-xl-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mx-xl-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mx-xl-16 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .mx-xl-24 {
    margin-left: 24px;
    margin-right: 24px;
  }
  .mx-xl-32 {
    margin-left: 32px;
    margin-right: 32px;
  }
  .mx-xl-48 {
    margin-left: 48px;
    margin-right: 48px;
  }
  .mx-xl-64 {
    margin-left: 64px;
    margin-right: 64px;
  }
  .mx-xl-72 {
    margin-left: 72px;
    margin-right: 72px;
  }
  .mx-xl-96 {
    margin-left: 96px;
    margin-right: 96px;
  }
  .mx-xl-128 {
    margin-left: 128px;
    margin-right: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .mx-xxl-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .mx-xxl-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mx-xxl-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mx-xxl-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mx-xxl-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mx-xxl-10 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .mx-xxl-16 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .mx-xxl-24 {
    margin-left: 24px;
    margin-right: 24px;
  }
  .mx-xxl-32 {
    margin-left: 32px;
    margin-right: 32px;
  }
  .mx-xxl-48 {
    margin-left: 48px;
    margin-right: 48px;
  }
  .mx-xxl-64 {
    margin-left: 64px;
    margin-right: 64px;
  }
  .mx-xxl-72 {
    margin-left: 72px;
    margin-right: 72px;
  }
  .mx-xxl-96 {
    margin-left: 96px;
    margin-right: 96px;
  }
  .mx-xxl-128 {
    margin-left: 128px;
    margin-right: 128px;
  }
}

.mt-auto {
  margin-top: auto;
}
.mt-0 {
  margin-top: 0px;
}
.mt-2 {
  margin-top: 2px;
}
.mt-4 {
  margin-top: 4px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-48 {
  margin-top: 48px;
}
.mt-64 {
  margin-top: 64px;
}
.mt-72 {
  margin-top: 72px;
}
.mt-96 {
  margin-top: 96px;
}
.mt-128 {
  margin-top: 128px;
}
@media only screen and (min-width: 480px) {
  .mt-xs-auto {
    margin-top: auto;
  }
  .mt-xs-0 {
    margin-top: 0px;
  }
  .mt-xs-2 {
    margin-top: 2px;
  }
  .mt-xs-4 {
    margin-top: 4px;
  }
  .mt-xs-8 {
    margin-top: 8px;
  }
  .mt-xs-10 {
    margin-top: 10px;
  }
  .mt-xs-16 {
    margin-top: 16px;
  }
  .mt-xs-24 {
    margin-top: 24px;
  }
  .mt-xs-32 {
    margin-top: 32px;
  }
  .mt-xs-48 {
    margin-top: 48px;
  }
  .mt-xs-64 {
    margin-top: 64px;
  }
  .mt-xs-72 {
    margin-top: 72px;
  }
  .mt-xs-96 {
    margin-top: 96px;
  }
  .mt-xs-128 {
    margin-top: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .mt-sm-auto {
    margin-top: auto;
  }
  .mt-sm-0 {
    margin-top: 0px;
  }
  .mt-sm-2 {
    margin-top: 2px;
  }
  .mt-sm-4 {
    margin-top: 4px;
  }
  .mt-sm-8 {
    margin-top: 8px;
  }
  .mt-sm-10 {
    margin-top: 10px;
  }
  .mt-sm-16 {
    margin-top: 16px;
  }
  .mt-sm-24 {
    margin-top: 24px;
  }
  .mt-sm-32 {
    margin-top: 32px;
  }
  .mt-sm-48 {
    margin-top: 48px;
  }
  .mt-sm-64 {
    margin-top: 64px;
  }
  .mt-sm-72 {
    margin-top: 72px;
  }
  .mt-sm-96 {
    margin-top: 96px;
  }
  .mt-sm-128 {
    margin-top: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .mt-md-auto {
    margin-top: auto;
  }
  .mt-md-0 {
    margin-top: 0px;
  }
  .mt-md-2 {
    margin-top: 2px;
  }
  .mt-md-4 {
    margin-top: 4px;
  }
  .mt-md-8 {
    margin-top: 8px;
  }
  .mt-md-10 {
    margin-top: 10px;
  }
  .mt-md-16 {
    margin-top: 16px;
  }
  .mt-md-24 {
    margin-top: 24px;
  }
  .mt-md-32 {
    margin-top: 32px;
  }
  .mt-md-48 {
    margin-top: 48px;
  }
  .mt-md-64 {
    margin-top: 64px;
  }
  .mt-md-72 {
    margin-top: 72px;
  }
  .mt-md-96 {
    margin-top: 96px;
  }
  .mt-md-128 {
    margin-top: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .mt-lg-auto {
    margin-top: auto;
  }
  .mt-lg-0 {
    margin-top: 0px;
  }
  .mt-lg-2 {
    margin-top: 2px;
  }
  .mt-lg-4 {
    margin-top: 4px;
  }
  .mt-lg-8 {
    margin-top: 8px;
  }
  .mt-lg-10 {
    margin-top: 10px;
  }
  .mt-lg-16 {
    margin-top: 16px;
  }
  .mt-lg-24 {
    margin-top: 24px;
  }
  .mt-lg-32 {
    margin-top: 32px;
  }
  .mt-lg-48 {
    margin-top: 48px;
  }
  .mt-lg-64 {
    margin-top: 64px;
  }
  .mt-lg-72 {
    margin-top: 72px;
  }
  .mt-lg-96 {
    margin-top: 96px;
  }
  .mt-lg-128 {
    margin-top: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .mt-xl-auto {
    margin-top: auto;
  }
  .mt-xl-0 {
    margin-top: 0px;
  }
  .mt-xl-2 {
    margin-top: 2px;
  }
  .mt-xl-4 {
    margin-top: 4px;
  }
  .mt-xl-8 {
    margin-top: 8px;
  }
  .mt-xl-10 {
    margin-top: 10px;
  }
  .mt-xl-16 {
    margin-top: 16px;
  }
  .mt-xl-24 {
    margin-top: 24px;
  }
  .mt-xl-32 {
    margin-top: 32px;
  }
  .mt-xl-48 {
    margin-top: 48px;
  }
  .mt-xl-64 {
    margin-top: 64px;
  }
  .mt-xl-72 {
    margin-top: 72px;
  }
  .mt-xl-96 {
    margin-top: 96px;
  }
  .mt-xl-128 {
    margin-top: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .mt-xxl-auto {
    margin-top: auto;
  }
  .mt-xxl-0 {
    margin-top: 0px;
  }
  .mt-xxl-2 {
    margin-top: 2px;
  }
  .mt-xxl-4 {
    margin-top: 4px;
  }
  .mt-xxl-8 {
    margin-top: 8px;
  }
  .mt-xxl-10 {
    margin-top: 10px;
  }
  .mt-xxl-16 {
    margin-top: 16px;
  }
  .mt-xxl-24 {
    margin-top: 24px;
  }
  .mt-xxl-32 {
    margin-top: 32px;
  }
  .mt-xxl-48 {
    margin-top: 48px;
  }
  .mt-xxl-64 {
    margin-top: 64px;
  }
  .mt-xxl-72 {
    margin-top: 72px;
  }
  .mt-xxl-96 {
    margin-top: 96px;
  }
  .mt-xxl-128 {
    margin-top: 128px;
  }
}

.mb-auto {
  margin-bottom: auto;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-2 {
  margin-bottom: 2px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-32 {
  margin-bottom: 32px;
}
.mb-48 {
  margin-bottom: 48px;
}
.mb-64 {
  margin-bottom: 64px;
}
.mb-72 {
  margin-bottom: 72px;
}
.mb-96 {
  margin-bottom: 96px;
}
.mb-128 {
  margin-bottom: 128px;
}
@media only screen and (min-width: 480px) {
  .mb-xs-auto {
    margin-bottom: auto;
  }
  .mb-xs-0 {
    margin-bottom: 0px;
  }
  .mb-xs-2 {
    margin-bottom: 2px;
  }
  .mb-xs-4 {
    margin-bottom: 4px;
  }
  .mb-xs-8 {
    margin-bottom: 8px;
  }
  .mb-xs-10 {
    margin-bottom: 10px;
  }
  .mb-xs-16 {
    margin-bottom: 16px;
  }
  .mb-xs-24 {
    margin-bottom: 24px;
  }
  .mb-xs-32 {
    margin-bottom: 32px;
  }
  .mb-xs-48 {
    margin-bottom: 48px;
  }
  .mb-xs-64 {
    margin-bottom: 64px;
  }
  .mb-xs-72 {
    margin-bottom: 72px;
  }
  .mb-xs-96 {
    margin-bottom: 96px;
  }
  .mb-xs-128 {
    margin-bottom: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .mb-sm-auto {
    margin-bottom: auto;
  }
  .mb-sm-0 {
    margin-bottom: 0px;
  }
  .mb-sm-2 {
    margin-bottom: 2px;
  }
  .mb-sm-4 {
    margin-bottom: 4px;
  }
  .mb-sm-8 {
    margin-bottom: 8px;
  }
  .mb-sm-10 {
    margin-bottom: 10px;
  }
  .mb-sm-16 {
    margin-bottom: 16px;
  }
  .mb-sm-24 {
    margin-bottom: 24px;
  }
  .mb-sm-32 {
    margin-bottom: 32px;
  }
  .mb-sm-48 {
    margin-bottom: 48px;
  }
  .mb-sm-64 {
    margin-bottom: 64px;
  }
  .mb-sm-72 {
    margin-bottom: 72px;
  }
  .mb-sm-96 {
    margin-bottom: 96px;
  }
  .mb-sm-128 {
    margin-bottom: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .mb-md-auto {
    margin-bottom: auto;
  }
  .mb-md-0 {
    margin-bottom: 0px;
  }
  .mb-md-2 {
    margin-bottom: 2px;
  }
  .mb-md-4 {
    margin-bottom: 4px;
  }
  .mb-md-8 {
    margin-bottom: 8px;
  }
  .mb-md-10 {
    margin-bottom: 10px;
  }
  .mb-md-16 {
    margin-bottom: 16px;
  }
  .mb-md-24 {
    margin-bottom: 24px;
  }
  .mb-md-32 {
    margin-bottom: 32px;
  }
  .mb-md-48 {
    margin-bottom: 48px;
  }
  .mb-md-64 {
    margin-bottom: 64px;
  }
  .mb-md-72 {
    margin-bottom: 72px;
  }
  .mb-md-96 {
    margin-bottom: 96px;
  }
  .mb-md-128 {
    margin-bottom: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .mb-lg-auto {
    margin-bottom: auto;
  }
  .mb-lg-0 {
    margin-bottom: 0px;
  }
  .mb-lg-2 {
    margin-bottom: 2px;
  }
  .mb-lg-4 {
    margin-bottom: 4px;
  }
  .mb-lg-8 {
    margin-bottom: 8px;
  }
  .mb-lg-10 {
    margin-bottom: 10px;
  }
  .mb-lg-16 {
    margin-bottom: 16px;
  }
  .mb-lg-24 {
    margin-bottom: 24px;
  }
  .mb-lg-32 {
    margin-bottom: 32px;
  }
  .mb-lg-48 {
    margin-bottom: 48px;
  }
  .mb-lg-64 {
    margin-bottom: 64px;
  }
  .mb-lg-72 {
    margin-bottom: 72px;
  }
  .mb-lg-96 {
    margin-bottom: 96px;
  }
  .mb-lg-128 {
    margin-bottom: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .mb-xl-auto {
    margin-bottom: auto;
  }
  .mb-xl-0 {
    margin-bottom: 0px;
  }
  .mb-xl-2 {
    margin-bottom: 2px;
  }
  .mb-xl-4 {
    margin-bottom: 4px;
  }
  .mb-xl-8 {
    margin-bottom: 8px;
  }
  .mb-xl-10 {
    margin-bottom: 10px;
  }
  .mb-xl-16 {
    margin-bottom: 16px;
  }
  .mb-xl-24 {
    margin-bottom: 24px;
  }
  .mb-xl-32 {
    margin-bottom: 32px;
  }
  .mb-xl-48 {
    margin-bottom: 48px;
  }
  .mb-xl-64 {
    margin-bottom: 64px;
  }
  .mb-xl-72 {
    margin-bottom: 72px;
  }
  .mb-xl-96 {
    margin-bottom: 96px;
  }
  .mb-xl-128 {
    margin-bottom: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .mb-xxl-auto {
    margin-bottom: auto;
  }
  .mb-xxl-0 {
    margin-bottom: 0px;
  }
  .mb-xxl-2 {
    margin-bottom: 2px;
  }
  .mb-xxl-4 {
    margin-bottom: 4px;
  }
  .mb-xxl-8 {
    margin-bottom: 8px;
  }
  .mb-xxl-10 {
    margin-bottom: 10px;
  }
  .mb-xxl-16 {
    margin-bottom: 16px;
  }
  .mb-xxl-24 {
    margin-bottom: 24px;
  }
  .mb-xxl-32 {
    margin-bottom: 32px;
  }
  .mb-xxl-48 {
    margin-bottom: 48px;
  }
  .mb-xxl-64 {
    margin-bottom: 64px;
  }
  .mb-xxl-72 {
    margin-bottom: 72px;
  }
  .mb-xxl-96 {
    margin-bottom: 96px;
  }
  .mb-xxl-128 {
    margin-bottom: 128px;
  }
}

.ml-auto {
  margin-left: auto;
}
.ml-0 {
  margin-left: 0px;
}
.ml-2 {
  margin-left: 2px;
}
.ml-4 {
  margin-left: 4px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-16 {
  margin-left: 16px;
}
.ml-24 {
  margin-left: 24px;
}
.ml-32 {
  margin-left: 32px;
}
.ml-48 {
  margin-left: 48px;
}
.ml-64 {
  margin-left: 64px;
}
.ml-72 {
  margin-left: 72px;
}
.ml-96 {
  margin-left: 96px;
}
.ml-128 {
  margin-left: 128px;
}
@media only screen and (min-width: 480px) {
  .ml-xs-auto {
    margin-left: auto;
  }
  .ml-xs-0 {
    margin-left: 0px;
  }
  .ml-xs-2 {
    margin-left: 2px;
  }
  .ml-xs-4 {
    margin-left: 4px;
  }
  .ml-xs-8 {
    margin-left: 8px;
  }
  .ml-xs-10 {
    margin-left: 10px;
  }
  .ml-xs-16 {
    margin-left: 16px;
  }
  .ml-xs-24 {
    margin-left: 24px;
  }
  .ml-xs-32 {
    margin-left: 32px;
  }
  .ml-xs-48 {
    margin-left: 48px;
  }
  .ml-xs-64 {
    margin-left: 64px;
  }
  .ml-xs-72 {
    margin-left: 72px;
  }
  .ml-xs-96 {
    margin-left: 96px;
  }
  .ml-xs-128 {
    margin-left: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .ml-sm-auto {
    margin-left: auto;
  }
  .ml-sm-0 {
    margin-left: 0px;
  }
  .ml-sm-2 {
    margin-left: 2px;
  }
  .ml-sm-4 {
    margin-left: 4px;
  }
  .ml-sm-8 {
    margin-left: 8px;
  }
  .ml-sm-10 {
    margin-left: 10px;
  }
  .ml-sm-16 {
    margin-left: 16px;
  }
  .ml-sm-24 {
    margin-left: 24px;
  }
  .ml-sm-32 {
    margin-left: 32px;
  }
  .ml-sm-48 {
    margin-left: 48px;
  }
  .ml-sm-64 {
    margin-left: 64px;
  }
  .ml-sm-72 {
    margin-left: 72px;
  }
  .ml-sm-96 {
    margin-left: 96px;
  }
  .ml-sm-128 {
    margin-left: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .ml-md-auto {
    margin-left: auto;
  }
  .ml-md-0 {
    margin-left: 0px;
  }
  .ml-md-2 {
    margin-left: 2px;
  }
  .ml-md-4 {
    margin-left: 4px;
  }
  .ml-md-8 {
    margin-left: 8px;
  }
  .ml-md-10 {
    margin-left: 10px;
  }
  .ml-md-16 {
    margin-left: 16px;
  }
  .ml-md-24 {
    margin-left: 24px;
  }
  .ml-md-32 {
    margin-left: 32px;
  }
  .ml-md-48 {
    margin-left: 48px;
  }
  .ml-md-64 {
    margin-left: 64px;
  }
  .ml-md-72 {
    margin-left: 72px;
  }
  .ml-md-96 {
    margin-left: 96px;
  }
  .ml-md-128 {
    margin-left: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .ml-lg-auto {
    margin-left: auto;
  }
  .ml-lg-0 {
    margin-left: 0px;
  }
  .ml-lg-2 {
    margin-left: 2px;
  }
  .ml-lg-4 {
    margin-left: 4px;
  }
  .ml-lg-8 {
    margin-left: 8px;
  }
  .ml-lg-10 {
    margin-left: 10px;
  }
  .ml-lg-16 {
    margin-left: 16px;
  }
  .ml-lg-24 {
    margin-left: 24px;
  }
  .ml-lg-32 {
    margin-left: 32px;
  }
  .ml-lg-48 {
    margin-left: 48px;
  }
  .ml-lg-64 {
    margin-left: 64px;
  }
  .ml-lg-72 {
    margin-left: 72px;
  }
  .ml-lg-96 {
    margin-left: 96px;
  }
  .ml-lg-128 {
    margin-left: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .ml-xl-auto {
    margin-left: auto;
  }
  .ml-xl-0 {
    margin-left: 0px;
  }
  .ml-xl-2 {
    margin-left: 2px;
  }
  .ml-xl-4 {
    margin-left: 4px;
  }
  .ml-xl-8 {
    margin-left: 8px;
  }
  .ml-xl-10 {
    margin-left: 10px;
  }
  .ml-xl-16 {
    margin-left: 16px;
  }
  .ml-xl-24 {
    margin-left: 24px;
  }
  .ml-xl-32 {
    margin-left: 32px;
  }
  .ml-xl-48 {
    margin-left: 48px;
  }
  .ml-xl-64 {
    margin-left: 64px;
  }
  .ml-xl-72 {
    margin-left: 72px;
  }
  .ml-xl-96 {
    margin-left: 96px;
  }
  .ml-xl-128 {
    margin-left: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .ml-xxl-auto {
    margin-left: auto;
  }
  .ml-xxl-0 {
    margin-left: 0px;
  }
  .ml-xxl-2 {
    margin-left: 2px;
  }
  .ml-xxl-4 {
    margin-left: 4px;
  }
  .ml-xxl-8 {
    margin-left: 8px;
  }
  .ml-xxl-10 {
    margin-left: 10px;
  }
  .ml-xxl-16 {
    margin-left: 16px;
  }
  .ml-xxl-24 {
    margin-left: 24px;
  }
  .ml-xxl-32 {
    margin-left: 32px;
  }
  .ml-xxl-48 {
    margin-left: 48px;
  }
  .ml-xxl-64 {
    margin-left: 64px;
  }
  .ml-xxl-72 {
    margin-left: 72px;
  }
  .ml-xxl-96 {
    margin-left: 96px;
  }
  .ml-xxl-128 {
    margin-left: 128px;
  }
}

.mr-auto {
  margin-right: auto;
}
.mr-0 {
  margin-right: 0px;
}
.mr-2 {
  margin-right: 2px;
}
.mr-4 {
  margin-right: 4px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-24 {
  margin-right: 24px;
}
.mr-32 {
  margin-right: 32px;
}
.mr-48 {
  margin-right: 48px;
}
.mr-64 {
  margin-right: 64px;
}
.mr-72 {
  margin-right: 72px;
}
.mr-96 {
  margin-right: 96px;
}
.mr-128 {
  margin-right: 128px;
}
@media only screen and (min-width: 480px) {
  .mr-xs-auto {
    margin-right: auto;
  }
  .mr-xs-0 {
    margin-right: 0px;
  }
  .mr-xs-2 {
    margin-right: 2px;
  }
  .mr-xs-4 {
    margin-right: 4px;
  }
  .mr-xs-8 {
    margin-right: 8px;
  }
  .mr-xs-10 {
    margin-right: 10px;
  }
  .mr-xs-16 {
    margin-right: 16px;
  }
  .mr-xs-24 {
    margin-right: 24px;
  }
  .mr-xs-32 {
    margin-right: 32px;
  }
  .mr-xs-48 {
    margin-right: 48px;
  }
  .mr-xs-64 {
    margin-right: 64px;
  }
  .mr-xs-72 {
    margin-right: 72px;
  }
  .mr-xs-96 {
    margin-right: 96px;
  }
  .mr-xs-128 {
    margin-right: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .mr-sm-auto {
    margin-right: auto;
  }
  .mr-sm-0 {
    margin-right: 0px;
  }
  .mr-sm-2 {
    margin-right: 2px;
  }
  .mr-sm-4 {
    margin-right: 4px;
  }
  .mr-sm-8 {
    margin-right: 8px;
  }
  .mr-sm-10 {
    margin-right: 10px;
  }
  .mr-sm-16 {
    margin-right: 16px;
  }
  .mr-sm-24 {
    margin-right: 24px;
  }
  .mr-sm-32 {
    margin-right: 32px;
  }
  .mr-sm-48 {
    margin-right: 48px;
  }
  .mr-sm-64 {
    margin-right: 64px;
  }
  .mr-sm-72 {
    margin-right: 72px;
  }
  .mr-sm-96 {
    margin-right: 96px;
  }
  .mr-sm-128 {
    margin-right: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .mr-md-auto {
    margin-right: auto;
  }
  .mr-md-0 {
    margin-right: 0px;
  }
  .mr-md-2 {
    margin-right: 2px;
  }
  .mr-md-4 {
    margin-right: 4px;
  }
  .mr-md-8 {
    margin-right: 8px;
  }
  .mr-md-10 {
    margin-right: 10px;
  }
  .mr-md-16 {
    margin-right: 16px;
  }
  .mr-md-24 {
    margin-right: 24px;
  }
  .mr-md-32 {
    margin-right: 32px;
  }
  .mr-md-48 {
    margin-right: 48px;
  }
  .mr-md-64 {
    margin-right: 64px;
  }
  .mr-md-72 {
    margin-right: 72px;
  }
  .mr-md-96 {
    margin-right: 96px;
  }
  .mr-md-128 {
    margin-right: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .mr-lg-auto {
    margin-right: auto;
  }
  .mr-lg-0 {
    margin-right: 0px;
  }
  .mr-lg-2 {
    margin-right: 2px;
  }
  .mr-lg-4 {
    margin-right: 4px;
  }
  .mr-lg-8 {
    margin-right: 8px;
  }
  .mr-lg-10 {
    margin-right: 10px;
  }
  .mr-lg-16 {
    margin-right: 16px;
  }
  .mr-lg-24 {
    margin-right: 24px;
  }
  .mr-lg-32 {
    margin-right: 32px;
  }
  .mr-lg-48 {
    margin-right: 48px;
  }
  .mr-lg-64 {
    margin-right: 64px;
  }
  .mr-lg-72 {
    margin-right: 72px;
  }
  .mr-lg-96 {
    margin-right: 96px;
  }
  .mr-lg-128 {
    margin-right: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .mr-xl-auto {
    margin-right: auto;
  }
  .mr-xl-0 {
    margin-right: 0px;
  }
  .mr-xl-2 {
    margin-right: 2px;
  }
  .mr-xl-4 {
    margin-right: 4px;
  }
  .mr-xl-8 {
    margin-right: 8px;
  }
  .mr-xl-10 {
    margin-right: 10px;
  }
  .mr-xl-16 {
    margin-right: 16px;
  }
  .mr-xl-24 {
    margin-right: 24px;
  }
  .mr-xl-32 {
    margin-right: 32px;
  }
  .mr-xl-48 {
    margin-right: 48px;
  }
  .mr-xl-64 {
    margin-right: 64px;
  }
  .mr-xl-72 {
    margin-right: 72px;
  }
  .mr-xl-96 {
    margin-right: 96px;
  }
  .mr-xl-128 {
    margin-right: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .mr-xxl-auto {
    margin-right: auto;
  }
  .mr-xxl-0 {
    margin-right: 0px;
  }
  .mr-xxl-2 {
    margin-right: 2px;
  }
  .mr-xxl-4 {
    margin-right: 4px;
  }
  .mr-xxl-8 {
    margin-right: 8px;
  }
  .mr-xxl-10 {
    margin-right: 10px;
  }
  .mr-xxl-16 {
    margin-right: 16px;
  }
  .mr-xxl-24 {
    margin-right: 24px;
  }
  .mr-xxl-32 {
    margin-right: 32px;
  }
  .mr-xxl-48 {
    margin-right: 48px;
  }
  .mr-xxl-64 {
    margin-right: 64px;
  }
  .mr-xxl-72 {
    margin-right: 72px;
  }
  .mr-xxl-96 {
    margin-right: 96px;
  }
  .mr-xxl-128 {
    margin-right: 128px;
  }
}

.p-auto {
  padding-top: auto;
  padding-bottom: auto;
  padding-left: auto;
  padding-right: auto;
}
.p-0 {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.p-2 {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
}
.p-4 {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
}
.p-8 {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
}
.p-10 {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.p-16 {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}
.p-24 {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
.p-32 {
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
}
.p-48 {
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 48px;
  padding-right: 48px;
}
.p-64 {
  padding-top: 64px;
  padding-bottom: 64px;
  padding-left: 64px;
  padding-right: 64px;
}
.p-72 {
  padding-top: 72px;
  padding-bottom: 72px;
  padding-left: 72px;
  padding-right: 72px;
}
.p-96 {
  padding-top: 96px;
  padding-bottom: 96px;
  padding-left: 96px;
  padding-right: 96px;
}
.p-128 {
  padding-top: 128px;
  padding-bottom: 128px;
  padding-left: 128px;
  padding-right: 128px;
}
@media only screen and (min-width: 480px) {
  .p-xs-auto {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .p-xs-0 {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .p-xs-2 {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .p-xs-4 {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .p-xs-8 {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .p-xs-10 {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .p-xs-16 {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .p-xs-24 {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .p-xs-32 {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .p-xs-48 {
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .p-xs-64 {
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 64px;
    padding-right: 64px;
  }
  .p-xs-72 {
    padding-top: 72px;
    padding-bottom: 72px;
    padding-left: 72px;
    padding-right: 72px;
  }
  .p-xs-96 {
    padding-top: 96px;
    padding-bottom: 96px;
    padding-left: 96px;
    padding-right: 96px;
  }
  .p-xs-128 {
    padding-top: 128px;
    padding-bottom: 128px;
    padding-left: 128px;
    padding-right: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .p-sm-auto {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .p-sm-0 {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .p-sm-2 {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .p-sm-4 {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .p-sm-8 {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .p-sm-10 {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .p-sm-16 {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .p-sm-24 {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .p-sm-32 {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .p-sm-48 {
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .p-sm-64 {
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 64px;
    padding-right: 64px;
  }
  .p-sm-72 {
    padding-top: 72px;
    padding-bottom: 72px;
    padding-left: 72px;
    padding-right: 72px;
  }
  .p-sm-96 {
    padding-top: 96px;
    padding-bottom: 96px;
    padding-left: 96px;
    padding-right: 96px;
  }
  .p-sm-128 {
    padding-top: 128px;
    padding-bottom: 128px;
    padding-left: 128px;
    padding-right: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .p-md-auto {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .p-md-0 {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .p-md-2 {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .p-md-4 {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .p-md-8 {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .p-md-10 {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .p-md-16 {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .p-md-24 {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .p-md-32 {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .p-md-48 {
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .p-md-64 {
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 64px;
    padding-right: 64px;
  }
  .p-md-72 {
    padding-top: 72px;
    padding-bottom: 72px;
    padding-left: 72px;
    padding-right: 72px;
  }
  .p-md-96 {
    padding-top: 96px;
    padding-bottom: 96px;
    padding-left: 96px;
    padding-right: 96px;
  }
  .p-md-128 {
    padding-top: 128px;
    padding-bottom: 128px;
    padding-left: 128px;
    padding-right: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .p-lg-auto {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .p-lg-0 {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .p-lg-2 {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .p-lg-4 {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .p-lg-8 {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .p-lg-10 {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .p-lg-16 {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .p-lg-24 {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .p-lg-32 {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .p-lg-48 {
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .p-lg-64 {
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 64px;
    padding-right: 64px;
  }
  .p-lg-72 {
    padding-top: 72px;
    padding-bottom: 72px;
    padding-left: 72px;
    padding-right: 72px;
  }
  .p-lg-96 {
    padding-top: 96px;
    padding-bottom: 96px;
    padding-left: 96px;
    padding-right: 96px;
  }
  .p-lg-128 {
    padding-top: 128px;
    padding-bottom: 128px;
    padding-left: 128px;
    padding-right: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .p-xl-auto {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .p-xl-0 {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .p-xl-2 {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .p-xl-4 {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .p-xl-8 {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .p-xl-10 {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .p-xl-16 {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .p-xl-24 {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .p-xl-32 {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .p-xl-48 {
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .p-xl-64 {
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 64px;
    padding-right: 64px;
  }
  .p-xl-72 {
    padding-top: 72px;
    padding-bottom: 72px;
    padding-left: 72px;
    padding-right: 72px;
  }
  .p-xl-96 {
    padding-top: 96px;
    padding-bottom: 96px;
    padding-left: 96px;
    padding-right: 96px;
  }
  .p-xl-128 {
    padding-top: 128px;
    padding-bottom: 128px;
    padding-left: 128px;
    padding-right: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .p-xxl-auto {
    padding-top: auto;
    padding-bottom: auto;
    padding-left: auto;
    padding-right: auto;
  }
  .p-xxl-0 {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .p-xxl-2 {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }
  .p-xxl-4 {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .p-xxl-8 {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .p-xxl-10 {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .p-xxl-16 {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .p-xxl-24 {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .p-xxl-32 {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }
  .p-xxl-48 {
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .p-xxl-64 {
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 64px;
    padding-right: 64px;
  }
  .p-xxl-72 {
    padding-top: 72px;
    padding-bottom: 72px;
    padding-left: 72px;
    padding-right: 72px;
  }
  .p-xxl-96 {
    padding-top: 96px;
    padding-bottom: 96px;
    padding-left: 96px;
    padding-right: 96px;
  }
  .p-xxl-128 {
    padding-top: 128px;
    padding-bottom: 128px;
    padding-left: 128px;
    padding-right: 128px;
  }
}

.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.py-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}
.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.py-48 {
  padding-top: 48px;
  padding-bottom: 48px;
}
.py-64 {
  padding-top: 64px;
  padding-bottom: 64px;
}
.py-72 {
  padding-top: 72px;
  padding-bottom: 72px;
}
.py-96 {
  padding-top: 96px;
  padding-bottom: 96px;
}
.py-128 {
  padding-top: 128px;
  padding-bottom: 128px;
}
@media only screen and (min-width: 480px) {
  .py-xs-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .py-xs-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .py-xs-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .py-xs-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .py-xs-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-xs-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .py-xs-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-xs-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-xs-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-xs-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-xs-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-xs-72 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-xs-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .py-xs-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .py-sm-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .py-sm-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .py-sm-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .py-sm-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .py-sm-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-sm-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .py-sm-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-sm-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-sm-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-sm-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-sm-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-sm-72 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-sm-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .py-sm-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .py-md-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .py-md-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .py-md-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .py-md-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .py-md-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-md-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .py-md-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-md-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-md-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-md-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-md-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-md-72 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-md-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .py-md-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .py-lg-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .py-lg-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .py-lg-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .py-lg-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .py-lg-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-lg-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .py-lg-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-lg-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-lg-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-lg-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-lg-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-lg-72 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-lg-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .py-lg-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .py-xl-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .py-xl-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .py-xl-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .py-xl-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .py-xl-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-xl-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .py-xl-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-xl-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-xl-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-xl-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-xl-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-xl-72 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-xl-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .py-xl-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .py-xxl-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .py-xxl-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .py-xxl-2 {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .py-xxl-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .py-xxl-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-xxl-10 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .py-xxl-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-xxl-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-xxl-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-xxl-48 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-xxl-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-xxl-72 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-xxl-96 {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  .py-xxl-128 {
    padding-top: 128px;
    padding-bottom: 128px;
  }
}

.px-auto {
  padding-left: auto;
  padding-right: auto;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-2 {
  padding-left: 2px;
  padding-right: 2px;
}
.px-4 {
  padding-left: 4px;
  padding-right: 4px;
}
.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.px-48 {
  padding-left: 48px;
  padding-right: 48px;
}
.px-64 {
  padding-left: 64px;
  padding-right: 64px;
}
.px-72 {
  padding-left: 72px;
  padding-right: 72px;
}
.px-96 {
  padding-left: 96px;
  padding-right: 96px;
}
.px-128 {
  padding-left: 128px;
  padding-right: 128px;
}
@media only screen and (min-width: 480px) {
  .px-xs-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .px-xs-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .px-xs-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .px-xs-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .px-xs-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .px-xs-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .px-xs-16 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .px-xs-24 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .px-xs-32 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .px-xs-48 {
    padding-left: 48px;
    padding-right: 48px;
  }
  .px-xs-64 {
    padding-left: 64px;
    padding-right: 64px;
  }
  .px-xs-72 {
    padding-left: 72px;
    padding-right: 72px;
  }
  .px-xs-96 {
    padding-left: 96px;
    padding-right: 96px;
  }
  .px-xs-128 {
    padding-left: 128px;
    padding-right: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .px-sm-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .px-sm-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .px-sm-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .px-sm-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .px-sm-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .px-sm-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .px-sm-16 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .px-sm-24 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .px-sm-32 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .px-sm-48 {
    padding-left: 48px;
    padding-right: 48px;
  }
  .px-sm-64 {
    padding-left: 64px;
    padding-right: 64px;
  }
  .px-sm-72 {
    padding-left: 72px;
    padding-right: 72px;
  }
  .px-sm-96 {
    padding-left: 96px;
    padding-right: 96px;
  }
  .px-sm-128 {
    padding-left: 128px;
    padding-right: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .px-md-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .px-md-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .px-md-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .px-md-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .px-md-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .px-md-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .px-md-16 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .px-md-24 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .px-md-32 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .px-md-48 {
    padding-left: 48px;
    padding-right: 48px;
  }
  .px-md-64 {
    padding-left: 64px;
    padding-right: 64px;
  }
  .px-md-72 {
    padding-left: 72px;
    padding-right: 72px;
  }
  .px-md-96 {
    padding-left: 96px;
    padding-right: 96px;
  }
  .px-md-128 {
    padding-left: 128px;
    padding-right: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .px-lg-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .px-lg-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .px-lg-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .px-lg-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .px-lg-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .px-lg-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .px-lg-16 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .px-lg-24 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .px-lg-32 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .px-lg-48 {
    padding-left: 48px;
    padding-right: 48px;
  }
  .px-lg-64 {
    padding-left: 64px;
    padding-right: 64px;
  }
  .px-lg-72 {
    padding-left: 72px;
    padding-right: 72px;
  }
  .px-lg-96 {
    padding-left: 96px;
    padding-right: 96px;
  }
  .px-lg-128 {
    padding-left: 128px;
    padding-right: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .px-xl-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .px-xl-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .px-xl-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .px-xl-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .px-xl-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .px-xl-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .px-xl-16 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .px-xl-24 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .px-xl-32 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .px-xl-48 {
    padding-left: 48px;
    padding-right: 48px;
  }
  .px-xl-64 {
    padding-left: 64px;
    padding-right: 64px;
  }
  .px-xl-72 {
    padding-left: 72px;
    padding-right: 72px;
  }
  .px-xl-96 {
    padding-left: 96px;
    padding-right: 96px;
  }
  .px-xl-128 {
    padding-left: 128px;
    padding-right: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .px-xxl-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .px-xxl-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .px-xxl-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .px-xxl-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .px-xxl-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .px-xxl-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .px-xxl-16 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .px-xxl-24 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .px-xxl-32 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .px-xxl-48 {
    padding-left: 48px;
    padding-right: 48px;
  }
  .px-xxl-64 {
    padding-left: 64px;
    padding-right: 64px;
  }
  .px-xxl-72 {
    padding-left: 72px;
    padding-right: 72px;
  }
  .px-xxl-96 {
    padding-left: 96px;
    padding-right: 96px;
  }
  .px-xxl-128 {
    padding-left: 128px;
    padding-right: 128px;
  }
}

.pt-auto {
  padding-top: auto;
}
.pt-0 {
  padding-top: 0px;
}
.pt-2 {
  padding-top: 2px;
}
.pt-4 {
  padding-top: 4px;
}
.pt-8 {
  padding-top: 8px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-24 {
  padding-top: 24px;
}
.pt-32 {
  padding-top: 32px;
}
.pt-48 {
  padding-top: 48px;
}
.pt-64 {
  padding-top: 64px;
}
.pt-72 {
  padding-top: 72px;
}
.pt-96 {
  padding-top: 96px;
}
.pt-128 {
  padding-top: 128px;
}
@media only screen and (min-width: 480px) {
  .pt-xs-auto {
    padding-top: auto;
  }
  .pt-xs-0 {
    padding-top: 0px;
  }
  .pt-xs-2 {
    padding-top: 2px;
  }
  .pt-xs-4 {
    padding-top: 4px;
  }
  .pt-xs-8 {
    padding-top: 8px;
  }
  .pt-xs-10 {
    padding-top: 10px;
  }
  .pt-xs-16 {
    padding-top: 16px;
  }
  .pt-xs-24 {
    padding-top: 24px;
  }
  .pt-xs-32 {
    padding-top: 32px;
  }
  .pt-xs-48 {
    padding-top: 48px;
  }
  .pt-xs-64 {
    padding-top: 64px;
  }
  .pt-xs-72 {
    padding-top: 72px;
  }
  .pt-xs-96 {
    padding-top: 96px;
  }
  .pt-xs-128 {
    padding-top: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .pt-sm-auto {
    padding-top: auto;
  }
  .pt-sm-0 {
    padding-top: 0px;
  }
  .pt-sm-2 {
    padding-top: 2px;
  }
  .pt-sm-4 {
    padding-top: 4px;
  }
  .pt-sm-8 {
    padding-top: 8px;
  }
  .pt-sm-10 {
    padding-top: 10px;
  }
  .pt-sm-16 {
    padding-top: 16px;
  }
  .pt-sm-24 {
    padding-top: 24px;
  }
  .pt-sm-32 {
    padding-top: 32px;
  }
  .pt-sm-48 {
    padding-top: 48px;
  }
  .pt-sm-64 {
    padding-top: 64px;
  }
  .pt-sm-72 {
    padding-top: 72px;
  }
  .pt-sm-96 {
    padding-top: 96px;
  }
  .pt-sm-128 {
    padding-top: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .pt-md-auto {
    padding-top: auto;
  }
  .pt-md-0 {
    padding-top: 0px;
  }
  .pt-md-2 {
    padding-top: 2px;
  }
  .pt-md-4 {
    padding-top: 4px;
  }
  .pt-md-8 {
    padding-top: 8px;
  }
  .pt-md-10 {
    padding-top: 10px;
  }
  .pt-md-16 {
    padding-top: 16px;
  }
  .pt-md-24 {
    padding-top: 24px;
  }
  .pt-md-32 {
    padding-top: 32px;
  }
  .pt-md-48 {
    padding-top: 48px;
  }
  .pt-md-64 {
    padding-top: 64px;
  }
  .pt-md-72 {
    padding-top: 72px;
  }
  .pt-md-96 {
    padding-top: 96px;
  }
  .pt-md-128 {
    padding-top: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .pt-lg-auto {
    padding-top: auto;
  }
  .pt-lg-0 {
    padding-top: 0px;
  }
  .pt-lg-2 {
    padding-top: 2px;
  }
  .pt-lg-4 {
    padding-top: 4px;
  }
  .pt-lg-8 {
    padding-top: 8px;
  }
  .pt-lg-10 {
    padding-top: 10px;
  }
  .pt-lg-16 {
    padding-top: 16px;
  }
  .pt-lg-24 {
    padding-top: 24px;
  }
  .pt-lg-32 {
    padding-top: 32px;
  }
  .pt-lg-48 {
    padding-top: 48px;
  }
  .pt-lg-64 {
    padding-top: 64px;
  }
  .pt-lg-72 {
    padding-top: 72px;
  }
  .pt-lg-96 {
    padding-top: 96px;
  }
  .pt-lg-128 {
    padding-top: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .pt-xl-auto {
    padding-top: auto;
  }
  .pt-xl-0 {
    padding-top: 0px;
  }
  .pt-xl-2 {
    padding-top: 2px;
  }
  .pt-xl-4 {
    padding-top: 4px;
  }
  .pt-xl-8 {
    padding-top: 8px;
  }
  .pt-xl-10 {
    padding-top: 10px;
  }
  .pt-xl-16 {
    padding-top: 16px;
  }
  .pt-xl-24 {
    padding-top: 24px;
  }
  .pt-xl-32 {
    padding-top: 32px;
  }
  .pt-xl-48 {
    padding-top: 48px;
  }
  .pt-xl-64 {
    padding-top: 64px;
  }
  .pt-xl-72 {
    padding-top: 72px;
  }
  .pt-xl-96 {
    padding-top: 96px;
  }
  .pt-xl-128 {
    padding-top: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .pt-xxl-auto {
    padding-top: auto;
  }
  .pt-xxl-0 {
    padding-top: 0px;
  }
  .pt-xxl-2 {
    padding-top: 2px;
  }
  .pt-xxl-4 {
    padding-top: 4px;
  }
  .pt-xxl-8 {
    padding-top: 8px;
  }
  .pt-xxl-10 {
    padding-top: 10px;
  }
  .pt-xxl-16 {
    padding-top: 16px;
  }
  .pt-xxl-24 {
    padding-top: 24px;
  }
  .pt-xxl-32 {
    padding-top: 32px;
  }
  .pt-xxl-48 {
    padding-top: 48px;
  }
  .pt-xxl-64 {
    padding-top: 64px;
  }
  .pt-xxl-72 {
    padding-top: 72px;
  }
  .pt-xxl-96 {
    padding-top: 96px;
  }
  .pt-xxl-128 {
    padding-top: 128px;
  }
}

.pb-auto {
  padding-bottom: auto;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-2 {
  padding-bottom: 2px;
}
.pb-4 {
  padding-bottom: 4px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pb-48 {
  padding-bottom: 48px;
}
.pb-64 {
  padding-bottom: 64px;
}
.pb-72 {
  padding-bottom: 72px;
}
.pb-96 {
  padding-bottom: 96px;
}
.pb-128 {
  padding-bottom: 128px;
}
@media only screen and (min-width: 480px) {
  .pb-xs-auto {
    padding-bottom: auto;
  }
  .pb-xs-0 {
    padding-bottom: 0px;
  }
  .pb-xs-2 {
    padding-bottom: 2px;
  }
  .pb-xs-4 {
    padding-bottom: 4px;
  }
  .pb-xs-8 {
    padding-bottom: 8px;
  }
  .pb-xs-10 {
    padding-bottom: 10px;
  }
  .pb-xs-16 {
    padding-bottom: 16px;
  }
  .pb-xs-24 {
    padding-bottom: 24px;
  }
  .pb-xs-32 {
    padding-bottom: 32px;
  }
  .pb-xs-48 {
    padding-bottom: 48px;
  }
  .pb-xs-64 {
    padding-bottom: 64px;
  }
  .pb-xs-72 {
    padding-bottom: 72px;
  }
  .pb-xs-96 {
    padding-bottom: 96px;
  }
  .pb-xs-128 {
    padding-bottom: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .pb-sm-auto {
    padding-bottom: auto;
  }
  .pb-sm-0 {
    padding-bottom: 0px;
  }
  .pb-sm-2 {
    padding-bottom: 2px;
  }
  .pb-sm-4 {
    padding-bottom: 4px;
  }
  .pb-sm-8 {
    padding-bottom: 8px;
  }
  .pb-sm-10 {
    padding-bottom: 10px;
  }
  .pb-sm-16 {
    padding-bottom: 16px;
  }
  .pb-sm-24 {
    padding-bottom: 24px;
  }
  .pb-sm-32 {
    padding-bottom: 32px;
  }
  .pb-sm-48 {
    padding-bottom: 48px;
  }
  .pb-sm-64 {
    padding-bottom: 64px;
  }
  .pb-sm-72 {
    padding-bottom: 72px;
  }
  .pb-sm-96 {
    padding-bottom: 96px;
  }
  .pb-sm-128 {
    padding-bottom: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .pb-md-auto {
    padding-bottom: auto;
  }
  .pb-md-0 {
    padding-bottom: 0px;
  }
  .pb-md-2 {
    padding-bottom: 2px;
  }
  .pb-md-4 {
    padding-bottom: 4px;
  }
  .pb-md-8 {
    padding-bottom: 8px;
  }
  .pb-md-10 {
    padding-bottom: 10px;
  }
  .pb-md-16 {
    padding-bottom: 16px;
  }
  .pb-md-24 {
    padding-bottom: 24px;
  }
  .pb-md-32 {
    padding-bottom: 32px;
  }
  .pb-md-48 {
    padding-bottom: 48px;
  }
  .pb-md-64 {
    padding-bottom: 64px;
  }
  .pb-md-72 {
    padding-bottom: 72px;
  }
  .pb-md-96 {
    padding-bottom: 96px;
  }
  .pb-md-128 {
    padding-bottom: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .pb-lg-auto {
    padding-bottom: auto;
  }
  .pb-lg-0 {
    padding-bottom: 0px;
  }
  .pb-lg-2 {
    padding-bottom: 2px;
  }
  .pb-lg-4 {
    padding-bottom: 4px;
  }
  .pb-lg-8 {
    padding-bottom: 8px;
  }
  .pb-lg-10 {
    padding-bottom: 10px;
  }
  .pb-lg-16 {
    padding-bottom: 16px;
  }
  .pb-lg-24 {
    padding-bottom: 24px;
  }
  .pb-lg-32 {
    padding-bottom: 32px;
  }
  .pb-lg-48 {
    padding-bottom: 48px;
  }
  .pb-lg-64 {
    padding-bottom: 64px;
  }
  .pb-lg-72 {
    padding-bottom: 72px;
  }
  .pb-lg-96 {
    padding-bottom: 96px;
  }
  .pb-lg-128 {
    padding-bottom: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .pb-xl-auto {
    padding-bottom: auto;
  }
  .pb-xl-0 {
    padding-bottom: 0px;
  }
  .pb-xl-2 {
    padding-bottom: 2px;
  }
  .pb-xl-4 {
    padding-bottom: 4px;
  }
  .pb-xl-8 {
    padding-bottom: 8px;
  }
  .pb-xl-10 {
    padding-bottom: 10px;
  }
  .pb-xl-16 {
    padding-bottom: 16px;
  }
  .pb-xl-24 {
    padding-bottom: 24px;
  }
  .pb-xl-32 {
    padding-bottom: 32px;
  }
  .pb-xl-48 {
    padding-bottom: 48px;
  }
  .pb-xl-64 {
    padding-bottom: 64px;
  }
  .pb-xl-72 {
    padding-bottom: 72px;
  }
  .pb-xl-96 {
    padding-bottom: 96px;
  }
  .pb-xl-128 {
    padding-bottom: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .pb-xxl-auto {
    padding-bottom: auto;
  }
  .pb-xxl-0 {
    padding-bottom: 0px;
  }
  .pb-xxl-2 {
    padding-bottom: 2px;
  }
  .pb-xxl-4 {
    padding-bottom: 4px;
  }
  .pb-xxl-8 {
    padding-bottom: 8px;
  }
  .pb-xxl-10 {
    padding-bottom: 10px;
  }
  .pb-xxl-16 {
    padding-bottom: 16px;
  }
  .pb-xxl-24 {
    padding-bottom: 24px;
  }
  .pb-xxl-32 {
    padding-bottom: 32px;
  }
  .pb-xxl-48 {
    padding-bottom: 48px;
  }
  .pb-xxl-64 {
    padding-bottom: 64px;
  }
  .pb-xxl-72 {
    padding-bottom: 72px;
  }
  .pb-xxl-96 {
    padding-bottom: 96px;
  }
  .pb-xxl-128 {
    padding-bottom: 128px;
  }
}

.pl-auto {
  padding-left: auto;
}
.pl-0 {
  padding-left: 0px;
}
.pl-2 {
  padding-left: 2px;
}
.pl-4 {
  padding-left: 4px;
}
.pl-8 {
  padding-left: 8px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-16 {
  padding-left: 16px;
}
.pl-24 {
  padding-left: 24px;
}
.pl-32 {
  padding-left: 32px;
}
.pl-48 {
  padding-left: 48px;
}
.pl-64 {
  padding-left: 64px;
}
.pl-72 {
  padding-left: 72px;
}
.pl-96 {
  padding-left: 96px;
}
.pl-128 {
  padding-left: 128px;
}
@media only screen and (min-width: 480px) {
  .pl-xs-auto {
    padding-left: auto;
  }
  .pl-xs-0 {
    padding-left: 0px;
  }
  .pl-xs-2 {
    padding-left: 2px;
  }
  .pl-xs-4 {
    padding-left: 4px;
  }
  .pl-xs-8 {
    padding-left: 8px;
  }
  .pl-xs-10 {
    padding-left: 10px;
  }
  .pl-xs-16 {
    padding-left: 16px;
  }
  .pl-xs-24 {
    padding-left: 24px;
  }
  .pl-xs-32 {
    padding-left: 32px;
  }
  .pl-xs-48 {
    padding-left: 48px;
  }
  .pl-xs-64 {
    padding-left: 64px;
  }
  .pl-xs-72 {
    padding-left: 72px;
  }
  .pl-xs-96 {
    padding-left: 96px;
  }
  .pl-xs-128 {
    padding-left: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .pl-sm-auto {
    padding-left: auto;
  }
  .pl-sm-0 {
    padding-left: 0px;
  }
  .pl-sm-2 {
    padding-left: 2px;
  }
  .pl-sm-4 {
    padding-left: 4px;
  }
  .pl-sm-8 {
    padding-left: 8px;
  }
  .pl-sm-10 {
    padding-left: 10px;
  }
  .pl-sm-16 {
    padding-left: 16px;
  }
  .pl-sm-24 {
    padding-left: 24px;
  }
  .pl-sm-32 {
    padding-left: 32px;
  }
  .pl-sm-48 {
    padding-left: 48px;
  }
  .pl-sm-64 {
    padding-left: 64px;
  }
  .pl-sm-72 {
    padding-left: 72px;
  }
  .pl-sm-96 {
    padding-left: 96px;
  }
  .pl-sm-128 {
    padding-left: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .pl-md-auto {
    padding-left: auto;
  }
  .pl-md-0 {
    padding-left: 0px;
  }
  .pl-md-2 {
    padding-left: 2px;
  }
  .pl-md-4 {
    padding-left: 4px;
  }
  .pl-md-8 {
    padding-left: 8px;
  }
  .pl-md-10 {
    padding-left: 10px;
  }
  .pl-md-16 {
    padding-left: 16px;
  }
  .pl-md-24 {
    padding-left: 24px;
  }
  .pl-md-32 {
    padding-left: 32px;
  }
  .pl-md-48 {
    padding-left: 48px;
  }
  .pl-md-64 {
    padding-left: 64px;
  }
  .pl-md-72 {
    padding-left: 72px;
  }
  .pl-md-96 {
    padding-left: 96px;
  }
  .pl-md-128 {
    padding-left: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .pl-lg-auto {
    padding-left: auto;
  }
  .pl-lg-0 {
    padding-left: 0px;
  }
  .pl-lg-2 {
    padding-left: 2px;
  }
  .pl-lg-4 {
    padding-left: 4px;
  }
  .pl-lg-8 {
    padding-left: 8px;
  }
  .pl-lg-10 {
    padding-left: 10px;
  }
  .pl-lg-16 {
    padding-left: 16px;
  }
  .pl-lg-24 {
    padding-left: 24px;
  }
  .pl-lg-32 {
    padding-left: 32px;
  }
  .pl-lg-48 {
    padding-left: 48px;
  }
  .pl-lg-64 {
    padding-left: 64px;
  }
  .pl-lg-72 {
    padding-left: 72px;
  }
  .pl-lg-96 {
    padding-left: 96px;
  }
  .pl-lg-128 {
    padding-left: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .pl-xl-auto {
    padding-left: auto;
  }
  .pl-xl-0 {
    padding-left: 0px;
  }
  .pl-xl-2 {
    padding-left: 2px;
  }
  .pl-xl-4 {
    padding-left: 4px;
  }
  .pl-xl-8 {
    padding-left: 8px;
  }
  .pl-xl-10 {
    padding-left: 10px;
  }
  .pl-xl-16 {
    padding-left: 16px;
  }
  .pl-xl-24 {
    padding-left: 24px;
  }
  .pl-xl-32 {
    padding-left: 32px;
  }
  .pl-xl-48 {
    padding-left: 48px;
  }
  .pl-xl-64 {
    padding-left: 64px;
  }
  .pl-xl-72 {
    padding-left: 72px;
  }
  .pl-xl-96 {
    padding-left: 96px;
  }
  .pl-xl-128 {
    padding-left: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .pl-xxl-auto {
    padding-left: auto;
  }
  .pl-xxl-0 {
    padding-left: 0px;
  }
  .pl-xxl-2 {
    padding-left: 2px;
  }
  .pl-xxl-4 {
    padding-left: 4px;
  }
  .pl-xxl-8 {
    padding-left: 8px;
  }
  .pl-xxl-10 {
    padding-left: 10px;
  }
  .pl-xxl-16 {
    padding-left: 16px;
  }
  .pl-xxl-24 {
    padding-left: 24px;
  }
  .pl-xxl-32 {
    padding-left: 32px;
  }
  .pl-xxl-48 {
    padding-left: 48px;
  }
  .pl-xxl-64 {
    padding-left: 64px;
  }
  .pl-xxl-72 {
    padding-left: 72px;
  }
  .pl-xxl-96 {
    padding-left: 96px;
  }
  .pl-xxl-128 {
    padding-left: 128px;
  }
}

.pr-auto {
  padding-right: auto;
}
.pr-0 {
  padding-right: 0px;
}
.pr-2 {
  padding-right: 2px;
}
.pr-4 {
  padding-right: 4px;
}
.pr-8 {
  padding-right: 8px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-16 {
  padding-right: 16px;
}
.pr-24 {
  padding-right: 24px;
}
.pr-32 {
  padding-right: 32px;
}
.pr-48 {
  padding-right: 48px;
}
.pr-64 {
  padding-right: 64px;
}
.pr-72 {
  padding-right: 72px;
}
.pr-96 {
  padding-right: 96px;
}
.pr-128 {
  padding-right: 128px;
}
@media only screen and (min-width: 480px) {
  .pr-xs-auto {
    padding-right: auto;
  }
  .pr-xs-0 {
    padding-right: 0px;
  }
  .pr-xs-2 {
    padding-right: 2px;
  }
  .pr-xs-4 {
    padding-right: 4px;
  }
  .pr-xs-8 {
    padding-right: 8px;
  }
  .pr-xs-10 {
    padding-right: 10px;
  }
  .pr-xs-16 {
    padding-right: 16px;
  }
  .pr-xs-24 {
    padding-right: 24px;
  }
  .pr-xs-32 {
    padding-right: 32px;
  }
  .pr-xs-48 {
    padding-right: 48px;
  }
  .pr-xs-64 {
    padding-right: 64px;
  }
  .pr-xs-72 {
    padding-right: 72px;
  }
  .pr-xs-96 {
    padding-right: 96px;
  }
  .pr-xs-128 {
    padding-right: 128px;
  }
}
@media only screen and (min-width: 640px) {
  .pr-sm-auto {
    padding-right: auto;
  }
  .pr-sm-0 {
    padding-right: 0px;
  }
  .pr-sm-2 {
    padding-right: 2px;
  }
  .pr-sm-4 {
    padding-right: 4px;
  }
  .pr-sm-8 {
    padding-right: 8px;
  }
  .pr-sm-10 {
    padding-right: 10px;
  }
  .pr-sm-16 {
    padding-right: 16px;
  }
  .pr-sm-24 {
    padding-right: 24px;
  }
  .pr-sm-32 {
    padding-right: 32px;
  }
  .pr-sm-48 {
    padding-right: 48px;
  }
  .pr-sm-64 {
    padding-right: 64px;
  }
  .pr-sm-72 {
    padding-right: 72px;
  }
  .pr-sm-96 {
    padding-right: 96px;
  }
  .pr-sm-128 {
    padding-right: 128px;
  }
}
@media only screen and (min-width: 800px) {
  .pr-md-auto {
    padding-right: auto;
  }
  .pr-md-0 {
    padding-right: 0px;
  }
  .pr-md-2 {
    padding-right: 2px;
  }
  .pr-md-4 {
    padding-right: 4px;
  }
  .pr-md-8 {
    padding-right: 8px;
  }
  .pr-md-10 {
    padding-right: 10px;
  }
  .pr-md-16 {
    padding-right: 16px;
  }
  .pr-md-24 {
    padding-right: 24px;
  }
  .pr-md-32 {
    padding-right: 32px;
  }
  .pr-md-48 {
    padding-right: 48px;
  }
  .pr-md-64 {
    padding-right: 64px;
  }
  .pr-md-72 {
    padding-right: 72px;
  }
  .pr-md-96 {
    padding-right: 96px;
  }
  .pr-md-128 {
    padding-right: 128px;
  }
}
@media only screen and (min-width: 960px) {
  .pr-lg-auto {
    padding-right: auto;
  }
  .pr-lg-0 {
    padding-right: 0px;
  }
  .pr-lg-2 {
    padding-right: 2px;
  }
  .pr-lg-4 {
    padding-right: 4px;
  }
  .pr-lg-8 {
    padding-right: 8px;
  }
  .pr-lg-10 {
    padding-right: 10px;
  }
  .pr-lg-16 {
    padding-right: 16px;
  }
  .pr-lg-24 {
    padding-right: 24px;
  }
  .pr-lg-32 {
    padding-right: 32px;
  }
  .pr-lg-48 {
    padding-right: 48px;
  }
  .pr-lg-64 {
    padding-right: 64px;
  }
  .pr-lg-72 {
    padding-right: 72px;
  }
  .pr-lg-96 {
    padding-right: 96px;
  }
  .pr-lg-128 {
    padding-right: 128px;
  }
}
@media only screen and (min-width: 1120px) {
  .pr-xl-auto {
    padding-right: auto;
  }
  .pr-xl-0 {
    padding-right: 0px;
  }
  .pr-xl-2 {
    padding-right: 2px;
  }
  .pr-xl-4 {
    padding-right: 4px;
  }
  .pr-xl-8 {
    padding-right: 8px;
  }
  .pr-xl-10 {
    padding-right: 10px;
  }
  .pr-xl-16 {
    padding-right: 16px;
  }
  .pr-xl-24 {
    padding-right: 24px;
  }
  .pr-xl-32 {
    padding-right: 32px;
  }
  .pr-xl-48 {
    padding-right: 48px;
  }
  .pr-xl-64 {
    padding-right: 64px;
  }
  .pr-xl-72 {
    padding-right: 72px;
  }
  .pr-xl-96 {
    padding-right: 96px;
  }
  .pr-xl-128 {
    padding-right: 128px;
  }
}
@media only screen and (min-width: 1280px) {
  .pr-xxl-auto {
    padding-right: auto;
  }
  .pr-xxl-0 {
    padding-right: 0px;
  }
  .pr-xxl-2 {
    padding-right: 2px;
  }
  .pr-xxl-4 {
    padding-right: 4px;
  }
  .pr-xxl-8 {
    padding-right: 8px;
  }
  .pr-xxl-10 {
    padding-right: 10px;
  }
  .pr-xxl-16 {
    padding-right: 16px;
  }
  .pr-xxl-24 {
    padding-right: 24px;
  }
  .pr-xxl-32 {
    padding-right: 32px;
  }
  .pr-xxl-48 {
    padding-right: 48px;
  }
  .pr-xxl-64 {
    padding-right: 64px;
  }
  .pr-xxl-72 {
    padding-right: 72px;
  }
  .pr-xxl-96 {
    padding-right: 96px;
  }
  .pr-xxl-128 {
    padding-right: 128px;
  }
}

.static {
  position: static;
}
@media only screen and (min-width: 480px) {
  .static-xs {
    position: static;
  }
}
@media only screen and (min-width: 640px) {
  .static-sm {
    position: static;
  }
}
@media only screen and (min-width: 800px) {
  .static-md {
    position: static;
  }
}
@media only screen and (min-width: 960px) {
  .static-lg {
    position: static;
  }
}
@media only screen and (min-width: 1120px) {
  .static-xl {
    position: static;
  }
}
@media only screen and (min-width: 1280px) {
  .static-xxl {
    position: static;
  }
}

.relative {
  position: relative;
}
@media only screen and (min-width: 480px) {
  .relative-xs {
    position: relative;
  }
}
@media only screen and (min-width: 640px) {
  .relative-sm {
    position: relative;
  }
}
@media only screen and (min-width: 800px) {
  .relative-md {
    position: relative;
  }
}
@media only screen and (min-width: 960px) {
  .relative-lg {
    position: relative;
  }
}
@media only screen and (min-width: 1120px) {
  .relative-xl {
    position: relative;
  }
}
@media only screen and (min-width: 1280px) {
  .relative-xxl {
    position: relative;
  }
}

.absolute {
  position: absolute;
}
@media only screen and (min-width: 480px) {
  .absolute-xs {
    position: absolute;
  }
}
@media only screen and (min-width: 640px) {
  .absolute-sm {
    position: absolute;
  }
}
@media only screen and (min-width: 800px) {
  .absolute-md {
    position: absolute;
  }
}
@media only screen and (min-width: 960px) {
  .absolute-lg {
    position: absolute;
  }
}
@media only screen and (min-width: 1120px) {
  .absolute-xl {
    position: absolute;
  }
}
@media only screen and (min-width: 1280px) {
  .absolute-xxl {
    position: absolute;
  }
}

.fixed {
  position: fixed;
}
@media only screen and (min-width: 480px) {
  .fixed-xs {
    position: fixed;
  }
}
@media only screen and (min-width: 640px) {
  .fixed-sm {
    position: fixed;
  }
}
@media only screen and (min-width: 800px) {
  .fixed-md {
    position: fixed;
  }
}
@media only screen and (min-width: 960px) {
  .fixed-lg {
    position: fixed;
  }
}
@media only screen and (min-width: 1120px) {
  .fixed-xl {
    position: fixed;
  }
}
@media only screen and (min-width: 1280px) {
  .fixed-xxl {
    position: fixed;
  }
}

.m-fixed-height {
  height: 100%;
}
@media only screen and (min-width: 480px) {
  .m-fixed-height-xs {
    height: 100%;
  }
}
@media only screen and (min-width: 640px) {
  .m-fixed-height-sm {
    height: 100%;
  }
}
@media only screen and (min-width: 800px) {
  .m-fixed-height-md {
    height: 100%;
  }
}
@media only screen and (min-width: 960px) {
  .m-fixed-height-lg {
    height: 100%;
  }
}
@media only screen and (min-width: 1120px) {
  .m-fixed-height-xl {
    height: 100%;
  }
}
@media only screen and (min-width: 1280px) {
  .m-fixed-height-xxl {
    height: 100%;
  }
}

.m-half-height {
  height: 50%;
}
@media only screen and (min-width: 480px) {
  .m-half-height-xs {
    height: 50%;
  }
}
@media only screen and (min-width: 640px) {
  .m-half-height-sm {
    height: 50%;
  }
}
@media only screen and (min-width: 800px) {
  .m-half-height-md {
    height: 50%;
  }
}
@media only screen and (min-width: 960px) {
  .m-half-height-lg {
    height: 50%;
  }
}
@media only screen and (min-width: 1120px) {
  .m-half-height-xl {
    height: 50%;
  }
}
@media only screen and (min-width: 1280px) {
  .m-half-height-xxl {
    height: 50%;
  }
}

.m-third-height {
  height: 33.33%;
}
@media only screen and (min-width: 480px) {
  .m-third-height-xs {
    height: 33.33%;
  }
}
@media only screen and (min-width: 640px) {
  .m-third-height-sm {
    height: 33.33%;
  }
}
@media only screen and (min-width: 800px) {
  .m-third-height-md {
    height: 33.33%;
  }
}
@media only screen and (min-width: 960px) {
  .m-third-height-lg {
    height: 33.33%;
  }
}
@media only screen and (min-width: 1120px) {
  .m-third-height-xl {
    height: 33.33%;
  }
}
@media only screen and (min-width: 1280px) {
  .m-third-height-xxl {
    height: 33.33%;
  }
}

.m-two-third-height {
  height: 66.66%;
}
@media only screen and (min-width: 480px) {
  .m-two-third-height-xs {
    height: 33.33%;
  }
}
@media only screen and (min-width: 640px) {
  .m-two-third-height-sm {
    height: 33.33%;
  }
}
@media only screen and (min-width: 800px) {
  .m-two-third-height-md {
    height: 33.33%;
  }
}
@media only screen and (min-width: 960px) {
  .m-two-third-height-lg {
    height: 33.33%;
  }
}
@media only screen and (min-width: 1120px) {
  .m-two-third-height-xl {
    height: 33.33%;
  }
}
@media only screen and (min-width: 1280px) {
  .m-two-third-height-xxl {
    height: 33.33%;
  }
}

.m-auto-height {
  height: auto;
}
@media only screen and (min-width: 480px) {
  .m-auto-height-xs {
    height: auto;
  }
}
@media only screen and (min-width: 640px) {
  .m-auto-height-sm {
    height: auto;
  }
}
@media only screen and (min-width: 800px) {
  .m-auto-height-md {
    height: auto;
  }
}
@media only screen and (min-width: 960px) {
  .m-auto-height-lg {
    height: auto;
  }
}
@media only screen and (min-width: 1120px) {
  .m-auto-height-xl {
    height: auto;
  }
}
@media only screen and (min-width: 1280px) {
  .m-auto-height-xxl {
    height: auto;
  }
}

.m-border-top {
  border-top: 1px solid rgba(23, 50, 50, 0.2);
}
.m-border-top-dashed {
  border-top: 1px dashed rgba(23, 50, 50, 0.2);
}

.m-border-bottom {
  border-bottom: 1px solid rgba(23, 50, 50, 0.2);
}
.m-border-bottom-dashed {
  border-bottom: 1px dashed rgba(23, 50, 50, 0.2);
}

.m-border-left {
  border-left: 1px solid rgba(23, 50, 50, 0.2);
}
.m-border-left-dashed {
  border-left: 1px dashed rgba(23, 50, 50, 0.2);
}

.m-border-right {
  border-right: 1px solid rgba(23, 50, 50, 0.2);
}
.m-border-right-dashed {
  border-right: 1px dashed rgba(23, 50, 50, 0.2);
}

.m-overflow-auto {
  overflow: auto;
}

.m-btn-baseline {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -4px;
}

.m-in-progress {
  position: fixed;
  left: 20px;
  bottom: 20px;
  max-width: calc(100% - 40px);
  color: #ff6600;
  pointer-events: none;
}

.m-no-wrap {
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.m-shadow {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.m-arrow-right {
  display: inline-block;
  vertical-align: middle;
  margin: 0 4px;
  width: 24px;
  height: 16px;
  background-image: url("./../../../assets/img/arrow-right.png");
  background-size: 24px 16px;
}

.m-arrow-left {
  display: inline-block;
  vertical-align: middle;
  margin: 0 4px;
  width: 24px;
  height: 16px;
  background-image: url("./../../../assets/img/arrow-left.png");
  background-size: 24px 16px;
}

.m-text-center {
  text-align: center;
}

.m-visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.m-list-inside {
  list-style-position: inside;
}

.m-text-dark {
  color: #173232;
}

.m-text {
  color: #173232;
}

.m-text-light {
  color: #999;
}

html {
  box-sizing: border-box;
  font-size: 16px;
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: "Fira Sans", sans-serif;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: #173232;
  background-color: #ffffff;
  overflow: hidden;
}

*:fullscreen {
  background-color: #ffffff;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
.m-details-summary,
.m-vms-designer-device .vms-type .p-dropdown-label,
h4,
h5,
h6 {
  font-weight: 500;
  color: #173232;
}

body,
h1,
h2,
h3,
.m-details-summary,
.m-vms-designer-device .vms-type .p-dropdown-label,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}
a:link, a:visited, a:hover, a:active {
  color: inherit;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: 500;
}

hr {
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #d4d4d4;
  display: block;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
  width: 8px;
  height: 8px;
  border-radius: 0px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
  background-color: #f5f6f7;
}

::-webkit-scrollbar-track-piece {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  border-radius: 0px;
  background-color: #c4ced3;
}

::-webkit-scrollbar-corner {
  /* 6 */
}

.m-page-content-wrapper {
  grid-area: pageBodyContent;
}

app-page {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0px, 1fr);
  grid-template-rows: auto minmax(0px, 1fr);
  grid-template-areas: "pageHeader" "pageBody";
}

app-page-header {
  grid-area: pageHeader;
}

app-page-body {
  grid-area: pageBody;
}
app-page-body > *:not(router-outlet) {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto minmax(0px, 1fr);
  grid-template-rows: auto minmax(0px, 1fr);
  grid-template-areas: "pageSidebar pageBodyHeader" "pageSidebar pageBodyContent";
}

app-page-sidebar {
  grid-area: pageSidebar;
  width: 360px;
  background-color: var(--ramuddenSand);
}

app-page-body-header {
  grid-area: pageBodyHeader;
  background-color: var(--neutral100);
  border-bottom: 1px solid var(--neutral300);
}

app-page-body-content {
  grid-area: pageBodyContent;
  background-color: var(--neutral100);
}

/*
 * Small hack for split area scrollbars
 */
.as-split-area {
  overflow: hidden !important;
}

.warning {
  color: darkorange;
}

.form-validation-error {
  color: red;
  font-size: small;
  margin: 0;
}

.m-vms-designer {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 320px;
  grid-template-areas: "navbar navbar" "canvas sidebar";
}
.m-vms-designer > * {
  min-width: 0;
  min-height: 0;
}
.m-vms-designer-navbar {
  grid-area: navbar;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(23, 50, 50, 0.2);
}
.m-vms-designer-canvas {
  grid-area: canvas;
  overflow: auto;
}
.m-vms-designer-canvas-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.m-vms-designer-actions {
  grid-area: buttons;
}
.m-vms-designer-sidebar {
  grid-area: sidebar;
  background-color: #ffffff;
  border-left: 1px solid rgba(23, 50, 50, 0.2);
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas: "device" "preview" "properties" "buttons";
}
.m-vms-designer-sidebar > * {
  min-width: 0;
  min-height: 0;
}
.m-vms-designer-preview {
  grid-area: preview;
  border-bottom: 1px solid rgba(23, 50, 50, 0.2);
  position: relative;
}
.m-vms-designer-preview-image {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;
  image-rendering: pixelated;
}
.m-vms-designer-preview-btn {
  position: absolute !important;
  bottom: 16px;
  right: 16px;
}
.m-vms-designer-device {
  grid-area: device;
  border-bottom: 1px solid rgba(23, 50, 50, 0.2);
}
.m-vms-designer-device .vms-type .p-dropdown {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0;
}
.m-vms-designer-device .vms-type .p-dropdown:not(.p-disabled):hover, .m-vms-designer-device .vms-type .p-dropdown:not(.p-disabled).p-focus {
  border-color: transparent;
}
.m-vms-designer-device .vms-type .p-dropdown-label {
  min-height: 40px;
  padding: 0.75rem;
}
.m-vms-designer-properties {
  grid-area: properties;
  overflow: auto;
}
.m-vms-designer-property:not(:first-child) {
  border-top: 1px solid rgba(23, 50, 50, 0.2);
}
.m-vms-designer-symbols {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 16px;
}
.m-vms-designer-symbol-link {
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  background-color: #173232;
  border: 4px dotted transparent;
  padding: 16px;
  border-radius: 0px;
}
.m-vms-designer-symbol-link > * {
  min-width: 0;
  min-height: 0;
}
.m-vms-designer-symbol-link.is-selected {
  border-color: #ffffff;
}
.m-vms-designer-symbol-checked {
  position: absolute;
  z-index: 1;
  top: -8px;
  right: -8px;
}
.m-vms-designer-symbol-checked:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  inset: 2px;
  border-radius: 50%;
  background-color: #ffffff;
}
.m-vms-designer-symbol-label {
  display: block;
  margin-top: 4px;
  font-size: 0.875rem;
  text-align: center;
  color: #ffffff;
}
.m-vms-designer-symbol-image {
  width: 100%;
}
.m-vms-designer-nav {
  display: flex;
  justify-content: center;
}
.m-vms-designer-nav .m-nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.m-vms-designer-nav .m-nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.m-vms-designer-nav .m-nav-link:hover, .m-vms-designer-nav .m-nav-link.is-active {
  border-bottom-color: #ffe137;
}
.m-vms-designer-icon-btn {
  background-color: transparent;
  border: 0;
  padding: 4px;
  border-radius: 0px;
}
.m-vms-designer-icon-btn:hover {
  background-color: #f4f4f4;
}
.m-vms-designer-icon-btn.is-selected {
  border-radius: 0px;
  border: 1px solid black;
  background-color: #f4f4f4;
}

.m-attachments {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
}

.m-attachment-image-wrapper {
  position: relative;
}

.m-attachment-image-actions {
  position: absolute;
  top: 0;
  right: 0;
}

.m-attachment-name {
  display: block;
  font-size: 0.6875rem;
  line-height: 1.1;
}

.p-dialog .file-drop-zone {
  width: 100%;
  height: 100%;
}

.p-dialog .file-drop-zone.drop-image {
  background-image: url("../../../assets/icons/drop.svg");
  background-repeat: no-repeat;
  background-size: 7.5%;
  background-position: 50% 50%;
}

.file-drop-zone .file-drop-zone-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px dashed black;
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
}

.file-drop-zone .file-drop-zone-overlay.file-drop-zone-overlay-image {
  background-image: url("../../../assets/icons/drop.svg");
  background-repeat: no-repeat;
  background-size: 75px 75px;
  background-position: 50% 50%;
}

.file-drop-zone.drag-over .file-drop-zone-overlay {
  display: block;
}

.p-dialog .file-drop-zone .file-drop-zone-overlay {
  background-size: 7.5%;
}

.p-autocomplete {
  width: 100%;
}

.p-autocomplete-item {
  font-size: 0.875rem;
}
.p-autocomplete-item .icon {
  width: 16px;
  height: 16px;
  fill: #999;
}

.m-badge {
  padding: 4px;
  background-color: #dce8ee;
  position: absolute;
  top: 0;
}

.m-badge-left {
  left: 0;
}

.m-badge-right {
  right: 0;
}

.m-badge-oker {
  background-color: #fec32d;
  color: #ffffff;
}

.m-badge-leaf {
  background-color: #26990f;
  color: #ffffff;
}

.p-button {
  padding: 4px 12px;
  background-color: #ffe137;
  border-color: #ffe137;
  border-radius: 0px;
  font-size: 0.8125rem;
  font-weight: 500;
  white-space: nowrap;
  color: #ffffff;
}
.p-button:focus {
  box-shadow: none;
}
.p-button:enabled:hover {
  background: #ffd904;
  border-color: #ffd904;
  color: #ffffff;
}
.p-button:enabled:active {
  background: #ffd904;
  border-color: #ffd904;
  color: #ffffff;
}
.p-button.p-button-text {
  font-weight: normal;
  color: #173232;
}
.p-button.p-button-text:enabled:hover {
  background: #f4f4f4;
  color: #ffe137;
}
.p-button.p-button-text:enabled:active {
  background: #e4e4e4;
  color: #ffe137;
}
.p-button.p-button-icon-only {
  padding: 4px 12px;
}

.p-button-label {
  font-weight: 500;
}

.m-btn, .btn--lightgrey, .btn--yellow {
  position: relative;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  padding: 4px 16px;
  border: 1px solid transparent;
  text-decoration: none;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  pointer-events: auto;
  cursor: pointer;
}
.m-btn span, .btn--lightgrey span, .btn--yellow span {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}
.m-btn:focus, .btn--lightgrey:focus, .btn--yellow:focus {
  outline: none;
}
.m-btn:disabled, .btn--lightgrey:disabled, .btn--yellow:disabled, .m-btn[disabled], [disabled].btn--lightgrey, [disabled].btn--yellow {
  opacity: 0.66;
  cursor: default;
}
.m-btn.m-btn-square, .m-btn-square.btn--lightgrey, .m-btn-square.btn--yellow {
  align-items: center;
  justify-content: center;
  min-width: 36px;
  min-height: 36px;
  background: transparent;
  border-radius: 0px;
  padding: 0;
}
.m-btn.m-btn-square.m-btn-toggle svg, .m-btn-square.m-btn-toggle.btn--lightgrey svg, .m-btn-square.m-btn-toggle.btn--yellow svg {
  fill: #173232;
}
.m-btn.m-btn-square.m-btn-toggle.active svg, .m-btn-square.m-btn-toggle.active.btn--lightgrey svg, .m-btn-square.m-btn-toggle.active.btn--yellow svg, .m-btn.m-btn-square.m-btn-toggle:not(.disabled):hover svg, .m-btn-square.m-btn-toggle.btn--lightgrey:not(.disabled):hover svg, .m-btn-square.m-btn-toggle.btn--yellow:not(.disabled):hover svg {
  fill: #ffe137;
}
.m-btn.m-btn-default, .m-btn.btn--lightgrey, .m-btn-default.btn--lightgrey, .btn--lightgrey, .m-btn-default.btn--yellow {
  background: #dce8ee;
  color: #173232;
  border-radius: 0px;
}
.m-btn.m-btn-default .icon, .m-btn.btn--lightgrey .icon, .m-btn-default.btn--lightgrey .icon, .btn--lightgrey .icon, .m-btn-default.btn--yellow .icon, .btn--yellow.btn--lightgrey .icon {
  width: 20px;
  height: 20px;
}
.m-btn.m-btn-default:hover, .m-btn.btn--lightgrey:hover, .m-btn-default.btn--lightgrey:hover, .btn--lightgrey:hover, .m-btn-default.btn--yellow:hover {
  background: #cedfe7;
}
.m-btn.m-btn-primary, .m-btn-primary.btn--lightgrey, .m-btn.btn--yellow, .btn--yellow {
  background: #ffe137;
  color: #173232;
  border-radius: 0px;
}
.m-btn.m-btn-primary .icon, .m-btn-primary.btn--lightgrey .icon, .m-btn.btn--yellow .icon, .btn--yellow .icon {
  width: 20px;
  height: 20px;
}
.m-btn.m-btn-primary:hover, .m-btn-primary.btn--lightgrey:hover, .m-btn.btn--yellow:hover, .btn--yellow:hover {
  background: #ffde23;
}
.m-btn.m-btn-secondary, .m-btn-secondary.btn--lightgrey, .m-btn-secondary.btn--yellow, .m-btn.m-btn-back, .m-btn-back.btn--lightgrey, .m-btn-back.btn--yellow {
  background-color: #ffffff;
  color: #173232;
  border-radius: 0px;
}
.m-btn.m-btn-secondary .icon, .m-btn-secondary.btn--lightgrey .icon, .m-btn-secondary.btn--yellow .icon, .m-btn.m-btn-back .icon, .m-btn-back.btn--lightgrey .icon, .m-btn-back.btn--yellow .icon {
  width: 20px;
  height: 20px;
}
.m-btn.m-btn-secondary:hover, .m-btn-secondary.btn--lightgrey:hover, .m-btn-secondary.btn--yellow:hover, .m-btn.m-btn-back:hover, .m-btn-back.btn--lightgrey:hover, .m-btn-back.btn--yellow:hover {
  text-decoration: underline;
}
.m-btn.m-btn-tertiary, .m-btn-tertiary.btn--lightgrey, .m-btn-tertiary.btn--yellow {
  background-color: #7295a3;
  color: #ffffff;
  border-radius: 0px;
}
.m-btn.m-btn-tertiary .icon, .m-btn-tertiary.btn--lightgrey .icon, .m-btn-tertiary.btn--yellow .icon {
  width: 20px;
  height: 20px;
}
.m-btn.m-btn-tertiary:hover, .m-btn-tertiary.btn--lightgrey:hover, .m-btn-tertiary.btn--yellow:hover {
  background: #668c9b;
}
.m-btn.m-btn-primary:disabled, .m-btn.btn--yellow:disabled, .btn--yellow:disabled, .m-btn.m-btn-primary[disabled], .m-btn[disabled].btn--yellow, [disabled].btn--yellow, .m-btn.m-btn-tertiary:disabled, .m-btn.m-btn-tertiary[disabled], .m-btn.m-btn-default:disabled, .m-btn.btn--lightgrey:disabled, .btn--lightgrey:disabled, .m-btn.m-btn-default[disabled], .m-btn[disabled].btn--lightgrey, [disabled].btn--lightgrey {
  background: #999;
  color: #ffffff;
}
.m-btn.m-btn-primary:disabled .icon, .btn--yellow:disabled .icon, .m-btn.m-btn-primary[disabled] .icon, [disabled].btn--yellow .icon, .m-btn.m-btn-tertiary:disabled .icon, .m-btn.m-btn-tertiary[disabled] .icon, .m-btn.m-btn-default:disabled .icon, .btn--lightgrey:disabled .icon, .m-btn.m-btn-default[disabled] .icon, [disabled].btn--lightgrey .icon {
  fill: #ffffff;
}
.m-btn.m-btn-primary:disabled:hover, .btn--yellow:disabled:hover, .m-btn.m-btn-primary[disabled]:hover, [disabled].btn--yellow:hover, .m-btn.m-btn-tertiary:disabled:hover, .m-btn.m-btn-tertiary[disabled]:hover, .m-btn.m-btn-default:disabled:hover, .btn--lightgrey:disabled:hover, .m-btn.m-btn-default[disabled]:hover, [disabled].btn--lightgrey:hover {
  background: #999;
  color: #ffffff;
}
.m-btn.m-btn-primary:disabled:hover .icon, .btn--yellow:disabled:hover .icon, .m-btn.m-btn-primary[disabled]:hover .icon, [disabled].btn--yellow:hover .icon, .m-btn.m-btn-tertiary:disabled:hover .icon, .m-btn.m-btn-tertiary[disabled]:hover .icon, .m-btn.m-btn-default:disabled:hover .icon, .btn--lightgrey:disabled:hover .icon, .m-btn.m-btn-default[disabled]:hover .icon, [disabled].btn--lightgrey:hover .icon {
  fill: #ffffff;
}
.m-btn.m-btn-secondary:disabled, .m-btn-secondary.btn--lightgrey:disabled, .m-btn-secondary.btn--yellow:disabled, .m-btn.m-btn-back:disabled, .m-btn-back.btn--lightgrey:disabled, .m-btn-back.btn--yellow:disabled, .m-btn.m-btn-secondary[disabled], .m-btn-secondary[disabled].btn--lightgrey, .m-btn-secondary[disabled].btn--yellow, .m-btn[disabled].m-btn-back, [disabled].m-btn-back.btn--lightgrey, [disabled].m-btn-back.btn--yellow {
  color: #999;
}
.m-btn.m-btn-secondary:disabled .icon, .m-btn-secondary.btn--lightgrey:disabled .icon, .m-btn-secondary.btn--yellow:disabled .icon, .m-btn.m-btn-back:disabled .icon, .m-btn-back.btn--lightgrey:disabled .icon, .m-btn-back.btn--yellow:disabled .icon, .m-btn.m-btn-secondary[disabled] .icon, .m-btn-secondary[disabled].btn--lightgrey .icon, .m-btn-secondary[disabled].btn--yellow .icon, .m-btn[disabled].m-btn-back .icon, [disabled].m-btn-back.btn--lightgrey .icon, [disabled].m-btn-back.btn--yellow .icon {
  fill: #999;
}
.m-btn.m-btn-secondary:disabled:hover, .m-btn-secondary.btn--lightgrey:disabled:hover, .m-btn-secondary.btn--yellow:disabled:hover, .m-btn.m-btn-back:disabled:hover, .m-btn-back.btn--lightgrey:disabled:hover, .m-btn-back.btn--yellow:disabled:hover, .m-btn.m-btn-secondary[disabled]:hover, .m-btn-secondary[disabled].btn--lightgrey:hover, .m-btn-secondary[disabled].btn--yellow:hover, .m-btn[disabled].m-btn-back:hover, [disabled].m-btn-back.btn--lightgrey:hover, [disabled].m-btn-back.btn--yellow:hover {
  text-decoration: none;
  color: #999;
}
.m-btn.m-btn-secondary:disabled:hover .icon, .m-btn-secondary.btn--lightgrey:disabled:hover .icon, .m-btn-secondary.btn--yellow:disabled:hover .icon, .m-btn.m-btn-back:disabled:hover .icon, .m-btn-back.btn--lightgrey:disabled:hover .icon, .m-btn-back.btn--yellow:disabled:hover .icon, .m-btn.m-btn-secondary[disabled]:hover .icon, .m-btn-secondary[disabled].btn--lightgrey:hover .icon, .m-btn-secondary[disabled].btn--yellow:hover .icon, .m-btn[disabled].m-btn-back:hover .icon, [disabled].m-btn-back.btn--lightgrey:hover .icon, [disabled].m-btn-back.btn--yellow:hover .icon {
  fill: #999;
}
.m-btn.m-btn-link, .m-btn-link.btn--lightgrey, .m-btn-link.btn--yellow {
  background: transparent;
  min-width: 0;
  font-weight: 400;
  text-decoration: underline;
  color: #173232;
}
.m-btn.m-btn-link:disabled, .m-btn-link.btn--lightgrey:disabled, .m-btn-link.btn--yellow:disabled, .m-btn.m-btn-link[disabled], .m-btn-link[disabled].btn--lightgrey, .m-btn-link[disabled].btn--yellow {
  opacity: 0.4;
}
.m-btn.m-btn-filter, .m-btn-filter.btn--lightgrey, .m-btn-filter.btn--yellow {
  background-color: #ffffff;
  border: 1px solid rgba(23, 50, 50, 0.2);
  border-radius: 0px;
  min-width: 107px;
  min-height: 30px;
  font-weight: 400;
  font-size: 0.8125rem;
}
.m-btn.m-btn-back, .m-btn-back.btn--lightgrey, .m-btn-back.btn--yellow {
  font-size: 0.8125rem;
  font-weight: 400;
  text-transform: none;
}
.m-btn.m-btn-back-map, .m-btn-back-map.btn--lightgrey, .m-btn-back-map.btn--yellow {
  /* Position overlay */
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
}
.m-btn.m-btn-back .icon, .m-btn-back.btn--lightgrey .icon, .m-btn-back.btn--yellow .icon {
  width: 10px;
  height: 10px;
}
.m-btn.m-btn-back:hover, .m-btn-back.btn--lightgrey:hover, .m-btn-back.btn--yellow:hover {
  color: #173232;
}

.m-btn-group {
  display: flex;
  flex-wrap: wrap;
}
.m-btn-group.m-btn-group-right {
  justify-content: flex-end;
}
.m-btn-group.m-btn-group-left {
  justify-content: flex-start;
}

.m-btn-100 {
  width: 100%;
}

.m-btn-mode-select {
  height: 22px;
  min-height: 22px;
  width: 100%;
  border-radius: 0px;
  background-color: #EEE;
  padding: 5px 10px;
  font-weight: normal;
  font-size: inherit;
  border-color: #999;
}

p-button.h-narrow .p-button {
  display: flex;
  align-items: center;
  height: 30px;
}

.p-calendar {
  position: relative;
  width: 100%;
}

.p-calendar-w-btn .p-inputtext, .p-calendar-w-btn .m-map-search-input > input, .p-calendar-w-btn .p-inputtextarea {
  border-radius: 0px !important;
}
.p-calendar-w-btn .p-inputtext:enabled:focus + .p-datepicker-trigger, .p-calendar-w-btn .m-map-search-input > input:enabled:focus + .p-datepicker-trigger, .p-calendar-w-btn .p-inputtextarea:enabled:focus + .p-datepicker-trigger {
  background-color: #e4e4e4;
  border-color: rgba(7, 15, 15, 0.2);
  color: #173232;
}
.p-calendar-w-btn .p-datepicker-trigger {
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  background-color: transparent;
  border: 0;
  color: #173232;
}
.p-calendar-w-btn .p-datepicker-trigger:enabled:hover {
  background-color: transparent;
  color: #173232;
}
.p-calendar-w-btn .p-datepicker-trigger:enabled:active {
  background-color: transparent;
  color: #173232;
}

.p-datepicker {
  background: #ffffff;
  color: #173232;
  border-color: rgba(23, 50, 50, 0.2);
  border-radius: 0px;
  padding: 0px;
  min-width: 0px !important;
}

.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border-color: rgba(23, 50, 50, 0.2);
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #f4f4f4;
  color: #173232;
}

.p-datepicker .p-datepicker-header {
  background: #ffe137;
  color: #ffffff;
  font-weight: 500;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 24px;
  height: 24px;
  color: #173232;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev .pi,
.p-datepicker .p-datepicker-header .p-datepicker-next .pi {
  font-size: 0.625rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #173232;
  border-color: transparent;
  background: transparent;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  box-shadow: none;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 1.5;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
  font-size: 0.875rem;
  font-weight: 500;
  color: #173232;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover {
  text-decoration: underline;
  color: #173232;
}

.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  box-shadow: none;
  border-color: #ffe137;
}

.p-datepicker .p-datepicker-calendar-container {
  height: 224px;
}

.p-datepicker .p-datepicker-calendar {
  font-size: 0.8125rem;
  margin: 0;
}

.p-datepicker .p-datepicker-calendar > thead > tr > th {
  padding: 0px;
}

.p-datepicker .p-datepicker-calendar > thead > tr > th > span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  font-weight: 500;
  text-align: center;
  color: #173232;
}

.p-datepicker .p-datepicker-calendar > tbody > tr > td {
  padding: 0px;
}

.p-datepicker table td > span {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  border-radius: 0px;
  text-align: center;
}

.p-datepicker table td > span.p-highlight {
  font-weight: 500;
  color: #173232;
  background: #ffe137;
}

.p-datepicker table td > span:focus {
  box-shadow: none;
}

.p-datepicker table td.p-datepicker-today > span {
  background: #e4e4e4;
  color: #173232;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  font-weight: 500;
  color: #173232;
  background: #ffe137;
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 0.429rem;
  border-top-color: rgba(23, 50, 50, 0.2);
}

.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}

.p-datepicker .p-timepicker {
  padding: 0.429rem 0;
  border-top-color: rgba(23, 50, 50, 0.2);
}

.p-datepicker .p-timepicker button {
  width: 24px;
  height: 24px;
  color: #173232;
}

.p-datepicker .p-timepicker button:enabled:hover {
  color: #ffe137;
}

.p-datepicker .p-timepicker button:focus {
  box-shadow: none;
}

.p-datepicker .p-timepicker span {
  font-size: 0.8125rem;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 0px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #ffffff;
  background: #ffe137;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right-color: rgba(23, 50, 50, 0.2);
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #f4f4f4;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  box-shadow: none;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: transparent;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  box-shadow: none;
}

.m-date-highlight {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #26990f;
  text-decoration: underline;
  color: #ffffff;
}
.m-date-highlight:hover {
  background-color: #20820d !important;
}
.p-disabled .m-date-highlight {
  background-color: transparent;
  color: #d4d4d4;
  text-decoration: none;
}
.p-disabled .m-date-highlight:hover {
  background-color: transparent !important;
}
.p-highlight .m-date-highlight {
  background-color: #ffe137;
  color: #ffffff;
}
.p-highlight .m-date-highlight:hover {
  background-color: #ffdd1e !important;
}

.m-date-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #fb5555;
  text-decoration: underline;
  color: #ffffff;
}
.m-date-error:hover {
  background-color: #fa3c3c !important;
}
.p-disabled .m-date-error {
  background-color: transparent;
  color: #d4d4d4;
  text-decoration: none;
}
.p-disabled .m-date-error:hover {
  background-color: transparent !important;
}
.p-highlight .m-date-error {
  background-color: #ffe137;
  color: #ffffff;
}
.p-highlight .m-date-error:hover {
  background-color: #ffdd1e !important;
}

.m-date-exception {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #fec32d;
  text-decoration: underline;
  color: #ffffff;
}
.m-date-exception:hover {
  background-color: #febc14 !important;
}
.p-disabled .m-date-exception {
  background-color: transparent;
  color: #d4d4d4;
  text-decoration: none;
}
.p-disabled .m-date-exception:hover {
  background-color: transparent !important;
}
.p-highlight .m-date-exception {
  background-color: #ffe137;
  color: #ffffff;
}
.p-highlight .m-date-exception:hover {
  background-color: #ffdd1e !important;
}

.m-date-predicted {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #453d55;
  text-decoration: underline;
  color: #ffffff;
}
.m-date-predicted:hover {
  background-color: #393246 !important;
}
.p-disabled .m-date-predicted {
  background-color: transparent;
  color: #d4d4d4;
  text-decoration: none;
}
.p-disabled .m-date-predicted:hover {
  background-color: transparent !important;
}
.p-highlight .m-date-predicted {
  background-color: #ffe137;
  color: #ffffff;
}
.p-highlight .m-date-predicted:hover {
  background-color: #ffdd1e !important;
}

.m-camera {
  display: block;
  width: 100%;
  max-width: 220px;
  margin-bottom: 8px;
}
.m-livetile .m-camera {
  max-width: 100%;
}

.m-camera-image {
  width: 100%;
  max-height: 100%;
}
.m-camera-image img {
  display: block;
  width: 100%;
  max-height: 100%;
  height: auto;
}
.m-livetile .m-camera-image:hover .m-camera-controls {
  opacity: 1;
}

.m-livetile .m-camera-timestamp {
  font-size: 0.5625rem;
}

.m-camera-controls {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  align-items: center;
  justify-items: center;
}
.m-livetile .m-camera-controls {
  grid-gap: 4px;
  position: absolute;
  margin-top: 0;
  bottom: 12px;
  left: 12px;
  right: 12px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.m-camera-control-pad {
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.m-camera-control-pad-btn {
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 0px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
}
.m-camera-control-pad-btn:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}
.m-livetile .m-camera-control-pad-btn {
  width: 24px;
  height: 24px;
  font-size: 0.75rem;
}

.m-camera-control-pad-btn-home {
  font-size: 2rem;
}
.m-livetile .m-camera-control-pad-btn-home {
  font-size: 1.5rem;
}

.m-camera-zoom {
  width: 100%;
  height: auto;
}

.m-camera-presets {
  width: 100%;
  height: auto;
}

.m-camera-presets-wrapper > * + * {
  margin-left: 8px;
}
.m-camera-presets-wrapper .m-btn-group > * + * {
  margin-left: 8px;
}
.m-camera-presets-wrapper .m-btn, .m-camera-presets-wrapper .btn--yellow, .m-camera-presets-wrapper .btn--lightgrey {
  min-width: 0;
}

/*
 * Dirty Fix
 */
.m-camera-gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 270px;
}
.m-camera-gallery img {
  display: flex;
  flex-grow: 1;
  width: 80%;
  max-width: 80%;
  max-height: 100%;
  height: auto;
}
.m-camera-gallery .m-camera-gallery-command {
  width: 36px;
  max-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.m-camera-gallery .m-camera-gallery-command-hover:hover {
  filter: brightness(0.5);
  background-color: rgba(242, 241, 237, 0.1);
  cursor: pointer;
}

.m-card, .m-snackbar, .m-map-overlay-datatable table {
  background-color: #ffffff;
  box-shadow: 0px 1px 4px rgba(23, 50, 50, 0.25);
}

.m-card-brand {
  background-color: #ffe137;
}

.p-checkbox .p-checkbox-box {
  border-color: rgba(23, 50, 50, 0.2);
  background: #ffffff;
  color: #173232;
  border-radius: 0;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #ffe137;
  background: #ffe137;
}

.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon {
  color: #173232;
}

.p-checkbox .p-checkbox-box.p-highlight checkicon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-checkbox .p-checkbox-box.p-highlight timesicon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: rgba(7, 15, 15, 0.2);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none;
  border-color: #ffe137;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #ffe137;
  background: #ffe137;
  color: #ffffff;
}

.p-highlight .p-checkbox .p-checkbox-box {
  border-color: rgba(23, 50, 50, 0.2);
}

.m-circle {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #ffffff;
  border-radius: 50%;
}
.m-circle-xs {
  width: 12px;
  height: 12px;
}
.m-circle-md {
  width: 22px;
  height: 22px;
}
.m-circle .icon {
  width: 18px;
  height: 18px;
}
.m-circle-primary {
  background-color: #ffe137;
}
.m-circle-primary .icon {
  fill: #173232;
}
.m-circle-grey {
  background-color: #c6c6c6;
}
.m-circle-grey .icon {
  fill: #173232;
}

.p-component {
  font-family: "Fira Sans", sans-serif;
  font-size: inherit;
}

.container {
  width: var(--width, 1280px);
  height: var(--height, auto);
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container--fluid {
  width: 100%;
  height: var(--height, auto);
  margin-left: auto;
  margin-right: auto;
}

.m-cookie {
  position: fixed;
  bottom: 16px;
  left: 16px;
  width: 640px;
  max-width: calc(100% - 32px);
  z-index: 9999;
}

.grid-container {
  display: grid;
  gap: 20px;
  width: 100%;
  padding: 20px;
}

.grid-cell {
  width: 100%;
}

.grid-cell-no-wrap {
  flex-wrap: nowrap;
}

.grid-container.grid-columns-0 {
  grid-template-columns: repeat(0, auto);
}

.grid-column-0 {
  grid-column: 0;
}

.grid-row-0 {
  grid-row: 0;
}

.grid-container.grid-columns-1 {
  grid-template-columns: repeat(1, auto);
}

.grid-column-1 {
  grid-column: 1;
}

.grid-row-1 {
  grid-row: 1;
}

.grid-container.grid-columns-2 {
  grid-template-columns: repeat(2, auto);
}

.grid-column-2 {
  grid-column: 2;
}

.grid-row-2 {
  grid-row: 2;
}

.grid-container.grid-columns-3 {
  grid-template-columns: repeat(3, auto);
}

.grid-column-3 {
  grid-column: 3;
}

.grid-row-3 {
  grid-row: 3;
}

.grid-container.grid-columns-4 {
  grid-template-columns: repeat(4, auto);
}

.grid-column-4 {
  grid-column: 4;
}

.grid-row-4 {
  grid-row: 4;
}

.grid-container.grid-columns-5 {
  grid-template-columns: repeat(5, auto);
}

.grid-column-5 {
  grid-column: 5;
}

.grid-row-5 {
  grid-row: 5;
}

.p-dialog {
  width: 100%;
  max-width: 960px;
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-color: rgba(23, 50, 50, 0.2);
}

.p-dialog .p-dialog-header {
  border-bottom: 0;
  background: #f4f4f4;
  color: #173232;
  padding: 16px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  color: #173232;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 20px;
  height: 20px;
  color: #173232;
  margin-right: 0;
  background: transparent;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #070f0f;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #173232;
  padding: 0;
}

.p-dialog .p-dialog-footer {
  border-top: 0;
  background: #ffffff;
  color: #173232;
  padding: 8px 16px;
  text-align: right;
  border-top: 1px solid rgba(23, 50, 50, 0.2);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-dialog .p-dialog-footer button {
  margin: 0 0 0 8px;
  width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: inherit;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 16px;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.m-dl-hor {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}
.m-dl-hor dt {
  color: var(--neutral500);
}
.m-dl-hor dt,
.m-dl-hor dd {
  margin: 8px 0;
}

.p-dropdown {
  width: 100%;
  background-color: #f4f4f4;
  border-color: rgba(23, 50, 50, 0.2);
  border-radius: 0px;
  color: #173232;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: rgba(23, 50, 50, 0.2);
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: rgba(7, 15, 15, 0.2);
}
.p-dropdown .p-dropdown-label {
  display: flex;
  align-items: center;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #999;
}
.p-dropdown .p-dropdown-trigger {
  color: #173232;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-dropdown .p-dropdown-clear-icon {
  position: relative;
  right: 0;
}
.p-dropdown .p-dropdown-clear-icon svg {
  color: #173232;
}

#projects-filter .p-dropdown-header {
  padding: 0.35rem 1.25rem;
}
#projects-filter .p-dropdown-filter-icon {
  top: 50%;
}

p-dropdown.panel-third-screen .p-dropdown-items-wrapper {
  max-height: 35vh !important;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #173232;
  border-color: rgba(23, 50, 50, 0.2);
  border-radius: 0px;
  overflow: hidden;
  width: 288px;
}

.p-dropdown-panel .p-dropdown-header {
  border-bottom-color: rgba(23, 50, 50, 0.2);
  color: #173232;
  background: #ffffff;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  color: #173232;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 0.8125rem;
  white-space: wrap;
  color: #173232;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  font-weight: 500;
  color: #173232;
  background: #e4e4e4;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #333333;
  background: #eaeaea;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.429rem 0.857rem;
  color: #333333;
  background: transparent;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.857rem;
  color: #333333;
  background: #f4f4f4;
  font-weight: 700;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #a80000;
}

p-dropdown.dd-small .p-dropdown {
  height: 25px;
  border: 0px solid white;
}

p-dropdown.right-float {
  float: right;
}

p-dropdown.dd-small .p-dropdown-label {
  height: auto;
  font-size: 11.25px;
  padding-left: 2px;
  padding-right: 2px;
}

p-dropdown.dd-small .p-dropdown-trigger {
  width: 2rem;
  height: auto;
  margin: 0px;
  padding: 0px;
}

p-dropdown p-overlay .p-overlay {
  width: 100%;
  min-width: 288px !important;
}

.p-input-filled .p-dropdown {
  background: #f4f4f4;
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #f4f4f4;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #f4f4f4;
}

.m-dropdown-toggle {
  display: flex;
  align-items: center;
  width: 100%;
}
.m-dropdown-toggle:hover {
  cursor: pointer;
}

.m-dropdown-menu {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  list-style: none;
}
.open .m-dropdown-menu {
  clip: auto;
  clip-path: none;
  height: auto;
  overflow: visible;
  position: static;
  white-space: normal;
  width: auto;
  display: flex;
  flex-direction: column;
}

.m-dropdown-menu-item {
  width: 100%;
}

p-dropdown.h-narrow .p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0px;
  margin: 5px;
}

.m-dropdown {
  position: relative;
}
.m-dropdown-summary {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  list-style: none;
}
.m-dropdown-summary::-webkit-details-marker {
  display: none;
}
.m-dropdown-summary:hover {
  cursor: pointer;
}
.m-dropdown-content {
  position: absolute;
  z-index: 99;
}

.m-filter-dropdown {
  position: relative;
}
.m-filter-dropdown-summary {
  display: grid;
  align-items: center;
  justify-items: center;
  width: 32px;
  height: 32px;
  margin: 0px;
  padding: 0px;
  list-style: none;
  background: var(--ramuddenYellow);
  color: var(--neutral900);
  border-radius: 0px;
}
.m-filter-dropdown-summary::-webkit-details-marker {
  display: none;
}
.m-filter-dropdown-summary:hover {
  cursor: pointer;
}
.m-filter-dropdown-content {
  position: absolute;
  right: 0;
  z-index: 999;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 0px;
}

.m-filter-group {
  display: flex;
  align-items: center;
}

.m-filter-title {
  font-weight: 500;
}

.m-galleria {
  z-index: 100000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "actions" "body" "navigator";
}

.m-galleria-actions {
  display: flex;
  justify-content: flex-end;
  grid-area: actions;
}

.m-galleria-body-wrapper {
  grid-area: body;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.m-galleria-body {
  width: 100%;
  height: 100%;
  display: grid;
  overflow: hidden;
  grid-template-rows: auto 1fr;
  grid-template-areas: "title" "content";
}

.m-galleria-title {
  text-align: center;
  color: #ffffff;
  grid-area: title;
}

.m-galleria-content {
  width: 100%;
  height: 100%;
  grid-area: content;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.m-galleria-content pdf-viewer {
  display: block;
  width: 100%;
  height: 100%;
}
.m-galleria-content img,
.m-galleria-content video {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.m-galleria-navigator {
  display: flex;
  justify-content: center;
  gap: 16px;
  grid-area: navigator;
  overflow: auto;
}
.m-galleria-navigator img {
  max-height: 80px;
  max-width: 100%;
  object-fit: contain;
  cursor: pointer;
}

.m-galleria-navigator-item {
  width: 160px;
}

.m-galleria-navigator-title {
  width: 100%;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  color: #ffffff;
  overflow: hidden;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  fill: #173232;
}
.icon svg {
  display: block;
  width: 100%;
  height: 100%;
}
.icon-brand {
  fill: #ffe137;
}
.icon-text-light {
  fill: #999;
}
.icon-text-dark, .m-btn.m-btn-default .icon, .m-btn.m-btn-secondary .icon, .m-btn.m-btn-back .icon, .m-btn-default.btn--yellow .icon, .m-btn-secondary.btn--yellow .icon, .m-btn-back.btn--yellow .icon, .m-btn-default.btn--lightgrey .icon, .m-btn-secondary.btn--lightgrey .icon, .m-btn-back.btn--lightgrey .icon, .m-btn.btn--lightgrey .icon, .btn--yellow.btn--lightgrey .icon, .btn--lightgrey .icon {
  fill: #173232;
}
.icon-white, .m-btn.m-btn-tertiary .icon, .m-btn-tertiary.btn--yellow .icon, .m-btn-tertiary.btn--lightgrey .icon {
  fill: #ffffff;
}
.icon.icon-12 {
  width: 12px;
  height: 12px;
}
.icon.icon-16 {
  width: 16px;
  height: 16px;
}
.icon.icon-20 {
  width: 20px;
  height: 20px;
}
.icon.icon-50 {
  width: 50px;
  height: 50px;
}
.icon.icon-gm {
  width: 20px;
  height: 20px;
  background-image: url("../../../assets/img/map-type-gm.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon.icon-gm-satellite {
  width: 20px;
  height: 20px;
  background-image: url("../../../assets/img/map-type-gm-satellite.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon.icon-osm {
  width: 20px;
  height: 20px;
  background-image: url("../../../assets/img/map-type-osm.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon.icon-osm-bike {
  width: 20px;
  height: 20px;
  background-image: url("../../../assets/img/map-type-osm-bike.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.icon.icon-osm-transport {
  width: 20px;
  height: 20px;
  background-image: url("../../../assets/img/map-type-osm-transport.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.svg-preview svg {
  width: 30px;
  height: 30px;
}

.p-inputtext, .m-map-search-input > input, .p-inputtextarea {
  align-items: center;
  width: 100%;
  height: 32px;
  min-width: 85px;
  padding: 1px 0px 1px 12px;
  background-color: #f4f4f4;
  border: 1px solid rgba(23, 50, 50, 0.2);
  border-radius: 0px;
  font-family: "Fira Sans", sans-serif;
  font-size: 0.8125rem;
  font-weight: 400;
  color: #173232;
}
.p-inputtext::placeholder, .m-map-search-input > input::placeholder, .p-inputtextarea::placeholder {
  color: #999;
}
.p-inputtext:enabled:hover, .m-map-search-input > input:enabled:hover, .p-inputtextarea:enabled:hover {
  border-color: rgba(23, 50, 50, 0.2);
}
.p-inputtext:enabled:focus, .m-map-search-input > input:enabled:focus, .p-inputtextarea:enabled:focus {
  outline: none;
  box-shadow: none;
  border-color: rgba(7, 15, 15, 0.2);
}
.p-inputtext[readonly], .m-map-search-input > input[readonly], [readonly].p-inputtextarea, .p-inputtext[disabled], .m-map-search-input > input[disabled], [disabled].p-inputtextarea {
  opacity: 0.5;
  cursor: default !important;
  user-select: none;
  pointer-events: none;
}
.p-inputtext[readonly]:enabled:focus, .m-map-search-input > input[readonly]:enabled:focus, [readonly].p-inputtextarea:enabled:focus, .p-inputtext[disabled]:enabled:focus, .m-map-search-input > input[disabled]:enabled:focus, [disabled].p-inputtextarea:enabled:focus {
  border-color: rgba(23, 50, 50, 0.2);
}
.p-disabled .p-inputtext[readonly], .p-disabled .m-map-search-input > input[readonly], .p-disabled [readonly].p-inputtextarea, .p-disabled .p-inputtext[disabled], .p-disabled .m-map-search-input > input[disabled], .p-disabled [disabled].p-inputtextarea {
  opacity: 1;
}
.p-colorpicker .p-inputtext[readonly], .p-colorpicker .m-map-search-input > input[readonly], .p-colorpicker [readonly].p-inputtextarea, .p-colorpicker .p-inputtext[disabled], .p-colorpicker .m-map-search-input > input[disabled], .p-colorpicker [disabled].p-inputtextarea {
  opacity: 1;
  user-select: auto;
  pointer-events: all;
}
.p-dropdown > .p-inputtext, .m-map-search-input.p-dropdown > input, .p-dropdown > .p-inputtextarea {
  background-color: transparent;
  border: 0px;
}

.p-inputtext.ng-invalid, .m-map-search-input > input.ng-invalid, .ng-invalid.p-inputtextarea {
  border-color: #e24c4c;
}

.p-inputtextarea {
  min-height: 72px;
  padding: 12px;
}

.p-inputtextarea.ng-invalid {
  border-color: #e24c4c;
}

.m-input-radio-block,
.m-input-checkbox-block {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 0.8125rem;
  line-height: 1.33;
}
.m-input-radio-block:hover,
.m-input-checkbox-block:hover {
  cursor: pointer;
}
.m-input-radio-block input,
.m-input-checkbox-block input {
  margin: 1px 6px 1px 0;
  width: 14px;
  height: 14px;
  accent-color: var(--ramuddenYellow);
}

.m-input-radio,
.m-input-checkbox {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 0.8125rem;
  line-height: 1.33;
}
.m-input-radio:hover,
.m-input-checkbox:hover {
  cursor: pointer;
}
.m-input-radio input,
.m-input-checkbox input {
  margin: 1px 6px 1px 0;
  width: 14px;
  height: 14px;
  accent-color: var(--ramuddenYellow);
}

input.m-input-checkbox {
  margin: 1px 6px 1px 0;
  width: 14px;
  height: 14px;
  accent-color: var(--ramuddenYellow);
}

.m-input-slider-value {
  display: grid;
  grid-template-columns: 1fr 80px;
  align-items: center;
  gap: 16px;
}
.m-input-slider-value > * {
  min-width: 0;
  min-height: 0;
}

.m-input-file {
  display: grid;
  grid-template-columns: 1fr min-content;
}
.m-input-file:hover {
  cursor: pointer;
}
.m-input-file input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.m-input-group {
  display: grid;
  grid-template-columns: 1fr min-content;
}
.m-input-group .p-inputtext, .m-input-group .m-map-search-input > input, .m-input-group .p-inputtextarea {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}
.m-input-group .p-inputtext:focus + .m-input-group-addon, .m-input-group .m-map-search-input > input:focus + .m-input-group-addon, .m-input-group .p-inputtextarea:focus + .m-input-group-addon {
  border-color: rgba(7, 15, 15, 0.2);
}

.m-input-group-addon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  font-size: 0.75rem;
  background-color: #e4e4e4;
  border: 1px solid rgba(23, 50, 50, 0.2);
  border-left: 0;
}

.hour-addon {
  width: 70px;
}

.m-input-group-w-icon {
  position: relative;
}

.m-input-group-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.m-input-group-icon .icon {
  fill: #999;
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.m-input-group-icon-right {
  right: 12px;
  left: 290px;
}

.m-form-group + .m-form-group {
  margin-top: 8px;
}
.m-form-group.has-arrow {
  position: relative;
}
.m-form-group.has-arrow:before {
  content: "";
  display: block;
  position: absolute;
  left: calc(33.33% - 8px);
  top: 0;
  width: 8px;
  height: 100%;
  background-image: url("./../../../assets/img/arrow-vertical.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.p-link {
  font-family: inherit;
  border-radius: 0px;
}
.p-link:focus {
  box-shadow: none;
}

.m-link,
.m-link:link,
.m-link:active,
.m-link:visited {
  cursor: pointer;
  color: var(--ramuddenBlue);
  text-decoration: underline;
}
.m-link:hover,
.m-link:link:hover,
.m-link:active:hover,
.m-link:visited:hover {
  text-decoration: none;
}

.m-livetile {
  display: grid;
  position: fixed;
  width: auto;
  max-width: 90vw;
  background: #ffffff;
  border-radius: 0px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  z-index: 10;
}
.m-livetile-sm {
  width: 352px;
}
.m-livetile .nav.nav-tabs {
  padding: 0px !important;
  width: 100%;
  position: fixed;
  left: 0px;
}
.m-livetile .ng-resizable {
  position: static;
}
.m-livetile .ng-resizable-diagonal {
  right: 1px;
  bottom: -30px;
}

.m-livetile-header {
  display: flex;
  align-items: center;
  padding: 2px 0px 2px 8px;
  background: #ffe137;
  background-color: transparent;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.m-livetile-header h4 {
  margin-right: 4px;
}
.m-livetile-header .m-btn-square {
  min-width: 16px;
  min-height: 16px;
  opacity: 0.66;
}
.m-livetile-header .m-btn-square svg {
  fill: #173232;
}
.m-livetile-header .m-btn-square:hover {
  opacity: 1;
}

.m-livetile-body {
  display: grid;
  height: auto;
  max-height: 480px;
  overflow: auto;
}
.m-livetile-body-resizable {
  position: relative;
  overflow: visible;
  max-height: none;
}
.m-livetile-body .p-datatable {
  border-collapse: collapse;
}
.m-livetile-body .p-datatable .p-datatable-thead > tr > td,
.m-livetile-body .p-datatable .p-datatable-tbody > tr > td,
.m-livetile-body .p-datatable .p-datatable-tfoot > tr > td {
  border-left-color: transparent;
  border-right-color: transparent;
}
.m-livetile-body .icon {
  width: 16px;
  height: 16px;
}

.m-livetile-footer {
  background-color: #e4e4e4;
}
.m-livetile-footer .icon {
  width: 16px;
  height: 16px;
}
.m-livetile-footer .m-btn-square {
  min-width: 24px;
  min-height: 24px;
  opacity: 0.66;
}
.m-livetile-footer .m-btn-square:hover {
  opacity: 1;
}

.m-livetile-chart {
  display: block;
  position: relative;
  min-width: 260px;
  min-height: 120px;
  width: 100%;
  height: 100%;
}
.m-livetile-chart > app-chart {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.m-livetile-chart > app-chart > * {
  display: block;
  width: 100%;
  height: 100%;
}

.m-livetile-chart-props {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.m-livetile-key-value {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 16px;
  margin-top: 6px;
}
.m-livetile-key-value > * {
  min-width: 0;
  min-height: 0;
}

.sk-fading-circle {
  display: inline-block;
  margin: 0;
  width: 20px;
  height: 20px;
  position: relative;
}
.btn .sk-fading-circle {
  margin-right: 4px;
  top: 3px;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: inline-block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #173232;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.btn-primary .sk-fading-circle .sk-circle:before {
  background-color: #ffffff;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
.m-map {
  position: relative;
  width: 100%;
  height: 100%;
}

.m-map-teaser {
  height: 120px;
}
@media only screen and (min-width: 1120px) {
  .m-map-teaser {
    height: 180px;
  }
}

.m-map-overlay {
  position: absolute;
}
.m-map-overlay .btn {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.m-map-overlay-form {
  width: 600px;
  max-width: 100%;
}

.m-map-overlay-group-form {
  width: 100%;
  max-width: 100%;
}

.m-map-overlay-datatable table {
  border-collapse: collapse;
  border: 0 none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.m-map-overlay-datatable tr {
  border-top: 1px solid #d4d4d4;
}
.m-map-overlay-datatable tr:first-child {
  border-top: 0 none;
}
.m-map-overlay-datatable th {
  background-color: #e4e4e4;
}
.m-map-overlay-datatable th:first-child {
  border-top-left-radius: 4px;
}
.m-map-overlay-datatable th:last-child {
  border-top-right-radius: 4px;
}
.m-map-overlay-datatable th,
.m-map-overlay-datatable td {
  padding: 8px 12px;
}

.m-map-overlay-right-top {
  top: 10px;
  right: 10px;
}

.m-map-overlay-right-top-moved {
  top: 10px;
  right: 60px;
}

.m-map-overlay-left-top {
  top: 10px;
  left: 10px;
}

.m-map-overlay-right-bottom {
  bottom: 24px;
  right: 10px;
}

.m-map-type-dropdown {
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 0px;
}
.m-map-type-dropdown .m-dropdown-toggle {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  padding: 4px 12px;
  font-family: "Fira Sans", sans-serif;
  font-size: 0.8125rem;
  font-weight: 400;
  color: #173232;
  border-radius: 0px;
}
.m-map-type-dropdown .m-dropdown-toggle:after {
  flex: 0 0 auto;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 6px 0 6px;
  border-color: #173232 transparent transparent transparent;
  margin-left: 8px;
}
.m-map-type-dropdown .m-dropdown-toggle:hover {
  background-color: #e4e4e4;
  cursor: pointer;
}
.m-map-type-dropdown .m-dropdown-toggle .icon {
  margin-right: 8px;
}
.m-map-type-dropdown-compact .m-map-type-dropdown .m-dropdown-toggle .icon {
  margin-right: 0;
}
.m-map-type-dropdown-compact.open .m-map-type-dropdown .m-dropdown-toggle .icon {
  margin-right: 8px;
}
.m-map-type-dropdown .m-dropdown-menu {
  border-top: 1px solid #d4d4d4;
}
.m-map-type-dropdown .m-dropdown-menu .icon {
  margin-right: 8px;
}
.m-map-type-dropdown .m-dropdown-menu button {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  width: 100%;
  min-height: 40px;
  background: transparent;
  border: 0;
  font-family: "Fira Sans", sans-serif;
  font-size: 0.8125rem;
  font-weight: 400;
  color: #173232;
}
.m-map-type-dropdown .m-dropdown-menu button:hover {
  cursor: pointer;
}
.m-map-type-dropdown .m-dropdown-menu li:hover {
  background-color: #e4e4e4;
  cursor: pointer;
}
.m-map-type-dropdown .m-dropdown-menu li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.m-map-type-dropdown-compact .m-dropdown-toggle .icon {
  margin-right: 0;
}
.m-map-type-dropdown-compact .m-dropdown-toggle .label {
  display: none;
}
.m-map-type-dropdown-compact.open .m-dropdown-toggle .icon {
  margin-right: 8px;
}
.m-map-type-dropdown-compact.open .m-dropdown-toggle .label {
  display: block;
}

.m-map-coordinates {
  display: flex;
  align-items: center;
  width: 140px;
  border-radius: 0px;
  background-color: #173232;
  color: #ffffff;
}

.m-map-coordinates-btn {
  padding: 8px;
  background-color: transparent;
  border: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.m-map-coordinates-btn:hover {
  cursor: pointer;
}
.m-map-coordinates-btn .icon {
  width: 20px;
  height: 20px;
  fill: #999;
}
.m-map-coordinates-btn.is-active .icon {
  fill: #ffffff;
}

.m-map-marker-label {
  position: absolute;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 16px;
  border: 1px solid #ffffff;
  max-width: 500px;
  min-height: 15px;
  color: #ffffff;
  font-weight: 500;
  padding: 5px 6px;
  margin-left: 2px;
  margin-bottom: 8px;
  white-space: nowrap;
}

.m-map-info-panel {
  position: fixed;
  bottom: 16px;
  left: 16px;
  width: 640px;
  max-width: calc(100% - 32px);
}

.m-map-info-panel-close {
  position: absolute;
  top: 16px;
  right: 16px;
}

/*
 * Overrides Google Maps
 */
.gm-bundled-control-on-bottom {
  top: auto !important;
  left: auto !important;
  bottom: 0 !important;
  right: 0 !important;
  margin: 0 16px 24px 0 !important;
}

.gm-svpc {
  position: static !important;
  border-radius: 0px !important;
}

/* The popup bubble styling. */
.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the bubble. */
  background-color: white;
  padding: 5px 9px 5px 5px;
  border-radius: 0px;
  font-family: "Fira Sans", sans-serif;
  overflow-y: auto;
  max-height: 60px;
  box-shadow: 0px 2px 10px 0.5px rgba(0, 0, 0, 0.5);
  border-radius: 0px;
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
  bottom: 16px;
  left: 0;
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}

.p-multiselect {
  position: relative;
  width: 100%;
  min-height: 32px;
  background-color: #f4f4f4;
  border-color: rgba(23, 50, 50, 0.2);
  border-radius: 0px;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: rgba(23, 50, 50, 0.2);
}
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: rgba(7, 15, 15, 0.2);
}
.p-multiselect .p-multiselect-label-container {
  display: flex;
}
.p-multiselect .p-multiselect-label {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  padding: 1px 12px;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #999;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background: #ffe137;
  color: #173232;
  border-radius: 0px;
}
.p-multiselect .p-multiselect-trigger {
  width: 30px;
  height: 30px;
  color: #173232;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

p-multiselect.b-ultra-light-grey .p-multiselect {
  background-color: #f4f4f4;
  height: 32px;
}

p-multiselect.b-white .p-multiselect {
  background-color: #ffffff;
}
p-multiselect.b-white .p-multiselect .p-multiselect-label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.8125rem;
}
p-multiselect.b-white .p-multiselect .p-multiselect-label.p-placeholder {
  color: #999;
}

p-multiselect.b-white .p-multiselect .p-overlay-content {
  width: auto;
}

.p-multiselect-panel {
  color: #173232;
  border-color: rgba(23, 50, 50, 0.2);
  border-radius: 0px;
}
.p-multiselect-panel .p-multiselect-header {
  padding: 4px 12px;
  border-bottom-color: rgba(23, 50, 50, 0.2);
  color: #173232;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  font-size: 0.8125rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #e4e4e4;
  font-weight: 500;
  color: #ffe137;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  color: #173232;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  color: #173232;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #ffe137;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  box-shadow: none;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: #173232;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #173232;
  background: #e4e4e4;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  color: #173232;
  background: #e4e4e4;
  font-weight: 500;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  color: #173232;
}

.p-input-filled .p-multiselect {
  background: #e4e4e4;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #e4e4e4;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #e4e4e4;
}

p-multiselect.panel-third-screen .p-multiselect-items-wrapper {
  max-height: 35vh !important;
}

.measuring-point-projects .p-multiselect-header {
  display: grid;
  grid-template-columns: 1fr 32px;
  grid-template-rows: 1fr;
}

.m-header {
  background: #ffe137;
  height: 80px;
  min-height: 0;
  padding: 0px 24px;
}

.m-header-navbar {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
}

.m-header-navbar-left {
  min-width: 0;
  display: flex;
  justify-content: flex-start;
}

.m-header-navbar-right {
  min-width: 0;
  display: flex;
  justify-content: flex-end;
}

.m-header-navbar-left,
.m-header-navbar-right {
  height: 100%;
  display: flex;
}

.m-org {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.m-org__logo {
  flex: 0 0 auto;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--neutral100);
  object-fit: contain;
}

.m-org__info {
  flex: 0 0 auto;
  color: var(--neutral900);
}

.m-org__name {
  font-size: 1.0625rem;
}

.m-org__product {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.6875rem;
  color: var(--neutral700);
}

.m-nav-primary {
  flex: 0 1 auto;
  overflow-x: auto;
  overflow-y: visible;
}
.m-nav-primary .m-nav-list {
  height: 100%;
  display: flex;
  padding: 0 16px;
  list-style: none;
}
.m-nav-primary .m-nav-list-item {
  height: 100%;
  display: flex;
  align-items: center;
}
.m-nav-primary .m-nav-link {
  height: 100%;
  min-width: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  font-weight: 400;
  color: #173232;
  white-space: nowrap;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  user-select: none;
}
.m-nav-primary .m-nav-link:hover {
  border-bottom-color: #173232;
  background-color: rgba(23, 50, 50, 0.05);
}
.m-nav-primary .m-nav-link.is-active {
  border-bottom-color: #173232;
  background-color: rgba(23, 50, 50, 0.05);
}
.m-nav-primary .icon {
  margin-bottom: 4px;
}

.m-nav-secondary {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(23, 50, 50, 0.2);
}

.m-language {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-left: 1px solid rgba(23, 50, 50, 0.2);
}

.m-account {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 16px;
  border-left: 1px solid rgba(23, 50, 50, 0.2);
}
.m-account .m-dropdown-toggle {
  font-weight: 400;
  color: #173232;
}
.m-account .m-dropdown-toggle .icon {
  margin-right: 8px;
}

.m-account-item {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
}
.m-account-item:hover {
  background-color: rgba(23, 50, 50, 0.05);
}
.m-account-item.has-background {
  background-color: rgba(23, 50, 50, 0.05);
}
.m-account-item.has-background:hover {
  background-color: rgba(23, 50, 50, 0.1);
}

.m-account-dropdown {
  position: relative;
}
.m-account-dropdown .m-dropdown-menu {
  background-color: #ffe137;
  border-radius: 0px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.m-account-dropdown .m-dropdown-menu li {
  border-top: 1px solid rgba(23, 50, 50, 0.2);
}
.m-account-dropdown .m-dropdown-menu li:hover {
  background-color: #ffde23;
}
.m-account-dropdown .m-dropdown-menu li:first-child {
  border-top: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.m-account-dropdown .m-dropdown-menu li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.m-account-dropdown .m-dropdown-menu a {
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 4px 12px;
  color: #173232;
}
.m-account-dropdown .m-dropdown-menu .p-dropdown {
  border-radius: 0;
  border: 0;
  background-color: transparent;
}
.m-account-dropdown .m-dropdown-menu .p-dropdown-label {
  color: #173232;
}
.m-account-dropdown .m-dropdown-menu .p-dropdown-label.p-placeholder {
  color: #173232;
}
.m-account-dropdown .m-dropdown-menu .p-dropdown-trigger {
  color: #173232;
}
.m-account-dropdown .m-dropdown-menu .p-dropdown-clear-icon {
  color: #173232;
}
.m-account-dropdown .m-dropdown-menu .icon {
  margin-right: 8px;
}
.m-account-dropdown.open .m-dropdown-menu {
  position: absolute;
  z-index: 90;
  top: calc(100% + 8px);
  right: 0;
}

.m-language-dropdown .p-dropdown {
  border-radius: 0;
  border: 0;
  background-color: transparent;
}
.m-language-dropdown .p-dropdown-label {
  color: #173232;
}
.m-language-dropdown .p-dropdown-label.p-placeholder {
  color: #173232;
}
.m-language-dropdown .p-dropdown-trigger {
  color: #173232;
}
.m-language-dropdown .p-dropdown-clear-icon {
  color: #173232;
}
.m-language-dropdown .icon {
  margin-right: 8px;
}

.m-nav-tabs {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: visible;
}
.m-nav-tabs .m-nav-list {
  height: 100%;
  display: flex;
  list-style: none;
}
.m-nav-tabs .m-nav-list-item {
  height: 100%;
}
.m-nav-tabs .m-nav-link {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 12px;
  white-space: nowrap;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  color: #173232;
}
.m-nav-tabs .m-nav-link:hover, .m-nav-tabs .m-nav-link.is-active {
  background-color: rgba(23, 50, 50, 0.05);
  color: #173232;
  border-bottom-color: #173232;
}
.m-nav-tabs .m-nav-link.is-disabled {
  opacity: 0.66;
  color: #999;
}
.m-nav-tabs .m-nav-link.is-disabled .icon {
  fill: #999;
}
.m-nav-tabs .m-nav-link.is-disabled.is-active {
  font-weight: 400;
  border-bottom-color: transparent;
}
.m-nav-tabs .m-nav-link.is-disabled.is-active:hover {
  border-bottom-color: transparent;
}
.m-nav-tabs .m-nav-link.is-disabled:hover {
  color: #999;
  cursor: default;
}
.m-nav-tabs .m-nav-link.is-disabled:hover .icon {
  fill: #999;
}
.m-nav-tabs .icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.m-nav-context-menu {
  position: absolute;
  display: inline-block;
}
.m-nav-context-menu .m-nav-list {
  display: flex;
  flex-direction: column;
  list-style: none;
}
.m-nav-context-menu .m-nav-list-item {
  width: 100%;
  border-top: 1px solid rgba(23, 50, 50, 0.2);
}
.m-nav-context-menu .m-nav-list-item:first-child {
  border-top: 0;
}

a.m-navigator {
  text-decoration: underline;
  color: #003c8c;
}
a.m-navigator.is-disabled {
  text-decoration: none;
  color: inherit;
}
a.m-navigator.is-inactive {
  color: #999;
}

.p-paginator {
  background: var(--neutral200);
  padding: 0 12px;
  color: var(--neutral900);
  font-size: 0.8125rem;
}
.p-paginator .icon {
  width: 16px;
  height: 16px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  color: var(--neutral900);
  min-width: 32px;
  height: 32px;
  margin: 0px;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: var(--neutral100);
  color: var(--neutral900);
}

.p-paginator .p-paginator-current {
  color: var(--neutral900);
  min-width: 32px;
  height: 32px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  font-size: 0.8125rem;
  color: var(--neutral900);
  min-width: 32px;
  height: 32px;
  margin: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--neutral300);
  border-color: var(--neutral300);
  font-weight: 500;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: var(--neutral100);
  border-color: var(--neutral100);
  color: var(--neutral900);
}

.p-datatable .p-paginator-bottom {
  border-width: 0;
}

.m-dates-container-header {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  width: 100%;
}

.m-date-container-header {
  font-weight: 500;
  text-align: center;
  background-color: #f4f4f4;
}
.m-date-container-header.is-selected {
  background-color: #e4e4e4;
}

.m-dates-container {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  width: 100%;
}
.m-dates-container-week {
  gap: 8px;
}
.m-dates-container-week + .m-dates-container-week {
  margin-top: 8px;
}
.m-dates-container-day {
  grid-auto-flow: initial;
  grid-gap: 8px;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: masonry;
}

.m-planning-item {
  break-inside: avoid;
  width: 100%;
  border: 1px solid rgba(23, 50, 50, 0.2);
  background-color: #ffffff;
  border-radius: 0px;
}
.m-planning-item + .m-planning-item {
  margin-top: 8px;
}

.m-planning-item-header {
  border-bottom: 1px solid rgba(23, 50, 50, 0.2);
  background-color: #f4f4f4;
  font-weight: 500;
  text-align: center;
}
.m-planning-item-header.is-previous-month {
  color: #999;
}

.m-planning-worker,
.m-planning-assignment {
  min-height: 28px;
}
.m-planning-worker:nth-child(even),
.m-planning-assignment:nth-child(even) {
  background-color: #f4f4f4;
}

.m-worker,
.m-assignment {
  max-width: 100%;
}

.m-worker-name,
.m-assignment-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.m-planning-dropzone {
  min-height: 28px;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.m-planning-assignment .drag-over {
  border: 1px dashed;
}

.m-popup {
  position: fixed;
  width: 40%;
  max-width: 90vw;
  background: var(--neutral100);
  border-radius: 0px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  z-index: 10;
}
.m-popup-sm {
  width: 352px;
}
.m-popup-auto {
  width: auto;
}
.m-popup .nav.nav-tabs {
  padding: 0px !important;
  width: 100%;
  position: fixed;
  left: 0px;
}
.m-popup .ng-resizable {
  position: static;
}
.m-popup .ng-resizable-diagonal {
  right: 1px;
  bottom: -23px;
}

.m-popup-header {
  display: flex;
  align-items: center;
  padding: 4px 8px 4px 16px;
  background: var(--ramuddenYellow);
  align-items: center;
}
.m-popup-header input[type=checkbox] {
  margin-left: -3px;
}
.m-popup-header h3, .m-popup-header .m-details-summary, .m-popup-header .m-vms-designer-device .vms-type .p-dropdown-label, .m-vms-designer-device .vms-type .m-popup-header .p-dropdown-label {
  color: var(--neutral900);
}
.m-popup-header .icon {
  width: 20px;
  height: 20px;
}
.m-popup-header .m-btn-square {
  min-width: 32px;
  min-height: 32px;
}
.m-popup-header .m-btn-square svg {
  fill: var(--neutral900);
}

.m-popup-body {
  height: auto;
  max-height: 480px;
  overflow: auto;
}
.m-popup-body label {
  color: var(--ramuddenYellow);
}
.m-popup-body-resizable {
  position: relative;
  overflow: visible;
  max-height: none;
}
.m-popup-body .p-datatable {
  border-collapse: collapse;
}
.m-popup-body .p-datatable .p-datatable-thead > tr > td,
.m-popup-body .p-datatable .p-datatable-tbody > tr > td,
.m-popup-body .p-datatable .p-datatable-tfoot > tr > td {
  border-left-color: transparent;
  border-right-color: transparent;
}

.m-popup-close {
  border: none;
  background-color: transparent;
  pointer-events: auto;
  padding: 0;
}
.m-popup-close:hover {
  cursor: pointer;
}

.m-preview {
  position: relative;
  width: 360px;
  max-width: 100%;
}
.m-preview img {
  border: 1px solid rgba(23, 50, 50, 0.2);
  border-radius: 0px;
}
.m-preview-sm {
  width: 120px;
}

.m-preview-delete {
  position: absolute;
  top: 0px;
  right: 0px;
}

.m-snackbar {
  position: fixed;
  z-index: 89;
  bottom: 20px;
  right: 20px;
  width: 480px;
  max-width: calc(100% - 40px);
}

.m-snackbar-header {
  position: relative;
  border-radius: 0px;
  background-color: var(--neutral300);
}
.is-expanded .m-snackbar-header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.m-snackbar-expand {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.p-progressbar {
  border: 0 none;
  height: 16px;
  background: var(--neutral500);
  border-radius: 0px;
}

.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: var(--ramuddenYellow);
}

.p-progressbar .p-progressbar-label {
  font-size: 0.75rem;
  color: var(--neutral900);
  line-height: 16px;
}

.m-progressbar-item {
  min-height: 36px;
}

.m-anchor {
  font-style: italic;
  font-size: smaller;
  margin-top: 2px;
}
.m-anchor a {
  text-decoration: underline;
  color: #7295a3;
}

.m-input-round-slider {
  position: relative;
}
.m-input-round-slider .p-inputtext, .m-input-round-slider .m-map-search-input > input, .m-input-round-slider .p-inputtextarea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  appearance: textfield;
  min-width: 60px;
  width: 60px;
  text-align: center;
}

.m-input-round-slider-label {
  display: block;
  text-align: center;
}

.m-input-round-slider-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.m-input-round-slider-wrapper .circumference {
  fill: #e4e4e4;
}
.m-input-round-slider-wrapper .dot circle:hover {
  cursor: pointer;
}
.m-input-round-slider-wrapper .dot circle {
  fill: #ffe137;
}
.m-input-round-slider-wrapper .dot circle.dragging {
  fill: #ffde23;
}

.p-inputswitch {
  width: 48px;
  height: 28px;
}

.p-inputswitch .p-inputswitch-slider {
  background: rgba(23, 50, 50, 0.2);
  border-radius: 14px;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 4px;
  margin-top: -10px;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: rgba(15, 33, 33, 0.2);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #26990f;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #20820d;
}

.m-switch {
  position: relative;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 36px;
  border: 1px solid transparent;
  text-decoration: none;
  transition: background 0.2s ease-out;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
}

.m-switch-label {
  font-weight: 500;
  font-size: 0.875rem;
  margin-right: 8px;
}

.m-tab-header {
  height: 56px;
  display: grid;
  grid-template-columns: auto 1fr;
}

.m-tab-header-title {
  display: flex;
  align-items: center;
}

.m-tab-header-title-text {
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #173232;
}

.m-tab-header-title-text-label {
  display: block;
  font-size: 0.5625rem;
  font-weight: 400;
  text-transform: uppercase;
  color: #999;
}

.m-tab-header-body {
  min-width: 0;
  max-width: 100%;
  display: flex;
  align-items: center;
}

.p-datatable-responsive {
  width: 100%;
  height: 100%;
}

.p-datatable {
  font-family: inherit;
  font-size: 0.75rem;
}
.p-datatable .btn.btn-icon {
  min-width: 24px;
  min-height: 24px;
}
.p-datatable .row-obsolete td {
  opacity: 0.5;
}

.p-datatable th {
  white-space: nowrap;
}

.p-datatable .p-datatable-table {
  border-spacing: 0px;
}

.p-datatable .p-datatable-header {
  background: var(--neutral200);
  color: inherit;
  border-color: var(--neutral300);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 4px 8px;
  font-weight: 500;
}

.p-datatable .p-datatable-footer {
  background: transparent;
  color: inherit;
  border-color: var(--neutral300);
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  padding: 4px 8px;
  font-weight: 400;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 4px 8px;
  min-height: 40px;
  border-color: var(--neutral300);
  font-weight: 500;
  vertical-align: middle;
  color: #173232;
  background: var(--ramuddenYellow);
  border-top: 0px;
  border-bottom: 0px;
  border-left: 1px solid var(--neutral300);
  border-right: 0 none;
}
.p-datatable .p-datatable-thead > tr > th:first-child {
  border-left: 0;
}

.p-datatable .p-datatable-tfoot > tr > td {
  padding: 4px 8px;
  border-color: var(--neutral300);
  font-weight: 500;
  color: inherit;
  background: var(--neutral200);
  border-left: 1px solid var(--neutral300);
  border-right: 0 none;
}
.p-datatable .p-datatable-tfoot > tr > td:first-child {
  border-left: 0;
}

.p-datatable .p-sortable-column p-sorticon {
  display: flex;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: inherit;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  color: inherit;
  background: var(--ramuddenYellow);
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: var(--ramuddenYellow);
  color: inherit;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: inherit;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #f6d100;
  color: inherit;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: inherit;
}

.p-datatable .p-sortable-column.p-highlight:hover {
  background: #f6d100;
  color: inherit;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: inherit;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none;
  outline: 0 none;
}

.p-datatable .p-datatable-tbody > tr {
  background: transparent;
  color: inherit;
  outline: 0;
}
.p-datatable .p-datatable-tbody > tr.is-greyed-out {
  color: #999;
}

.p-datatable .p-datatable-tbody > tr:focus {
  outline: 0;
  outline-offset: 0;
}

.p-datatable .p-datatable-tbody > tr > td {
  border-color: var(--neutral300);
  min-height: 28px;
  padding: 2px 8px;
  border-top: 0 none;
  border-bottom: 0 none;
  border-left: 1px solid var(--neutral300);
  border-right: 0 none;
}
.p-datatable .p-datatable-tbody > tr > td.m-table-empty-message {
  padding: 8px;
}
.p-datatable .p-datatable-tbody > tr > td:first-child {
  border-left: 0;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  color: inherit;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: inherit;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  box-shadow: none;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: rgba(0, 0, 0, 0.066);
  color: inherit;
}
.p-datatable .p-datatable-tbody > tr.p-highlight > td:first-child {
  position: relative;
}
.p-datatable .p-datatable-tbody > tr.p-highlight > td:first-child:before {
  content: "";
  display: block;
  width: 5px;
  height: 100%;
  background-color: var(--ramuddenYellow);
  position: absolute;
  top: 0;
  left: 0;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: none;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: none;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.066);
  color: inherit;
}

.p-datatable .p-column-resizer-helper {
  background: var(--neutral300);
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: var(--neutral300);
}

.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.033);
}

.p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: rgba(0, 0, 0, 0.066);
  color: inherit;
}

.p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: inherit;
}

.p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: inherit;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 4px 8px;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 4px 8px;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 1px 8px;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 4px 8px;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 4px 8px;
}

.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 8px;
}

.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 8px;
}

.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 8px;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 8px;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 8px;
}

.p-datatable .p-column-resizer {
  right: -0.25rem;
}

.p-datatable .p-datatable-loading-icon {
  color: var(--neutral500);
  width: 2rem;
  height: 2rem;
}

.p-datatable .p-datatable-loading-overlay {
  background-color: rgba(255, 255, 255, 0.5);
}

.m-table-card .p-datatable .p-datatable-tbody > tr > td {
  padding: 8px;
  border: 0;
}
.m-table-card .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background: transparent;
}
.m-table-card .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #f4f4f4;
}
.m-table-card .p-datatable .p-datatable-tbody > tr > td > .m-card, .m-table-card .p-datatable .m-map-overlay-datatable .p-datatable-tbody > tr > td > table, .m-map-overlay-datatable .m-table-card .p-datatable .p-datatable-tbody > tr > td > table, .m-table-card .p-datatable .p-datatable-tbody > tr > td > .m-snackbar {
  background-color: #f4f4f4;
}
.m-table-card .p-datatable .p-datatable-tbody > tr > td > .m-card:hover, .m-table-card .p-datatable .m-map-overlay-datatable .p-datatable-tbody > tr > td > table:hover, .m-map-overlay-datatable .m-table-card .p-datatable .p-datatable-tbody > tr > td > table:hover, .m-table-card .p-datatable .p-datatable-tbody > tr > td > .m-snackbar:hover {
  background-color: #e4e4e4;
}
.m-table-card .p-datatable .p-datatable-tbody > tr > td > .m-card p + p, .m-table-card .p-datatable .m-map-overlay-datatable .p-datatable-tbody > tr > td > table p + p, .m-map-overlay-datatable .m-table-card .p-datatable .p-datatable-tbody > tr > td > table p + p, .m-table-card .p-datatable .p-datatable-tbody > tr > td > .m-snackbar p + p {
  line-height: 1.5;
  margin-top: 8px;
}
.m-table-card .p-datatable .p-datatable-tbody > tr.p-highlight {
  background: transparent;
}
.m-table-card .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: transparent;
}

.p-datatable .p-inputtext, .p-datatable .m-map-search-input > input, .p-datatable .p-inputtextarea {
  height: auto;
  min-height: 24px;
}
.p-datatable .m-column-header {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.p-datatable .m-column-header-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 24px;
}
.p-datatable .m-column-title {
  font-size: 0.8125rem;
  font-weight: 500;
  color: #173232;
  margin-right: auto;
}
.p-datatable .m-column-sort {
  margin-left: 8px;
}
.p-datatable .m-column-filter {
  margin-left: 8px;
}
.p-datatable .m-column-filter-clear {
  display: flex;
  align-items: center;
  background-color: rgba(23, 50, 50, 0.1);
  border-radius: 0px;
  padding: 4px 8px;
  margin-top: 4px;
}
.p-datatable .m-column-filter-clear .icon {
  width: 12px;
  height: 12px;
  margin-left: 4px;
  flex: 0 0 auto;
}
.p-datatable .m-column-filter-clear-label {
  width: calc(100% - 16px);
  font-size: 0.625rem;
  font-weight: 400;
  white-space: nowrap;
  color: #173232;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-datatable .m-column-search,
.p-datatable .m-column-slider,
.p-datatable .m-column-dropdown,
.p-datatable .m-column-multiselect {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  overflow: hidden;
  opacity: 0;
}
.p-datatable .m-column-filter:focus-within .m-column-search,
.p-datatable .m-column-filter:focus-within .m-column-slider {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transform: none;
  width: auto;
  height: auto;
  overflow: visible;
  opacity: 1;
}
.p-datatable .m-column-calendar {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  overflow: hidden;
  opacity: 0;
}

.m-pulse-warning {
  background-color: #fec32d;
}

.m-pulse-error {
  background-color: red;
  color: #ffffff;
}

.m-table {
  width: 100%;
  border-collapse: collapse;
}
.m-table th,
.m-table td {
  padding: 4px 8px;
}
.m-table > thead > tr {
  background-color: var(--ramuddenYellow);
}
.m-table > tbody > tr:nth-child(2n) {
  background-color: var(--neutral200);
}

.m-tabs-picker > ul {
  display: flex;
  list-style: none;
  overflow: auto;
}
.m-tabs-picker > ul > li > a {
  display: inline-block;
  padding: 12px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
}
.m-tabs-picker > ul > li > a:hover, .m-tabs-picker > ul > li > a.is-active {
  background-color: rgba(23, 50, 50, 0.05);
  color: #173232;
  border-bottom-color: #173232;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: fit-content;
  max-width: min(80%, 900px);
  margin-left: auto;
  margin-right: auto;
}

.p-tree {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 0 none;
  background: transparent;
  font-size: 0.8125rem;
  color: #173232;
  padding: 0;
  border-radius: 0;
}

.p-tree .p-tree-container .p-treenode {
  padding: 0;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 0;
  padding: 0;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 36px;
  padding: 0 16px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label span {
  display: block;
  width: 100%;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0;
  width: 2rem;
  height: 2rem;
  color: var(--neutral900);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  box-shadow: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  box-shadow: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: rgba(0, 0, 0, 0.05);
  color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.05);
  color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: rgba(0, 0, 0, 0.05);
  color: var(--neutral900);
}

.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.429rem;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.5rem;
  color: var(--neutral900);
}

.p-tree .p-treenode-children {
  padding: 0 0 0 2rem;
}

.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}

.p-tree .p-tree-loading-overlay {
  background-color: transparent;
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background: rgba(0, 0, 0, 0.05);
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 0px;
  border: 0 none;
  background: transparent;
  color: #173232;
  padding: 0;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background: rgba(0, 0, 0, 0.05);
  color: var(--neutral900);
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: var(--neutral900);
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: var(--neutral900);
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: rgba(0, 0, 0, 0.05);
  color: var(--neutral900);
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  box-shadow: none;
}

.p-tree .p-treenode-leaf .p-tree-toggler {
  display: none;
}

.p-tree .p-tree-container:has(.p-treenode:not(.p-treenode-leaf)) .p-tree-toggler {
  display: inline-flex;
}

.p-treeselect {
  width: 100%;
  background-color: #f4f4f4;
  border-color: rgba(23, 50, 50, 0.2);
  border-radius: 0px;
  color: #173232;
}
.p-treeselect:not(.p-disabled):hover {
  border-color: rgba(23, 50, 50, 0.2);
}
.p-treeselect:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: rgba(7, 15, 15, 0.2);
}
.p-treeselect .p-treeselect-label {
  display: flex;
  align-items: center;
}
.p-treeselect .p-treeselect-label.p-placeholder {
  color: #999;
}
.p-treeselect .p-treeselect-trigger {
  color: #173232;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-treeselect .p-treeselect-clear-icon {
  position: relative;
  right: 0;
}
.p-treeselect .p-treeselect-clear-icon svg {
  color: #173232;
}

.m-details + .m-details {
  margin-top: 2px;
}
.m-details-summary {
  display: grid;
  grid-template-columns: 1fr 16px;
  align-items: center;
  min-height: 36px;
  padding: 8px 16px;
  background-color: #f4f4f4;
  list-style: none;
}
.m-details-summary::-webkit-details-marker {
  display: none;
}
.m-details-summary:hover {
  cursor: pointer;
}
[open] > .m-details-summary .icon {
  transform: rotate(180deg);
}
.m-details-content {
  padding: 16px;
}

.m-2lvl-select {
  position: relative;
}

.m-2lvl-select-dropdown {
  z-index: 999999 !important;
}

.m-2lvl-select-dropdown-wrapper {
  max-height: 400px;
  overflow: auto;
}

.m-2lvl-select-dropdown-wrapper-max-height {
  max-height: 400px;
  overflow: auto;
}

.twolevel-select-expanded-full-height .p-dropdown-items-wrapper {
  max-height: none;
  height: 100%;
}

.twolevel-select-small .p-inputwrapper {
  height: 25px;
}

.twolevel-select-small .p-dropdown {
  border: 0px solid white;
  height: 25px;
}

.twolevel-select-small .p-dropdown-label {
  font-size: 11.25px;
  padding: 2px;
  height: 25px;
}

.m-vms-animation-wrapper {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "left center right";
  align-items: center;
}
.m-vms-animation-wrapper:hover .m-vms-animation-button {
  opacity: 1;
}

.m-vms-animation-image-wrapper {
  grid-area: left/left/right/right;
  z-index: 0;
}

.m-vms-animation-image {
  width: 100%;
  max-height: 480px;
  aspect-ratio: 16/9;
  object-fit: contain;
  image-rendering: pixelated;
}

.m-vms-animation-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background-color: #ffffff;
  border: 0;
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
.is-compact .m-vms-animation-button {
  width: 24px;
  height: 24px;
}
.m-vms-animation-button:hover {
  cursor: pointer;
}

.m-vms-animation-button-icon {
  flex: 0 0 auto;
}

.m-vms-animation-prev {
  grid-area: left;
  z-index: 1;
  margin-left: 16px;
}
.is-compact .m-vms-animation-prev {
  margin-left: 0;
  margin-right: -12px;
}

.m-vms-animation-next {
  grid-area: right;
  z-index: 1;
  margin-right: 16px;
}
.is-compact .m-vms-animation-next {
  margin-right: 0;
  margin-left: -12px;
}

.m-mode-grid-header {
  background-color: #f4f4f4;
  border-radius: 0 0 0 0;
}
.m-mode-grid-header .p-inputtext, .m-mode-grid-header .m-map-search-input > input, .m-mode-grid-header .p-inputtextarea {
  background-color: white;
}

.m-mode-grid-row {
  border-top: 2px dashed #f4f4f4;
}

.m-mode-grid-frames {
  border-radius: 0 0 0 4px;
}

.m-mode-select {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.m-mode-select-tile {
  background-color: #f4f4f4;
  border: 1px solid rgba(23, 50, 50, 0.2);
  border-radius: 0px;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "title" "animation" "button";
  gap: 16px;
}

.m-mode-select-tile-content {
  display: grid;
  gap: 8px;
}

.m-map-filters {
  border-bottom: 1px solid #e4e4e4;
}

.m-map-filters-header {
  display: grid;
  gap: 8px;
  grid-template-rows: auto auto;
  align-items: center;
}

.m-map-filters-header-title {
  align-self: center;
}

.m-map-filters-container {
  display: grid;
  grid-template-columns: 1fr auto;
}

.m-map-filters-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.m-map-filters-chip {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #dce8ee;
  border-radius: 0px;
  padding: 2px 8px;
  margin: 0;
  border: 0;
  outline: 0;
  user-select: none;
  font-family: inherit;
  font-size: 0.8125rem;
}
.m-map-filters-chip svg {
  width: 16px;
  height: 16px;
}
.m-map-filters-chip.is-selected {
  background-color: #ffe137;
  color: #173232;
}
.m-map-filters-chip.is-selected:disabled, .m-map-filters-chip.is-selected[disabled] {
  opacity: 0.66;
  cursor: default;
}
.m-map-filters-chip.is-selected svg {
  fill: #173232;
}

button.m-map-filters-chip:hover {
  cursor: pointer;
}

.m-map-filters-chip-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
}
.m-map-filters-chip-remove:hover {
  cursor: pointer;
}
.m-map-filters-chip-remove svg {
  fill: #ffffff;
}

.m-button-link-icon {
  display: flex;
  align-self: center;
  margin-right: 5px;
}

.m-map-linked-device-filter {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.m-map-filters-chips-close-icon {
  color: #f4f4f4;
  margin-left: 5px;
  font-size: 0.8125rem;
}

.m-map-filters-chips-close-icon:hover {
  color: #f4f4f4;
  margin-left: 5px;
  background-color: #f6d100;
  font-size: 0.8125rem;
  cursor: pointer;
}

/*
 * Dialog
 */
.m-map-filters-dialog-body {
  display: grid;
  gap: 12px;
}

app-map-search-container {
  max-width: 360px;
}
app-map-search-container.is-collapsed {
  max-width: 84px;
}

.m-map-search-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: minmax(0, 1fr);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  pointer-events: none;
}

.m-map-search-open {
  position: relative;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0px;
  outline: 0;
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s ease;
  pointer-events: auto;
}
.m-map-search-open svg {
  width: 24px;
  height: 24px;
}
.m-map-search-open:hover {
  cursor: pointer;
}

.m-map-search-close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0px;
  outline: 0;
  background-color: transparent;
}
.m-map-search-close:hover {
  background-color: #eaeaea;
}
.m-map-search-close svg {
  width: 24px;
  height: 24px;
}
.m-map-search-close:hover {
  cursor: pointer;
}

.m-map-search {
  pointer-events: auto;
  display: grid;
  grid-template-rows: auto auto 1fr;
  background-color: #ffffff;
  height: calc(100vh - 80px);
  width: 360px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
}

.m-map-search-header {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr auto;
  background-color: #f4f4f4;
  border-bottom: 1px solid #e4e4e4;
}

.m-map-search-input {
  display: grid;
  grid-template-columns: 1fr auto auto;
  background-color: #ffffff;
  border: 1px solid rgba(23, 50, 50, 0.2);
  border-radius: 0px;
  overflow: hidden;
}
.m-map-search-input > input {
  z-index: 0;
  grid-column: 1/span 3;
  grid-row: 1;
  padding-right: 72px;
  background-color: transparent;
  height: 36px;
  border-radius: 0;
  border: 0;
}
.m-map-search-input > button {
  z-index: 1;
  grid-column: 2;
  grid-row: 1;
}
.m-map-search-input > button:hover {
  cursor: pointer;
}
.m-map-search-input > span {
  z-index: 1;
  grid-column: 3;
  grid-row: 1;
  pointer-events: none;
}

.m-map-search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: #173232;
}

.m-map-search-result-selected-item {
  background-color: #d4d4d4;
}

.m-location-popup {
  width: 360px;
  max-height: calc(100% - 20px);
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  pointer-events: auto;
}

.m-location-popup-header {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  background-color: #f4f4f4;
  border-bottom: 1px solid #e4e4e4;
}

.m-location-popup-close-container {
  align-self: start;
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
}

.m-location-popup-close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0px;
  outline: 0;
  background-color: transparent;
}
.m-location-popup-close svg {
  width: 24px;
  height: 24px;
}
.m-location-popup-close:hover {
  background-color: #eaeaea;
  cursor: pointer;
}

.m-map-search-result {
  border-bottom: 1px solid #e4e4e4;
}
.m-map-search-result:hover {
  cursor: pointer;
}
.m-map-search-result:hover {
  background-color: #f4f4f4;
}
.m-map-search-result.is-selected {
  background-color: #e4e4e4;
}

.m-map-search-result-header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
}

.m-map-search-result-body {
  display: grid;
}

.m-map-search-result-body-text {
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
}

.m-map-search-result-action-icons {
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
}

.m-depth-1 {
  padding-left: 0.75rem;
}
.m-depth-2 {
  padding-left: 1.5rem;
}
.m-depth-3 {
  padding-left: 2.25rem;
}
.m-depth-4 {
  padding-left: 3rem;
}
.m-depth-5 {
  padding-left: 3.75rem;
}
.m-depth-6 {
  padding-left: 4.5rem;
}
.m-depth-7 {
  padding-left: 5.25rem;
}
.m-depth-8 {
  padding-left: 6rem;
}
.m-depth-9 {
  padding-left: 6.75rem;
}

.m-alert {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
}
.m-alert.is-unread {
  background-color: #f4f4f4;
}
.m-alert-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #173232;
  font-family: "Fira Sans", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  color: #ffffff;
}
.m-alert-date {
  color: #999;
}
.m-alert-showall {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border-top: 1px solid rgba(23, 50, 50, 0.2);
}

.vis-label {
  border: 0;
}

.vis-label.vis-nesting-group {
  background-color: var(--neutral900) !important;
  color: var(--neutral100) !important;
}

.vis-label.vis-nested-group {
  background-color: var(--neutral200) !important;
}

.vis-nested-group .vis-inner {
  display: flex !important;
  align-items: center;
  gap: 8px;
}
.vis-nested-group .vis-inner:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  margin-left: auto;
}
.vis-nested-group.has-active-alert-error .vis-inner:after {
  background-color: rgb(241, 65, 65);
}
.vis-nested-group.has-active-alert-warning .vis-inner:after {
  background-color: rgb(255, 122, 34);
}
.vis-nested-group.has-active-alert-info .vis-inner:after {
  background-color: rgb(255, 225, 55);
}