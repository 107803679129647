.p-link {
    font-family: inherit;
    border-radius: 0px;

    &:focus {
        box-shadow: none;
    }
}

.m-link,
.m-link:link,
.m-link:active,
.m-link:visited {
    cursor: pointer;
    color: var(--ramuddenBlue);
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}