.row {
    display: grid;
    grid-template-columns: repeat($gridColumns, minmax(0px, 1fr));
    grid-auto-rows: auto;
    gap: $gridGutter;
}

.col {

    @for $i from 1 through $gridColumns {
        &-#{$i} {
            grid-column: auto / span #{$i};
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            @for $i from 1 through $gridColumns {
                &-#{$i}--#{$breakpoint} {
                    grid-column: auto / span #{$i};
                }
            }
        }
    }
}