.m-dates-container-header {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    width: 100%;
}

.m-date-container-header {
    font-weight: 500;
    text-align: center;
    background-color: $colorUltraLightGrey;

    &.is-selected {
        background-color: $colorLightGrey;
    }
}

.m-dates-container {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    width: 100%;

    &-week {
        gap: 8px;

        &+& {
            margin-top: 8px;
        }
    }

    &-day {
        grid-auto-flow: initial;
        grid-gap: 8px;
        grid-template-columns: repeat(7, minmax(0, 1fr));
        grid-template-rows: masonry;
    }
}

.m-planning-item {
    break-inside: avoid;
    width: 100%;
    border: 1px solid $colorBorder;
    background-color: $colorWhite;
    border-radius: 0px;

    &+& {
        margin-top: 8px;
    }
}

.m-planning-item-header {
    border-bottom: 1px solid $colorBorder;
    background-color: $colorUltraLightGrey;
    font-weight: 500;
    text-align: center;

    &.is-previous-month {
        color: $colorTextLight;
    }
}

.m-planning-worker,
.m-planning-assignment {
    min-height: 28px;

    &:nth-child(even) {
        background-color: $colorUltraLightGrey;
    }
}

.m-worker,
.m-assignment {
    max-width: 100%;
}

.m-worker-name,
.m-assignment-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.m-planning-dropzone {
    min-height: 28px;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.m-planning-assignment .drag-over {
    border: 1px dashed;
}
