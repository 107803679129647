.m-alert {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px;

    &.is-unread {
        background-color: $colorUltraLightGrey;
    }

    &-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -5px;
        right: -5px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: $colorBlack;
        font-family: "Fira Sans", sans-serif;
        font-size: .8rem;
        font-weight: 400;
        color: $colorWhite;
    }

    &-date {
        color: $colorTextLight;
    }

    &-showall {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $colorUltraLightGrey;
        border-top: 1px solid $colorBorder;
    }
}
