.p-calendar {
    position: relative;
    width: 100%;
}

.p-calendar-w-btn {
    .p-inputtext {
        border-radius: 0px !important;

        &:enabled:focus {
            & + .p-datepicker-trigger {
                background-color: $colorLightGrey;
                border-color: darken($colorInputBorder, 10%);
                color: $colorText;
            }
        }
    }

    .p-datepicker-trigger {
        position: absolute;
        top: 1px;
        bottom: 1px;
        right: 1px;
        background-color: transparent;
        border: 0;
        color: $colorText;

        &:enabled:hover {
            background-color: transparent;
            color: $colorText;
        }

        &:enabled:active {
            background-color: transparent;
            color: $colorText;
        }
    }
}

.p-datepicker {
    background: $colorWhite;
    color: $colorText;
    border-color: $colorInputBorder;
    border-radius: 0px;
    padding: 0px;
    min-width: 0px !important;
}

.p-datepicker:not(.p-datepicker-inline) {
    background: $colorWhite;
    border-color: $colorInputBorder;
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: $colorUltraLightGrey;
    color: $colorBlack;
}

.p-datepicker .p-datepicker-header {
    background: $colorBrand;
    color: $colorWhite;
    font-weight: 500;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 24px;
    height: 24px;
    color: $colorBlack;

    .pi {
        font-size: rem(10px);
    }
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: $colorBlack;
    border-color: transparent;
    background: transparent;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
    box-shadow: none;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 1.5;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year {
    font-size: rem(14px);
    font-weight: 500;
    color: $colorBlack;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover {
    text-decoration: underline;
    color: $colorBlack;
}

.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
    box-shadow: none;
    border-color: $colorBrand;
}

.p-datepicker .p-datepicker-calendar-container {
    height: 224px;
}

.p-datepicker .p-datepicker-calendar {
    font-size: rem(13px);
    margin: 0;
}

.p-datepicker .p-datepicker-calendar > thead > tr > th {
    padding: 0px;
}

.p-datepicker .p-datepicker-calendar > thead > tr > th > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 8px;
    font-weight: 500;
    text-align: center;
    color: $colorBlack;
}

.p-datepicker .p-datepicker-calendar > tbody > tr > td {
    padding: 0px;
}

.p-datepicker table td > span {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 8px;
    border-radius: 0px;
    text-align: center;
}

.p-datepicker table td > span.p-highlight {
    font-weight: 500;
    color: $colorBlack;
    background: $colorBrand;
}

.p-datepicker table td > span:focus {
    box-shadow: none;
}

.p-datepicker table td.p-datepicker-today > span {
    background: $colorLightGrey;
    color: $colorText;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
    font-weight: 500;
    color: $colorBlack;
    background: $colorBrand;
}

.p-datepicker .p-datepicker-buttonbar {
    padding: 0.429rem;
    border-top-color: $colorInputBorder;
}

.p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto;
}

.p-datepicker .p-timepicker {
    padding: 0.429rem 0;
    border-top-color: $colorInputBorder;
}

.p-datepicker .p-timepicker button {
    width: 24px;
    height: 24px;
    color: $colorText;
}

.p-datepicker .p-timepicker button:enabled:hover {
    color: $colorBrand;
}

.p-datepicker .p-timepicker button:focus {
    box-shadow: none;
}

.p-datepicker .p-timepicker span {
    font-size: rem(13px);
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: 0.5rem;
    transition:
        background-color 0.2s,
        box-shadow 0.2s;
    border-radius: 0px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: $colorWhite;
    background: $colorBrand;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-right-color: $colorInputBorder;
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: $colorUltraLightGrey;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    box-shadow: none;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
    background: transparent;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
    box-shadow: none;
}

.m-date-highlight {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: $colorLeaf;
    text-decoration: underline;
    color: $colorWhite;

    &:hover {
        background-color: darken($colorLeaf, 5%) !important;
    }

    .p-disabled & {
        background-color: transparent;
        color: $colorGrey;
        text-decoration: none;

        &:hover {
            background-color: transparent !important;
        }
    }

    .p-highlight & {
        background-color: $colorBrand;
        color: $colorWhite;

        &:hover {
            background-color: darken($colorBrand, 5%) !important;
        }
    }
}

.m-date-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #fb5555;
    text-decoration: underline;
    color: $colorWhite;

    &:hover {
        background-color: darken(#fb5555, 5%) !important;
    }

    .p-disabled & {
        background-color: transparent;
        color: $colorGrey;
        text-decoration: none;

        &:hover {
            background-color: transparent !important;
        }
    }

    .p-highlight & {
        background-color: $colorBrand;
        color: $colorWhite;

        &:hover {
            background-color: darken($colorBrand, 5%) !important;
        }
    }
}

.m-date-exception {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #fec32d;
    text-decoration: underline;
    color: $colorWhite;

    &:hover {
        background-color: darken(#fec32d, 5%) !important;
    }

    .p-disabled & {
        background-color: transparent;
        color: $colorGrey;
        text-decoration: none;

        &:hover {
            background-color: transparent !important;
        }
    }

    .p-highlight & {
        background-color: $colorBrand;
        color: $colorWhite;

        &:hover {
            background-color: darken($colorBrand, 5%) !important;
        }
    }
}

.m-date-predicted {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #453d55;
    text-decoration: underline;
    color: $colorWhite;

    &:hover {
        background-color: darken(#453d55, 5%) !important;
    }

    .p-disabled & {
        background-color: transparent;
        color: $colorGrey;
        text-decoration: none;

        &:hover {
            background-color: transparent !important;
        }
    }

    .p-highlight & {
        background-color: $colorBrand;
        color: $colorWhite;

        &:hover {
            background-color: darken($colorBrand, 5%) !important;
        }
    }
}
