.flex {
    display: flex;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                display: flex;
            }
        }
    }
}

.flex-column {
    flex-direction: column;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                flex-direction: column;
            }
        }
    }
}

.flex-row {
    flex-direction: row;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                flex-direction: row;
            }
        }
    }
}

.flex-wrap {
    flex-wrap: wrap;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                flex-wrap: wrap;
            }
        }
    }
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                flex-wrap: wrap-reverse;
            }
        }
    }
}

.flex-nowrap {
    flex-wrap: nowrap;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                flex-wrap: nowrap;
            }
        }
    }
}

.align-stretch {
    align-items: stretch;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                align-items: stretch;
            }
        }
    }
}

.align-start {
    align-items: flex-start;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                align-items: flex-start;
            }
        }
    }
}

.align-end {
    align-items: flex-end;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                align-items: flex-end;
            }
        }
    }
}

.align-items-center,
.align-center {
    align-items: center;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                align-items: center;
            }
        }
    }
}

.justify-stretch {
    justify-content: stretch;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                justify-content: stretch;
            }
        }
    }
}

.justify-start {
    justify-content: flex-start;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                justify-content: flex-start;
            }
        }
    }
}

.justify-content-end,
.justify-end {
    justify-content: flex-end;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                justify-content: flex-end;
            }
        }
    }
}

.justify-center {
    justify-content: center;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                justify-content: center;
            }
        }
    }
}

.justify-between {
    justify-content: space-between;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                justify-content: space-between;
            }
        }
    }
}

.gap {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            gap: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        gap: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}
