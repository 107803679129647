html {
    box-sizing: border-box;
    font-size: 16px;
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    font-family: "Fira Sans", sans-serif;
    font-size: rem(13px);
    line-height: 1.5;
    color: $colorText;
    background-color: $colorWhite;
    overflow: hidden;
}

*:fullscreen {
    background-color: $colorWhite;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    color: $colorHeadings;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
}

ol,
ul {
    // list-style: none;
}

a {
    text-decoration: none;
    color: inherit;

    &:link,
    &:visited,
    &:hover,
    &:active {
        color: inherit;
    }
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

strong {
    font-weight: 500;
}

hr {
    width: 100%;
    height: 1px;
    border: 0;
    background-color: $colorGrey;
    display: block;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-track {
    width: 8px;
    height: 8px;
    border-radius: 0px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    background-color: #f5f6f7;
}

::-webkit-scrollbar-track-piece {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    width: 8px;
    height: 8px;
    border-radius: 0px;
    background-color: #c4ced3;
}

::-webkit-scrollbar-corner {
    /* 6 */
}

::-webkit-resizer {}