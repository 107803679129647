.p-tree {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 0 none;
    background: transparent;
    font-size: rem(13px);
    color: $colorText;
    padding: 0;
    border-radius: 0;
}

.p-tree .p-tree-container .p-treenode {
    padding: 0;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 0;
    padding: 0;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 36px;
    padding: 0 16px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label span {
    display: block;
    width: 100%;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: 0;
    width: 2rem;
    height: 2rem;
    color: var(--neutral900);
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    box-shadow: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    margin-right: .5rem;
    color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: .5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    box-shadow: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: rgba(black, .05);
    color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: rgba(black, .05);
    color: var(--neutral900);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
    background: rgba(black, .05);
    color: var(--neutral900);
}

.p-tree .p-tree-filter-container {
    // width: calc(100% - 1rem);
    // margin: 0.5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.429rem;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
    right: .5rem;
    color: var(--neutral900);
}

.p-tree .p-treenode-children {
    padding: 0 0 0 2rem;
}

.p-tree .p-tree-loading-icon {
    font-size: 2rem;
}

.p-tree .p-tree-loading-overlay {
    background-color: transparent;
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    background: rgba(black, .05);
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
    border-radius: 0px;
    border: 0 none;
    background: transparent;
    color: $colorText;
    padding: 0;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
    background: rgba(black, .05);
    color: var(--neutral900);
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: var(--neutral900);
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: .5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
    color: var(--neutral900);
    margin-right: .5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
    margin-right: .5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: rgba(black, .05);
    color: var(--neutral900);
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
    box-shadow: none;
}

.p-tree .p-treenode-leaf .p-tree-toggler {
    display: none;
}

.p-tree .p-tree-container:has(.p-treenode:not(.p-treenode-leaf)) .p-tree-toggler {
    display: inline-flex;
}