$maxDepth: 9;
$gap: rem(12px);

.m-depth {
    @for $i from 1 through $maxDepth {
        &-#{$i} {
            padding-left: ($gap * $i);

            // &:after {
            //     content: '';
            //     display: block;
            //     position: absolute;
            //     top: 0;
            //     left: (1.25rem + ($gap * ($i - 1)));
            //     width: ($gap - rem(8px));
            //     height: 50%;
            //     border-left: 1px solid grey;
            //     border-bottom: 1px solid grey;
            // }
        }
    }
}