.p-inputswitch {
    width: 48px;
    height: 28px;
}

.p-inputswitch .p-inputswitch-slider {
    background: $colorInputBorder;
    border-radius: 14px;
}

.p-inputswitch .p-inputswitch-slider:before {
    background: $colorWhite;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 4px;
    margin-top: -10px;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.25rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: darken($colorInputBorder, 5%);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: $colorLeaf;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: $colorWhite;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: darken($colorLeaf, 5%);
}

.m-switch {
    position: relative;
    flex: 0 0 auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 36px;
    border: 1px solid transparent;
    text-decoration: none;
    transition: background 0.2s ease-out;
    font-family: inherit;
    font-size: rem(14px);
    font-weight: 500;
    white-space: nowrap;
    text-align: center;
}

.m-switch-label {
    font-weight: 500;
    font-size: 0.875rem;
    margin-right: 8px;
}