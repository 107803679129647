.m-camera {
    display: block;
    width: 100%;
    max-width: 220px;
    margin-bottom: 8px;

    .m-livetile & {
        max-width: 100%;
    }
}

.m-camera-image {
    width: 100%;
    max-height: 100%;

    img {
        display: block;
        width: 100%;
        max-height: 100%;
        height: auto;
    }

    .m-livetile & {
        &:hover {
            .m-camera-controls {
                opacity: 1;
            }
        }
    }
}

.m-camera-timestamp {
    .m-livetile & {
        font-size: rem(9px);
    }
}

.m-camera-controls {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    align-items: center;
    justify-items: center;

    .m-livetile & {
        grid-gap: 4px;
        position: absolute;
        margin-top: 0;
        bottom: 12px;
        left: 12px;
        right: 12px;
        opacity: 0;
        transition: opacity 0.25s ease;
    }
}

.m-camera-control-pad {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.m-camera-control-pad-btn {
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid $colorGrey;
    border-radius: 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;

    &:hover {
        background-color: #f3f3f3;
        cursor: pointer;
    }

    .m-livetile & {
        width: 24px;
        height: 24px;
        font-size: 0.75rem;
    }
}

.m-camera-control-pad-btn-home {
    font-size: 2rem;

    .m-livetile & {
        font-size: 1.5rem;
    }
}

.m-camera-zoom {
    width: 100%;
    height: auto;
}

.m-camera-presets {
    width: 100%;
    height: auto;
}

.m-camera-presets-wrapper {
    >*+* {
        margin-left: 8px;
    }

    .m-btn-group {
        >*+* {
            margin-left: 8px;
        }
    }

    .m-btn {
        min-width: 0;
    }
}

/*
 * Dirty Fix
 */

.m-camera {
    // .p-dropdown {
    //     .p-dropdown-label {
    //         display: flex;
    //         align-items: center;
    //         width: unset;

    //         margin-bottom: 0;

    //         .pin-measuringpoint-map & {
    //             min-height: 24px;
    //         }
    //     }

    //     .p-dropdown-item {
    //         .row {
    //             display: flex;
    //             align-items: center;
    //             justify-content: flex-start;
    //         }
    //     }

    //     .p-dropdown-panel {
    //         .p-dropdown-items {
    //             li {
    //                 .icon {
    //                     margin-right: 0;
    //                     width: 20px;
    //                     height: 20px;
    //                 }
    //             }
    //         }
    //     }
    // }

    // .m-btn {
    //     .pin-measuringpoint-map & {
    //         min-height: 24px;
    //     }
    // }

    // label {
    //     .m-btn.m-btn-icon {
    //         top: auto;
    //     }
    // }
}

.m-camera-gallery {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 270px;
    // min-height: 140px;

    img {
        display: flex;
        flex-grow: 1;
        width: 80%;
        max-width: 80%;
        max-height: 100%;
        height: auto;
    }

    .m-camera-gallery-command {
        width: 36px;
        max-height: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .m-camera-gallery-command-hover:hover {
        filter: brightness(0.5);
        background-color: rgba(242, 241, 237, 0.1);
        cursor: pointer;
    }
}