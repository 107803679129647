.p-dropdown {
    width: 100%;
    background-color: $colorUltraLightGrey;
    border-color: $colorInputBorder;
    border-radius: 0px;
    color: $colorText;

    &:not(.p-disabled):hover {
        border-color: $colorInputBorder;
    }

    &:not(.p-disabled).p-focus {
        box-shadow: none;
        border-color: darken($colorInputBorder, 10%);
    }

    .p-dropdown-label {
        display: flex;
        align-items: center;

        &.p-placeholder {
            color: $colorTextLight;
        }
    }

    .p-dropdown-trigger {
        color: $colorText;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .p-dropdown-clear-icon {
        position: relative;
        right: 0;

        svg {
            color: $colorTextDark;
        }
    }
}

#projects-filter {
    .p-dropdown-header {
        padding: 0.35rem 1.25rem;
    }

    .p-dropdown-filter-icon {
        top: 50%;
    }
}

p-dropdown.panel-third-screen {
    .p-dropdown-items-wrapper {
        max-height: 35vh !important;
    }
}

.p-dropdown-panel {
    background: $colorWhite;
    color: $colorText;
    border-color: $colorInputBorder;
    border-radius: 0px;
    overflow: hidden;
    width: 288px;
}

.p-dropdown-panel .p-dropdown-header {
    border-bottom-color: $colorInputBorder;
    color: $colorText;
    background: $colorWhite;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    color: $colorText;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: rem(13px);
    white-space: wrap;
    color: $colorText;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    font-weight: 500;
    color: $colorTextDark;
    background: $colorLightGrey;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #333333;
    background: #eaeaea;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.429rem 0.857rem;
    color: #333333;
    background: transparent;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.857rem;
    color: #333333;
    background: #f4f4f4;
    font-weight: 700;
}

p-dropdown.ng-dirty.ng-invalid>.p-dropdown {
    border-color: #a80000;
}

$small-height: 25px;

p-dropdown.dd-small .p-dropdown {
    height: $small-height;
    border: 0px solid white;
}


p-dropdown.right-float {
    float: right;
}

p-dropdown.dd-small .p-dropdown-label {
    height: auto;
    font-size: 11.25px;
    padding-left: 2px;
    padding-right: 2px;
}

p-dropdown.dd-small .p-dropdown-trigger {
    width: 2rem;
    height: auto;
    margin: 0px;
    padding: 0px;
}

p-dropdown p-overlay .p-overlay {
    width: 100%;
    min-width: 288px !important;
}

.p-input-filled .p-dropdown {
    background: #f4f4f4;
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #f4f4f4;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: #f4f4f4;
}

.m-dropdown-toggle {
    display: flex;
    align-items: center;
    width: 100%;

    &:hover {
        cursor: pointer;
    }
}

.m-dropdown-menu {
    @include visuallyHidden;
    list-style: none;

    .open & {
        @include visuallyVisible;
        display: flex;
        flex-direction: column;
    }
}

.m-dropdown-menu-item {
    width: 100%;
}

p-dropdown.h-narrow .p-dropdown {
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        padding: 0px;
        margin: 5px;
    }
}

.m-dropdown {
    position: relative;

    &-summary {
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: 0;
        outline: 0;
        list-style: none; // Remove marker

        &::-webkit-details-marker {
            // Remove marker
            display: none;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &-content {
        position: absolute;
        z-index: 99;
    }
}