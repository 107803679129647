.m-popup {
    position: fixed;
    width: 40%;
    max-width: 90vw;
    background: var(--neutral100);
    border-radius: 0px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    z-index: 10;

    &-sm {
        width: 352px;
    }

    &-auto {
        width: auto;
    }

    .nav.nav-tabs {
        padding: 0px !important;
        width: 100%;
        position: fixed;
        left: 0px;
    }

    .ng-resizable {
        position: static;
    }

    .ng-resizable-diagonal {
        right: 1px;
        bottom: -23px;
    }
}

.m-popup-header {
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 16px;
    background: var(--ramuddenYellow);
    align-items: center;

    input[type="checkbox"] {
        margin-left: -3px;
    }

    h3 {
        color: var(--neutral900);
    }

    .icon {
        width: 20px;
        height: 20px;
    }

    .m-btn-square {
        min-width: 32px;
        min-height: 32px;

        svg {
            fill: var(--neutral900);
        }
    }
}

.m-popup-body {
    height: auto;
    max-height: 480px;
    overflow: auto;

    label {
        color: var(--ramuddenYellow);
    }

    &-resizable {
        position: relative;
        overflow: visible;
        max-height: none;
    }

    .p-datatable {
        border-collapse: collapse;
    }

    .p-datatable .p-datatable-thead>tr>td,
    .p-datatable .p-datatable-tbody>tr>td,
    .p-datatable .p-datatable-tfoot>tr>td {
        border-left-color: transparent;
        border-right-color: transparent;
    }
}

.m-popup-close {
    border: none;
    background-color: transparent;
    pointer-events: auto;
    padding: 0;

    &:hover {
        cursor: pointer;
    }
}