.m-page-content-wrapper {
    grid-area: pageBodyContent;
}

app-page {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: minmax(0px, 1fr);
    grid-template-rows: auto minmax(0px, 1fr);
    grid-template-areas:
        "pageHeader"
        "pageBody";
}

app-page-header {
    grid-area: pageHeader;
}

app-page-body {
    grid-area: pageBody;

    &>*:not(router-outlet) {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: auto minmax(0px, 1fr);
        grid-template-rows: auto minmax(0px, 1fr);
        grid-template-areas:
            "pageSidebar pageBodyHeader"
            "pageSidebar pageBodyContent";
    }
}

app-page-sidebar {
    grid-area: pageSidebar;
    width: 360px;
    background-color: var(--ramuddenSand);
}

app-page-body-header {
    grid-area: pageBodyHeader;
    background-color: var(--neutral100);
    border-bottom: 1px solid var(--neutral300);
}

app-page-body-content {
    grid-area: pageBodyContent;
    background-color: var(--neutral100);
}

/*
 * Small hack for split area scrollbars
 */

.as-split-area {
    overflow: hidden !important;
}

.warning {
    color: darkorange;
}

.form-validation-error {
    color: red;
    font-size: small;
    margin: 0;
}
