.p-treeselect {
    width: 100%;
    background-color: $colorUltraLightGrey;
    border-color: $colorInputBorder;
    border-radius: 0px;
    color: $colorText;

    &:not(.p-disabled):hover {
        border-color: $colorInputBorder;
    }

    &:not(.p-disabled).p-focus {
        box-shadow: none;
        border-color: darken($colorInputBorder, 10%);
    }

    .p-treeselect-label {
        display: flex;
        align-items: center;

        &.p-placeholder {
            color: $colorTextLight;
        }
    }

    .p-treeselect-trigger {
        color: $colorText;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .p-treeselect-clear-icon {
        position: relative;
        right: 0;

        svg {
            color: $colorText;
        }
    }
}