.m-attachments {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
}

.m-attachment-image-wrapper {
    position: relative;
}

.m-attachment-image-actions {
    position: absolute;
    top: 0;
    right: 0;
}

.m-attachment-name {
    display: block;
    font-size: rem(11px);
    line-height: 1.1;
}

.p-dialog .file-drop-zone {
    width: 100%;
    height: 100%;
}

.p-dialog .file-drop-zone.drop-image {
    background-image: url("../../../assets/icons/drop.svg");
    background-repeat: no-repeat;
    background-size: 7.5%;
    background-position: 50% 50%;
}

.file-drop-zone .file-drop-zone-overlay {
    background-color: rgb(0, 0, 0, 0.5);
    border: 1px dashed black;

    z-index: 100;
    position: absolute;
    width: 100%;
    height: 100%;

    display: none;
}

.file-drop-zone .file-drop-zone-overlay.file-drop-zone-overlay-image {
    background-image: url("../../../assets/icons/drop.svg");
    background-repeat: no-repeat;
    background-size: 75px 75px;
    background-position: 50% 50%;
}

.file-drop-zone.drag-over .file-drop-zone-overlay {
    display: block;
}


.p-dialog .file-drop-zone .file-drop-zone-overlay {
    background-size: 7.5%;
}