.m-dl {
    &-hor {
        margin: 0;
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;

        dt {
            color: var(--neutral500);
        }

        dt,
        dd {
            margin: 8px 0;
        }
    }
}