.m-mode-select {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.m-mode-select-tile {
    background-color: $colorUltraLightGrey;
    border: 1px solid $colorBorder;
    border-radius: 0px;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "title"
        "animation"
        "button";
    gap: 16px;
}

.m-mode-select-tile-content {
    display: grid;
    gap: 8px;
}
