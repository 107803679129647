.m-map-filters {
    border-bottom: 1px solid $colorLightGrey;
}

.m-map-filters-header {
    display: grid;
    gap: 8px;
    grid-template-rows: auto auto;
    align-items: center;
}

.m-map-filters-header-title {
    align-self: center;
}

.m-map-filters-container {
    display: grid;
    grid-template-columns: 1fr auto;
}

.m-map-filters-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.m-map-filters-chip {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: $colorButtonDefault;
    border-radius: 0px;
    padding: 2px 8px;
    margin: 0;
    border: 0;
    outline: 0;
    user-select: none;
    font-family: inherit;
    font-size: rem(13px);

    svg {
        width: 16px;
        height: 16px;
    }

    &.is-selected {
        background-color: $colorButtonPrimary;
        color: $colorTextDark;

        &:disabled,
        &[disabled] {
            opacity: 0.66;
            cursor: default;
        }

        svg {
            fill: $colorBlack;
        }
    }
}

button.m-map-filters-chip {
    &:hover {
        cursor: pointer;
    }
}

.m-map-filters-chip-remove {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    background-color: transparent;

    &:hover {
        cursor: pointer;
    }

    svg {
        fill: $colorWhite;
    }
}

.m-button-link-icon {
    display: flex;
    align-self: center;
    margin-right: 5px;
}

.m-map-linked-device-filter {
    display: flex;
    flex-direction: column;
    margin: 5px;
}

.m-map-filters-chips-close-icon {
    color: $colorUltraLightGrey;
    margin-left: 5px;
    font-size: 0.8125rem
}

.m-map-filters-chips-close-icon:hover {
    color: $colorUltraLightGrey;
    margin-left: 5px;
    background-color: darken($colorBrand, 12.5%);
    font-size: 0.8125rem;
    cursor: pointer;
}

/*
 * Dialog
 */

.m-map-filters-dialog-body {
    display: grid;
    gap: 12px;
}
