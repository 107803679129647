.p-button {
    padding: 4px 12px;
    background-color: $colorButtonPrimary;
    border-color: $colorButtonPrimary;
    border-radius: 0px;
    font-size: rem(13px);
    font-weight: 500;
    white-space: nowrap;
    color: $colorWhite;

    &:focus {
        box-shadow: none;
    }

    &:enabled:hover {
        background: darken($colorButtonPrimary, 10%);
        border-color: darken($colorButtonPrimary, 10%);
        color: $colorWhite;
    }

    &:enabled:active {
        background: darken($colorButtonPrimary, 10%);
        border-color: darken($colorButtonPrimary, 10%);
        color: $colorWhite;
    }

    &.p-button-text {
        font-weight: normal;
        color: $colorTextDark;

        &:enabled:hover {
            background: $colorUltraLightGrey;
            color: $colorBrand;
        }

        &:enabled:active {
            background: $colorLightGrey;
            color: $colorBrand;
        }
    }

    &.p-button-icon-only {
        padding: 4px 12px;
    }
}

.p-button-label {
    font-weight: 500;
}

.m-btn {
    position: relative;
    flex: 0 0 auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 36px;
    padding: 4px 16px;
    border: 1px solid transparent;
    text-decoration: none;
    font-family: inherit;
    font-size: rem(14px);
    font-weight: 500;
    white-space: nowrap;
    text-align: center;
    pointer-events: auto;
    cursor: pointer;

    span {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
    }

    &:focus {
        outline: none;
    }

    &:disabled,
    &[disabled] {
        opacity: 0.66;
        cursor: default;
    }

    &.m-btn-square {
        align-items: center;
        justify-content: center;
        min-width: 36px;
        min-height: 36px;
        background: transparent;
        border-radius: 0px;
        padding: 0;

        &.m-btn-toggle {
            svg {
                fill: $colorText;
            }

            &.active,
            &:not(.disabled):hover {
                svg {
                    fill: $colorButtonPrimary;
                }
            }
        }
    }

    &.m-btn-default {
        background: $colorButtonDefault;
        color: $colorTextDark;
        border-radius: 0px;

        .icon {
            width: 20px;
            height: 20px;
            @extend .icon-text-dark;
        }

        &:hover {
            background: darken($colorButtonDefault, 4);
        }
    }

    &.m-btn-primary {
        background: $colorButtonPrimary;
        color: $colorBlack;
        border-radius: 0px;

        .icon {
            width: 20px;
            height: 20px;
        }

        &:hover {
            background: darken($colorButtonPrimary, 4);
        }
    }

    &.m-btn-secondary {
        background-color: $colorWhite;
        color: $colorTextDark;
        border-radius: 0px;

        .icon {
            width: 20px;
            height: 20px;
            @extend .icon-text-dark;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &.m-btn-tertiary {
        background-color: $colorButtonTertiary;
        color: $colorWhite;
        border-radius: 0px;

        .icon {
            width: 20px;
            height: 20px;
            @extend .icon-white;
        }

        &:hover {
            background: darken($colorButtonTertiary, 4);
        }
    }

    &.m-btn-primary,
    &.m-btn-tertiary,
    &.m-btn-default {

        &:disabled,
        &[disabled] {
            background: $colorTextLight;
            color: $colorWhite;

            .icon {
                fill: $colorWhite;
            }

            &:hover {
                background: $colorTextLight;
                color: $colorWhite;

                .icon {
                    fill: $colorWhite;
                }
            }
        }
    }

    &.m-btn-secondary {

        &:disabled,
        &[disabled] {
            color: $colorTextLight;

            .icon {
                fill: $colorTextLight;
            }

            &:hover {
                text-decoration: none;
                color: $colorTextLight;

                .icon {
                    fill: $colorTextLight;
                }
            }
        }
    }

    &.m-btn-link {
        background: transparent;
        min-width: 0;
        font-weight: 400;
        text-decoration: underline;
        color: $colorText;

        &:disabled,
        &[disabled] {
            opacity: 0.4;
        }
    }

    &.m-btn-filter {
        background-color: $colorWhite;
        border: 1px solid $colorBorder;
        border-radius: 0px;
        min-width: 107px;
        min-height: 30px;
        font-weight: 400;
        font-size: rem(13px);
    }

    &.m-btn-back {
        @extend .m-btn-secondary;
        font-size: rem(13px);
        font-weight: 400;
        text-transform: none;

        &-map {
            /* Position overlay */
            position: absolute;
            z-index: 1;
            top: 10px;
            left: 10px;
        }

        .icon {
            width: 10px;
            height: 10px;
        }

        &:hover {
            color: $colorTextDark;
        }
    }
}

.btn--yellow {
    @extend .m-btn;
    @extend .m-btn-primary;
}

.btn--lightgrey {
    @extend .m-btn;
    @extend .m-btn-default;
}

.m-btn-group {
    display: flex;
    flex-wrap: wrap;

    &.m-btn-group-right {
        justify-content: flex-end;
    }

    &.m-btn-group-left {
        justify-content: flex-start;
    }
}

.m-btn-100 {
    width: 100%;
}

.m-btn-mode-select {
    height: 22px;
    min-height: 22px;
    width: 100%;
    border-radius: 0px;
    background-color: #EEE;
    padding: 5px 10px;
    font-weight: normal;
    font-size: inherit;
    border-color: #999;
}

p-button.h-narrow .p-button {
    display: flex;
    align-items: center;
    height: 30px;
}
