.static {
    position: static;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                position: static;
            }
        }
    }
}

.relative {
    position: relative;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                position: relative;
            }
        }
    }
}

.absolute {
    position: absolute;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                position: absolute;
            }
        }
    }
}

.fixed {
    position: fixed;

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                position: fixed;
            }
        }
    }
}