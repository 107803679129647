.m-badge {
    padding: 4px;
    background-color: $colorButtonDefault;
    position: absolute;
    top: 0;
}

.m-badge-left {
    left: 0;
}

.m-badge-right {
    right: 0;
}

.m-badge-oker {
    background-color: $colorOker;
    color: $colorWhite;
}

.m-badge-leaf {
    background-color: $colorLeaf;
    color: $colorWhite;
}