.m-preview {
    position: relative;
    width: 360px;
    max-width: 100%;

    img {
        border: 1px solid $colorInputBorder;
        border-radius: 0px;
    }

    &-sm {
        width: 120px;
    }
}

.m-preview-delete {
    position: absolute;
    top: 0px;
    right: 0px;
}