app-map-search-container {
    max-width: 360px;

    &.is-collapsed {
        max-width: 84px;
    }
}

.m-map-search-container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: minmax(0, 1fr);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    pointer-events: none;
}

.m-map-search-open {
    position: relative;
    top: 10px;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0px;
    outline: 0;
    background: $colorWhite;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s ease;
    pointer-events: auto;

    svg {
        width: 24px;
        height: 24px;
    }

    &:hover {
        cursor: pointer;
    }
}

.m-map-search-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0px;
    outline: 0;
    background-color: transparent;

    &:hover {
        background-color: darken($colorUltraLightGrey, 4);
    }

    svg {
        width: 24px;
        height: 24px;
    }

    &:hover {
        cursor: pointer;
    }
}

.m-map-search {
    pointer-events: auto;
    display: grid;
    grid-template-rows: auto auto 1fr;
    background-color: $colorWhite;
    height: calc(100vh - 80px);
    width: 360px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
}

.m-map-search-header {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr auto;
    background-color: $colorUltraLightGrey;
    border-bottom: 1px solid $colorLightGrey;
}

.m-map-search-input {
    display: grid;
    grid-template-columns: 1fr auto auto;
    background-color: $colorWhite;
    border: 1px solid $colorInputBorder;
    border-radius: 0px;
    overflow: hidden;

    >input {
        z-index: 0;
        grid-column: 1 / span 3;
        grid-row: 1;
        @extend .p-inputtext;
        padding-right: 72px;
        background-color: transparent;
        height: 36px;
        border-radius: 0;
        border: 0;
    }

    >button {
        z-index: 1;
        grid-column: 2;
        grid-row: 1;

        &:hover {
            cursor: pointer;
        }
    }

    >span {
        z-index: 1;
        grid-column: 3;
        grid-row: 1;
        pointer-events: none;
    }
}

.m-map-search-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    background-color: transparent;
    color: $colorTextDark;
}