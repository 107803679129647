.m-header {
    background: $colorBrand;
    height: 80px;
    min-height: 0;
    padding: 0px 24px;
}

.m-header-navbar {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
}

.m-header-navbar-left {
    min-width: 0;
    display: flex;
    justify-content: flex-start;
}

.m-header-navbar-right {
    min-width: 0;
    display: flex;
    justify-content: flex-end;
}

.m-header-navbar-left,
.m-header-navbar-right {
    height: 100%;
    display: flex;
}

.m-org {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 8px;
}

.m-org__logo {
    flex: 0 0 auto;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: var(--neutral100);
    object-fit: contain;
}

.m-org__info {
    flex: 0 0 auto;
    color: var(--neutral900);
}

.m-org__name {
    font-size: rem(17px);
}

.m-org__product {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: rem(11px);
    color: var(--neutral700);
}

.m-nav-primary {
    flex: 0 1 auto;
    overflow-x: auto;
    overflow-y: visible;

    .m-nav-list {
        height: 100%;
        display: flex;
        padding: 0 16px;
        list-style: none;
    }

    .m-nav-list-item {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .m-nav-link {
        height: 100%;
        min-width: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 12px;
        font-weight: 400;
        color: $colorTextDark;
        white-space: nowrap;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        user-select: none;

        &:hover {
            border-bottom-color: $colorBlack;
            background-color: rgba($colorBlack, .05);
        }

        &.is-active {
            border-bottom-color: $colorBlack;
            background-color: rgba($colorBlack, .05);
        }
    }

    .icon {
        margin-bottom: 4px;
    }
}

.m-nav-secondary {
    background-color: $colorWhite;
    border-bottom: 1px solid $colorBorder;
}

.m-language {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    padding-left: 16px;
    border-left: 1px solid $colorBorder;
}

.m-account {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 16px;
    border-left: 1px solid $colorBorder;

    .m-dropdown-toggle {
        font-weight: 400;
        color: $colorTextDark;

        .icon {
            margin-right: 8px;
        }
    }
}

.m-account-item {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 500;

    &:hover {
        background-color: rgba($colorBlack, .05);
    }

    &.has-background {
        background-color: rgba($colorBlack, .05);

        &:hover {
            background-color: rgba($colorBlack, .1);
        }
    }
}

.m-account-dropdown {
    position: relative;

    .m-dropdown-menu {
        background-color: $colorBrand;
        border-radius: 0px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

        li {
            border-top: 1px solid $colorInputBorder;

            &:hover {
                background-color: darken($colorBrand, 4);
            }

            &:first-child {
                border-top: 0;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }

        a {
            display: flex;
            align-items: center;
            min-height: 36px;
            padding: 4px 12px;
            color: $colorTextDark;
        }

        .p-dropdown {
            border-radius: 0;
            border: 0;
            background-color: transparent;
        }

        .p-dropdown-label {
            color: $colorTextDark;
        }

        .p-dropdown-label.p-placeholder {
            color: $colorTextDark;
        }

        .p-dropdown-trigger {
            color: $colorTextDark;
        }

        .p-dropdown-clear-icon {
            color: $colorTextDark;
        }

        .icon {
            margin-right: 8px;
        }
    }

    &.open {
        .m-dropdown-menu {
            position: absolute;
            z-index: 90;
            top: calc(100% + 8px);
            right: 0;
        }
    }
}

.m-language-dropdown {
    .p-dropdown {
        border-radius: 0;
        border: 0;
        background-color: transparent;
    }

    .p-dropdown-label {
        color: $colorTextDark;
    }

    .p-dropdown-label.p-placeholder {
        color: $colorTextDark;
    }

    .p-dropdown-trigger {
        color: $colorTextDark;
    }

    .p-dropdown-clear-icon {
        color: $colorTextDark;
    }

    .icon {
        margin-right: 8px;
    }
}

.m-nav-tabs {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: visible;

    .m-nav-list {
        height: 100%;
        display: flex;
        list-style: none;
    }

    .m-nav-list-item {
        height: 100%;
    }

    .m-nav-link {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 12px;
        white-space: nowrap;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        color: $colorTextDark;

        &:hover,
        &.is-active {
            background-color: rgba($colorBlack, .05);
            color: $colorTextDark;
            border-bottom-color: $colorBlack;
        }

        &.is-disabled {
            opacity: 0.66;
            color: $colorTextLight;

            .icon {
                fill: $colorTextLight;
            }

            &.is-active {
                font-weight: 400;
                border-bottom-color: transparent;

                &:hover {
                    border-bottom-color: transparent;
                }
            }

            &:hover {
                color: $colorTextLight;
                cursor: default;

                .icon {
                    fill: $colorTextLight;
                }
            }
        }
    }

    .icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
}

.m-nav-context-menu {
    position: absolute;
    display: inline-block;

    .m-nav-list {
        display: flex;
        flex-direction: column;
        list-style: none;
    }

    .m-nav-list-item {
        width: 100%;
        border-top: 1px solid $colorBorder;

        &:first-child {
            border-top: 0;
        }
    }
}
