.vis-label {
    border: 0;
}

.vis-label.vis-nesting-group {
    background-color: var(--neutral900) !important;
    color: var(--neutral100) !important;
}

.vis-label.vis-nested-group {
    background-color: var(--neutral200) !important;
}

.vis-nested-group {
    .vis-inner {
        display: flex !important;
        align-items: center;
        gap: 8px;

        &:after {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: white;
            margin-left: auto;
        }
    }

    &.has-active-alert-error .vis-inner {
        &:after {
            background-color: rgb(241, 65, 65);
        }
    }

    &.has-active-alert-warning .vis-inner {
        &:after {
            background-color: rgb(255, 122, 34);
        }
    }

    &.has-active-alert-info .vis-inner {
        &:after {
            background-color: rgb(255, 225, 55);
        }
    }
}
