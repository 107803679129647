.m-vms-animation-wrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "left center right";
    align-items: center;

    &:hover {
        .m-vms-animation-button {
            opacity: 1;
        }
    }
}

.m-vms-animation-image-wrapper {
    grid-area: left / left / right / right;
    z-index: 0;
}

.m-vms-animation-image {
    width: 100%;
    max-height: 480px;
    aspect-ratio: 16/9;
    object-fit: contain;
    image-rendering: pixelated;
}

.m-vms-animation-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    background-color: $colorWhite;
    border: 0;
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    opacity: 0;
    transition: opacity .25s ease-in-out;

    .is-compact & {
        width: 24px;
        height: 24px;
    }

    &:hover {
        cursor: pointer;
    }
}

.m-vms-animation-button-icon {
    flex: 0 0 auto;
}

.m-vms-animation-prev {
    grid-area: left;
    z-index: 1;
    margin-left: 16px;

    .is-compact & {
        margin-left: 0;
        margin-right: -12px; // overlap the image when compact
    }
}

.m-vms-animation-next {
    grid-area: right;
    z-index: 1;
    margin-right: 16px;

    .is-compact & {
        margin-right: 0;
        margin-left: -12px; // overlap the image when compact
    }
}