.m-details {
    &+& {
        margin-top: 2px;
    }

    &-summary {
        display: grid;
        grid-template-columns: 1fr 16px;
        align-items: center;
        min-height: 36px;
        padding: 8px 16px;
        background-color: $colorUltraLightGrey;
        @extend h3;
        list-style: none; // Remove marker

        &::-webkit-details-marker {
            // Remove marker
            display: none;
        }

        &:hover {
            cursor: pointer;
        }

        [open]>& {
            .icon {
                transform: rotate(180deg);
            }
        }
    }

    &-content {
        padding: 16px;
    }
}