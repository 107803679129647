.p-paginator {
    background: var(--neutral200);
    padding: 0 12px;
    color: var(--neutral900);
    font-size: rem(13px);

    .icon {
        width: 16px;
        height: 16px;
    }
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    color: var(--neutral900);
    min-width: 32px;
    height: 32px;
    margin: 0px;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: var(--neutral100);
    color: var(--neutral900);
}

.p-paginator .p-paginator-first {}

.p-paginator .p-paginator-last {}

.p-paginator .p-paginator-current {
    color: var(--neutral900);
    min-width: 32px;
    height: 32px;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    font-size: rem(13px);
    color: var(--neutral900);
    min-width: 32px;
    height: 32px;
    margin: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: var(--neutral300);
    border-color: var(--neutral300);
    font-weight: 500;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: var(--neutral100);
    border-color: var(--neutral100);
    color: var(--neutral900);
}

.p-datatable .p-paginator-bottom {
    border-width: 0;
}