.m-vms-designer {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 320px;
    grid-template-areas:
        "navbar navbar"
        "canvas sidebar";

    >* {
        min-width: 0;
        min-height: 0;
    }

    &-navbar {
        grid-area: navbar;
        background-color: $colorWhite;
        border-bottom: 1px solid $colorBorder;
    }

    &-canvas {
        grid-area: canvas;
        overflow: auto;

        &-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    &-actions {
        grid-area: buttons;

    }

    &-sidebar {
        grid-area: sidebar;
        background-color: $colorWhite;
        border-left: 1px solid $colorBorder;
        display: grid;
        grid-template-rows: auto auto 1fr auto;
        grid-template-areas:
            "device"
            "preview"
            "properties"
            "buttons";

        >* {
            min-width: 0;
            min-height: 0;
        }
    }

    &-preview {
        grid-area: preview;
        border-bottom: 1px solid $colorBorder;
        position: relative;

        &-image {
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: contain;
            image-rendering: pixelated;
        }

        &-btn {
            position: absolute !important;
            bottom: 16px;
            right: 16px;
        }
    }

    &-device {
        grid-area: device;
        border-bottom: 1px solid $colorBorder;

        .vms-type {
            .p-dropdown {
                background-color: transparent;
                border-color: transparent;
                border-radius: 0;

                &:not(.p-disabled):hover,
                &:not(.p-disabled).p-focus {
                    border-color: transparent;
                }

                &-label {
                    min-height: 40px;
                    @extend h3;
                    padding: 0.75rem;
                }
            }
        }
    }


    &-properties {
        grid-area: properties;
        overflow: auto;
    }

    &-property {
        &:not(:first-child) {
            border-top: 1px solid $colorBorder;
        }
    }

    &-symbols {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
        gap: 16px;
    }

    &-symbol {
        &-link {
            position: relative;
            display: grid;
            grid-template-rows: 1fr auto;
            background-color: $colorBlack;
            border: 4px dotted transparent;
            padding: 16px;
            border-radius: 0px;

            >* {
                min-width: 0;
                min-height: 0;
            }

            &.is-selected {
                border-color: $colorWhite;
            }
        }

        &-checked {
            position: absolute;
            z-index: 1;
            top: -8px;
            right: -8px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                inset: 2px;
                border-radius: 50%;
                background-color: $colorWhite;
            }
        }

        &-label {
            display: block;
            margin-top: 4px;
            font-size: rem(14px);
            text-align: center;
            color: $colorWhite;
        }

        &-image {
            width: 100%;
        }
    }

    &-nav {
        display: flex;
        justify-content: center;

        .m-nav {
            &-list {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-wrap: wrap;
            }

            &-link {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 48px;
                border-top: 2px solid transparent;
                border-bottom: 2px solid transparent;

                &:hover,
                &.is-active {
                    border-bottom-color: $colorBrand;
                }
            }
        }
    }

    &-icon-btn {
        background-color: transparent;
        border: 0;
        padding: 4px;
        border-radius: 0px;

        &:hover {
            background-color: $colorUltraLightGrey;
        }

        &.is-selected {
            border-radius: 0px;
            border: 1px solid black;
            background-color: $colorUltraLightGrey;
        }
    }
}
