.p-autocomplete {
    width: 100%;
}

.p-autocomplete-item {
    font-size: rem(14px);

    .icon {
        width: 16px;
        height: 16px;
        fill: $colorTextLight;
    }
}