.m-filter-dropdown {
    position: relative;

    &-summary {
        display: grid;
        align-items: center;
        justify-items: center;
        width: 32px;
        height: 32px;
        margin: 0px;
        padding: 0px;
        list-style: none; // Remove marker
        background: var(--ramuddenYellow);
        color: var(--neutral900);
        border-radius: 0px;

        &::-webkit-details-marker {
            // Remove marker
            display: none;
        }

        &:hover {
            cursor: pointer;
        }

        [open]>& {}
    }

    &-content {
        position: absolute;
        right: 0;
        z-index: 999;
        background-color: $colorWhite;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
        border-radius: 0px;
    }
}

.m-filter-group {
    display: flex;
    align-items: center;
}

.m-filter-title {
    font-weight: 500;
}