.m-location-popup {
    width: 360px;
    max-height: calc(100% - 20px);
    overflow-y: auto;
    background-color: $colorWhite;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px;
    pointer-events: auto;
}

.m-location-popup-header {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    background-color: $colorUltraLightGrey;
    border-bottom: 1px solid $colorLightGrey;
}

.m-location-popup-close-container {
    align-self: start;
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: center;
}

.m-location-popup-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0px;
    outline: 0;
    background-color: transparent;

    svg {
        width: 24px;
        height: 24px;
    }

    &:hover {
        background-color: darken($colorUltraLightGrey, 4);
        cursor: pointer;
    }
}

.m-map-search-result {
    border-bottom: 1px solid $colorLightGrey;

    &:hover {
        cursor: pointer;
    }

    &:hover {
        background-color: $colorUltraLightGrey;
    }

    &.is-selected {
        background-color: $colorLightGrey;
    }
}

.m-map-search-result-header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 8px;
}

.m-map-search-result-body {
    display: grid;
}

.m-map-search-result-body-text {
    display: grid;
    grid-template-rows: auto auto;
    align-items: center;
}

.m-map-search-result-action-icons {
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: center;
}