.m-tab-header {
    height: 56px;
    display: grid;
    grid-template-columns: auto 1fr;
}

.m-tab-header-title {
    display: flex;
    align-items: center;
}

.m-tab-header-title-text {
    font-size: rem(14px);
    font-weight: 500;
    text-transform: uppercase;
    color: $colorTextDark;
}

.m-tab-header-title-text-label {
    display: block;
    font-size: rem(9px);
    font-weight: 400;
    text-transform: uppercase;
    color: $colorTextLight;
}

.m-tab-header-body {
    min-width: 0;
    max-width: 100%;
    display: flex;
    align-items: center;
}