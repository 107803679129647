:root {
    --ramuddenYellow: #ffe137;
    --ramuddenBeige: #ffe6aa;
    --ramuddenSand: #fffaeb;
    --ramuddenGreen: 23, 50, 50;
    --ramuddenRed: #3c1e28;
    --ramuddenOrange: #f06233;
    --ramuddenBlue: #003c8c;

    --neutral100: #ffffff;
    --neutral200: #f0f0f0; /// LIGHT BACKGROUND
    --neutral300: #d0d0d0; /// BORDER & SELECTED STATE
    --neutral400: #a0a0a0;
    --neutral500: #909090;
    --neutral600: #707070;
    --neutral700: #505050;
    --neutral800: #303030;
    --neutral900: #101010;

    --success: #2ba712;
    --info: #2e5ded;
    --warning: #ffc83e;
    --error: #ed2a2a;

    --boxShadow: 0 0 5px rgba(23, 50, 50, 0.2);
}

$colorBrand: #ffe137;
$colorBlue: #003c8c;
$colorPage: #fffaeb;
$colorPageBodyHeader: #fff;
$colorPageBodyContent: #fff;
$colorPageSidebar: #fffaeb;
$colorBorder: rgba(#173232, 0.2);

$colorText: #173232;
$colorTextLight: #999;
$colorTextDark: #173232;
$colorHeadings: #173232;

$colorButtonDefault: #dce8ee;
$colorButtonPrimary: $colorBrand;
$colorButtonSecondary: #ffffff;
$colorButtonTertiary: #7295a3;

$colorLightGrey: #e4e4e4;
$colorUltraLightGrey: #f4f4f4;
$colorGrey: #d4d4d4;
$colorMediumGrey: #5a5a5a;

$colorInputBorder: rgba(#173232, 0.2);

$colorCircleGrey: #c6c6c6;

$colorWhite: #ffffff;
$colorOker: #fec32d;
$colorLeaf: #26990f;
$colorOrange: #ff6600;
$colorOcean: #3f6df8;
$colorBlack: #173232;
