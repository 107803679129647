.m-map {
    position: relative;
    width: 100%;
    height: 100%;
}

.m-map-teaser {
    height: 120px;

    @media only screen and (min-width: map-get($breakpoints, xl)) {
        height: 180px;
    }
}

.m-map-overlay {
    position: absolute;

    .btn {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }
}

.m-map-overlay-form {
    width: 600px;
    max-width: 100%;
}

.m-map-overlay-group-form {
    width: 100%;
    max-width: 100%;
}

.m-map-overlay-datatable {
    table {
        @extend .m-card;
        border-collapse: collapse;
        border: 0 none;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }

    tr {
        border-top: 1px solid $colorGrey;

        &:first-child {
            border-top: 0 none;
        }
    }

    th {
        background-color: $colorLightGrey;

        &:first-child {
            border-top-left-radius: 4px;
        }

        &:last-child {
            border-top-right-radius: 4px;
        }
    }

    th,
    td {
        padding: 8px 12px;
    }
}

.m-map-overlay-right-top {
    top: 10px;
    right: 10px;
}

.m-map-overlay-right-top-moved {
    top: 10px;
    right: 60px;
}


.m-map-overlay-left-top {
    top: 10px;
    left: 10px;
}

.m-map-overlay-right-bottom {
    bottom: 24px;
    right: 10px;
}

.m-map-type-dropdown {
    position: relative;
    z-index: 1;
    background-color: $colorWhite;
    border-radius: 0px;

    .m-dropdown-toggle {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 40px;
        padding: 4px 12px;
        font-family: "Fira Sans", sans-serif;
        font-size: rem(13px);
        font-weight: 400;
        color: $colorText;
        border-radius: 0px;

        &:after {
            flex: 0 0 auto;
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 6px 0 6px;
            border-color: $colorTextDark transparent transparent transparent;
            margin-left: 8px;
        }

        &:hover {
            background-color: $colorLightGrey;
            cursor: pointer;
        }

        .icon {
            margin-right: 8px;

            .m-map-type-dropdown-compact & {
                margin-right: 0;
            }

            .m-map-type-dropdown-compact.open & {
                margin-right: 8px;
            }
        }
    }

    .m-dropdown-menu {
        border-top: 1px solid $colorGrey;

        .icon {
            margin-right: 8px;
        }

        button {
            display: flex;
            align-items: center;
            padding: 4px 12px;
            width: 100%;
            min-height: 40px;
            background: transparent;
            border: 0;
            font-family: "Fira Sans", sans-serif;
            font-size: rem(13px);
            font-weight: 400;
            color: $colorText;

            &:hover {
                cursor: pointer;
            }
        }

        li {
            &:hover {
                background-color: $colorLightGrey;
                cursor: pointer;
            }

            &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    &-compact {
        .m-dropdown-toggle {
            .icon {
                margin-right: 0;
            }

            .label {
                display: none;
            }
        }

        &.open {
            .m-dropdown-toggle {
                .icon {
                    margin-right: 8px;
                }

                .label {
                    display: block;
                }
            }
        }
    }
}

.m-map-coordinates {
    display: flex;
    align-items: center;
    width: 140px;
    border-radius: 0px;
    background-color: $colorTextDark;
    color: $colorWhite;
}

.m-map-coordinates-btn {
    padding: 8px;
    background-color: transparent;
    border: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &:hover {
        cursor: pointer;
    }

    .icon {
        width: 20px;
        height: 20px;
        fill: $colorTextLight;
    }

    &.is-active {
        .icon {
            fill: $colorWhite;
        }
    }
}

.m-map-marker-label {
    position: absolute;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 16px;
    border: 1px solid $colorWhite;

    max-width: 500px;
    min-height: 15px;

    color: $colorWhite;
    font-weight: 500;
    padding: 5px 6px;
    margin-left: 2px;
    margin-bottom: 8px;
    white-space: nowrap;
}

.m-map-info-panel {
    position: fixed;
    bottom: 16px;
    left: 16px;
    width: 640px;
    max-width: calc(100% - 32px);
}

.m-map-info-panel-close {
    position: absolute;
    top: 16px;
    right: 16px;
}

/*
 * Overrides Google Maps
 */

.gm-bundled-control-on-bottom {
    top: auto !important;
    left: auto !important;
    bottom: 0 !important;
    right: 0 !important;
    margin: 0 16px 24px 0 !important;
}

.gm-svpc {
    position: static !important;
    border-radius: 0px !important;
}

/* The popup bubble styling. */
.popup-bubble {
    /* Position the bubble centred-above its parent. */
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    /* Style the bubble. */
    background-color: white;
    padding: 5px 9px 5px 5px;
    border-radius: 0px;
    font-family: "Fira Sans", sans-serif;
    overflow-y: auto;
    max-height: 60px;
    box-shadow: 0px 2px 10px 0.5px rgba(0, 0, 0, 0.5);
    border-radius: 0px;
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
    /* Position the div a fixed distance above the tip. */
    position: absolute;
    width: 100%;
    bottom: 16px;
    left: 0;
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    /* Center the tip horizontally. */
    transform: translate(-50%, 0);
    /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
    width: 0;
    height: 0;
    /* The tip is 8px high, and 12px wide. */
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid white;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
    cursor: auto;
    height: 0;
    position: absolute;
    /* The max width of the info window. */
    width: 200px;
}