a.m-navigator {
    text-decoration: underline;
    color: $colorBlue;

    &.is-disabled {
        text-decoration: none;
        color: inherit;
    }

    &.is-inactive {
        color: #999;
    }
}
