.m {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            margin-top: map-get($sizes, $size);
            margin-bottom: map-get($sizes, $size);
            margin-left: map-get($sizes, $size);
            margin-right: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        margin-top: map-get($sizes, $size);
                        margin-bottom: map-get($sizes, $size);
                        margin-left: map-get($sizes, $size);
                        margin-right: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}

.my {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            margin-top: map-get($sizes, $size);
            margin-bottom: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        margin-top: map-get($sizes, $size);
                        margin-bottom: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}

.mx {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            margin-left: map-get($sizes, $size);
            margin-right: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        margin-left: map-get($sizes, $size);
                        margin-right: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}

.mt {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            margin-top: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        margin-top: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}

.mb {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            margin-bottom: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        margin-bottom: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}

.ml {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            margin-left: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        margin-left: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}

.mr {
    @each $size in map-keys($sizes) {
        &-#{$size} {
            margin-right: map-get($sizes, $size);
        }
    }

    @each $breakpoint in map-keys($breakpoints) {
        @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
            &-#{$breakpoint} {
                @each $size in map-keys($sizes) {
                    &-#{$size} {
                        margin-right: map-get($sizes, $size);
                    }
                }
            }
        }
    }
}