// The default toast width (300px) is too small for error messages
// However, the full-width version does not look good
// We want a combination of fit-content and max-width
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
    width: fit-content;
    max-width: min(80%, 900px);
    margin-left: auto;
    margin-right: auto;
}
