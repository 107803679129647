.m-2lvl-select {
    position: relative;
}

.m-2lvl-select-dropdown {
    z-index: 999999 !important;
}

.m-2lvl-select-dropdown-wrapper {
    max-height: 400px;
    overflow: auto;
}

.m-2lvl-select-dropdown-wrapper-max-height {
    max-height: 400px;
    overflow: auto;
}

$small-height: 25px;

.twolevel-select-expanded-full-height .p-dropdown-items-wrapper {
    max-height: none;
    height: 100%;
}

.twolevel-select-small .p-inputwrapper {
    height: $small-height;
}

.twolevel-select-small .p-dropdown {
    border: 0px solid white;
    height: $small-height;
}

.twolevel-select-small .p-dropdown-label {
    font-size: 11.25px;
    padding: 2px;
    height: $small-height;
}