/* You can add global styles to this file, and also import other style files */

/* No top whitespace on minimized screen */

@media (max-width: 767px) {
    .body-content {
        padding-top: 0 !important;
    }
}

/* File Input */

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

/* End File Input */

/* PrimeNG Responsive Table Title Fix */

th .p-column-title {
    display: inline !important;
}

/* Angular link without href */

a:not([href]) {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

a.disabled {
    cursor: default !important;
}

#map_canvas_container {
    position: relative;
}

#map_canvas {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

[hidden] {
    display: none !important;
}

.ng-draggable {
    cursor: move;
}

@font-face {
    font-family: 'Pixter Display Bold';
    src: url(assets/fonts/pixter-display-bold.ttf);
}

@font-face {
    font-family: 'Munro';
    src: url(assets/fonts/munro.ttf);
}

/* Fira Sans */

/* cyrillic-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 300;
    src: local("Fira Sans Light Italic"), local("FiraSans-LightItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrBiQyf1VFn2lg.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 300;
    src: local("Fira Sans Light Italic"), local("FiraSans-LightItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrBiQyf8VFn2lg.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 300;
    src: local("Fira Sans Light Italic"), local("FiraSans-LightItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrBiQyf0VFn2lg.woff2) format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* greek */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 300;
    src: local("Fira Sans Light Italic"), local("FiraSans-LightItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrBiQyf7VFn2lg.woff2) format("woff2");
    unicode-range: U+0370-03FF;
}

/* vietnamese */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 300;
    src: local("Fira Sans Light Italic"), local("FiraSans-LightItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrBiQyf3VFn2lg.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 300;
    src: local("Fira Sans Light Italic"), local("FiraSans-LightItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrBiQyf2VFn2lg.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 300;
    src: local("Fira Sans Light Italic"), local("FiraSans-LightItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrBiQyf4VFk.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 400;
    src: local("Fira Sans Italic"), local("FiraSans-Italic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9C4kDNxMZdWfMOD5VvkrjEYTLHdQ.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 400;
    src: local("Fira Sans Italic"), local("FiraSans-Italic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9C4kDNxMZdWfMOD5VvkrjNYTLHdQ.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 400;
    src: local("Fira Sans Italic"), local("FiraSans-Italic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9C4kDNxMZdWfMOD5VvkrjFYTLHdQ.woff2) format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* greek */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 400;
    src: local("Fira Sans Italic"), local("FiraSans-Italic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9C4kDNxMZdWfMOD5VvkrjKYTLHdQ.woff2) format("woff2");
    unicode-range: U+0370-03FF;
}

/* vietnamese */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 400;
    src: local("Fira Sans Italic"), local("FiraSans-Italic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9C4kDNxMZdWfMOD5VvkrjGYTLHdQ.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 400;
    src: local("Fira Sans Italic"), local("FiraSans-Italic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9C4kDNxMZdWfMOD5VvkrjHYTLHdQ.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 400;
    src: local("Fira Sans Italic"), local("FiraSans-Italic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9C4kDNxMZdWfMOD5VvkrjJYTI.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 500;
    src: local("Fira Sans Medium Italic"), local("FiraSans-MediumItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrA6Qif1VFn2lg.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 500;
    src: local("Fira Sans Medium Italic"), local("FiraSans-MediumItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrA6Qif8VFn2lg.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 500;
    src: local("Fira Sans Medium Italic"), local("FiraSans-MediumItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrA6Qif0VFn2lg.woff2) format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* greek */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 500;
    src: local("Fira Sans Medium Italic"), local("FiraSans-MediumItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrA6Qif7VFn2lg.woff2) format("woff2");
    unicode-range: U+0370-03FF;
}

/* vietnamese */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 500;
    src: local("Fira Sans Medium Italic"), local("FiraSans-MediumItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrA6Qif3VFn2lg.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 500;
    src: local("Fira Sans Medium Italic"), local("FiraSans-MediumItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrA6Qif2VFn2lg.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
    font-family: "Fira Sans";
    font-style: italic;
    font-weight: 500;
    src: local("Fira Sans Medium Italic"), local("FiraSans-MediumItalic"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9f4kDNxMZdWfMOD5VvkrA6Qif4VFk.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 300;
    src: local("Fira Sans Light"), local("FiraSans-Light"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnPKreSxf6TF0.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 300;
    src: local("Fira Sans Light"), local("FiraSans-Light"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnPKreQhf6TF0.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 300;
    src: local("Fira Sans Light"), local("FiraSans-Light"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnPKreShf6TF0.woff2) format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* greek */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 300;
    src: local("Fira Sans Light"), local("FiraSans-Light"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnPKreRRf6TF0.woff2) format("woff2");
    unicode-range: U+0370-03FF;
}

/* vietnamese */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 300;
    src: local("Fira Sans Light"), local("FiraSans-Light"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnPKreSRf6TF0.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 300;
    src: local("Fira Sans Light"), local("FiraSans-Light"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnPKreSBf6TF0.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 300;
    src: local("Fira Sans Light"), local("FiraSans-Light"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnPKreRhf6.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    src: local("Fira Sans Regular"), local("FiraSans-Regular"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9E4kDNxMZdWfMOD5VvmojLeTY.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    src: local("Fira Sans Regular"), local("FiraSans-Regular"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9E4kDNxMZdWfMOD5Vvk4jLeTY.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    src: local("Fira Sans Regular"), local("FiraSans-Regular"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9E4kDNxMZdWfMOD5Vvm4jLeTY.woff2) format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* greek */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    src: local("Fira Sans Regular"), local("FiraSans-Regular"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9E4kDNxMZdWfMOD5VvlIjLeTY.woff2) format("woff2");
    unicode-range: U+0370-03FF;
}

/* vietnamese */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    src: local("Fira Sans Regular"), local("FiraSans-Regular"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9E4kDNxMZdWfMOD5VvmIjLeTY.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    src: local("Fira Sans Regular"), local("FiraSans-Regular"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9E4kDNxMZdWfMOD5VvmYjLeTY.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 400;
    src: local("Fira Sans Regular"), local("FiraSans-Regular"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9E4kDNxMZdWfMOD5Vvl4jL.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    src: local("Fira Sans Medium"), local("FiraSans-Medium"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnZKveSxf6TF0.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    src: local("Fira Sans Medium"), local("FiraSans-Medium"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnZKveQhf6TF0.woff2) format("woff2");
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    src: local("Fira Sans Medium"), local("FiraSans-Medium"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnZKveShf6TF0.woff2) format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* greek */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    src: local("Fira Sans Medium"), local("FiraSans-Medium"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnZKveRRf6TF0.woff2) format("woff2");
    unicode-range: U+0370-03FF;
}

/* vietnamese */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    src: local("Fira Sans Medium"), local("FiraSans-Medium"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnZKveSRf6TF0.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    src: local("Fira Sans Medium"), local("FiraSans-Medium"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnZKveSBf6TF0.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
    font-family: "Fira Sans";
    font-style: normal;
    font-weight: 500;
    src: local("Fira Sans Medium"), local("FiraSans-Medium"),
    url(https://fonts.gstatic.com/s/firasans/v8/va9B4kDNxMZdWfMOD5VnZKveRhf6.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
